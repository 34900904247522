// export const SAS_BACKEND_URL = "http://api1.aetdevops.com/settlement";
// export const SAS_UPLOAD_URL = "http://jobs1.aetdevops.com/settlement-jobs";

const { REACT_APP_SAS_UPLOAD_URL,REACT_APP_SAS_BACKEND_URL } = process.env
export const SAS_BACKEND_URL = `${REACT_APP_SAS_BACKEND_URL}`;
export const SAS_UPLOAD_URL = `${REACT_APP_SAS_UPLOAD_URL}`;

// export const SAS_BACKEND_URL = `${REACT_APP_SAS_BACKEND_URL}`;
// export const SAS_UPLOAD_URL = `${REACT_APP_SAS_UPLOAD_URL}`;

export const ELASTIC_BACKEND_URL = ""
