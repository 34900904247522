import {
  CREATE_AFFILIATE,
  CREATE_AFFILIATE_ERROR,
  DELETE_AFFILIATE,
  GET_AFFILIATE,
  GET_AFFILIATE_ACCOUNT_TYPE,
  GET_AFFILIATE_ACCOUNT_TYPE_ERROR,
  GET_AFFILIATE_SCHEME_TYPE,
  GET_AFFILIATE_SCHEME_TYPE_ERROR,
  GET_AFFILIATE_PRODUCT_TYPE,
  GET_AFFILIATE_PRODUCT_TYPE_ERROR,
  GET_AFFILIATE_BINS,
  GET_AFFILIATE_BINS_ERROR,
  GET_AFFILIATE_PROCESSOR_ACCOUNTS,
  GET_AFFILIATE_PROCESSOR_ACCOUNTS_ERROR,
  GET_AFFILIATE_PRODUCT_ACCOUNTS,
  GET_AFFILIATE_PRODUCT_ACCOUNTS_ERROR,
  ADD_AFFILIATE_PROCESSOR_ACCOUNT,
  ADD_AFFILIATE_PROCESSOR_ACCOUNT_ERROR,
  CLEAR_ADD_AFFILIATE_PROCESSOR_ACCOUNT_RESPONSE,
  ADD_AFFILIATE_PRODUCT_ACCOUNT,
  ADD_AFFILIATE_PRODUCT_ACCOUNT_ERROR,
  CLEAR_ADD_AFFILIATE_PRODUCT_ACCOUNT_RESPONSE,
  GET_AFFILIATES,
  GET_AFFILIATES_ERROR,
  GET_AFFILIATES_SRES,
  GET_AFFILIATES_SRES_ERROR,
  GET_AFFILIATES_UNPAGINATED,
  GET_AFFILIATES_UNPAGINATED_ERROR,
  UPDATE_AFFILIATE,
  UPDATE_AFFILIATE_PROCESSOR_ACCOUNT,
  UPDATE_AFFILIATE_PRODUCT_ACCOUNT,
  FETCH_AFFILIATES_NAMES_AND_CODES,
  FETCH_AFFILIATES_NAMES_AND_CODES_ERROR,
  REGION_CODES,
  REGION_CODES_ERROR,
  REGION_CODES_DELETE,
  REGION_CODES_DELETE_ERROR,
  ADD_REGION_CODES,
  ADD_REGION_CODES_ERROR,
  DOWNLOADING_STATUS,
  ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE,
  ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE_ERROR,
  CLEAR_ADD_SETTLEMENT_CURRENCY_MESSAGES,
} from "../actions/types";

export default function (state = {}, action) {
	switch (action.type) {
		case DOWNLOADING_STATUS:
			return {
				...state,
				download_status : action.payload.data,
			};
		case REGION_CODES:
			return { ...state, region_codes: action.payload, region_codes_error: false };
		case REGION_CODES_ERROR:
			return { ...state, region_codes_error: action.payload, region_codes: false };
		case ADD_REGION_CODES:
			// because the post endpoint does not return any data.
			return {
				...state,
				region_codes       : [ ...state.region_codes, action.payload.data ],
				region_codes_error : false,
			};
		case ADD_REGION_CODES_ERROR:
			return { ...state, region_codes_error: action.payload, region_codes: false };
		case REGION_CODES_DELETE:
			return {
				...state,
				region_codes       : state.region_codes.filter((regionCode) => regionCode.code !== action.payload.data),
				region_codes_error : false,
			};
		case REGION_CODES_DELETE_ERROR:
			return { ...state, region_codes_error: action.payload, region_codes: false };

		case FETCH_AFFILIATES_NAMES_AND_CODES:
			let allAffiliatesArray = [ { label: "ALL", value: "ALL" } ];
			action.payload.map((aff) => {
				allAffiliatesArray = allAffiliatesArray.concat({
					label        : aff.name,
					value        : aff.code,
					currencyCode : aff.htmlCurrencyCode,
				});
				return allAffiliatesArray;
			});
			return { ...state, affiliatesNamesAndCodes: allAffiliatesArray, affiliatesNamesAndCodesError: false };
		case FETCH_AFFILIATES_NAMES_AND_CODES_ERROR:
			return { ...state, affiliatesNamesAndCodesError: action.payload, affiliatesNamesAndCodes: false };
		case GET_AFFILIATES:
			return { ...state, affiliateList: action.payload };
		case GET_AFFILIATES_UNPAGINATED:
			return { ...state, affiliateListUnPaginated: action.payload };
		case GET_AFFILIATES_UNPAGINATED_ERROR:
			return { ...state, affiliateListUnPaginatedError: action.payload };
		case GET_AFFILIATE_BINS:
			return { ...state, affiliateBinList: action.payload };
		case GET_AFFILIATE_BINS_ERROR:
			return { ...state, affiliateBinListError: action.payload };
		case GET_AFFILIATES_ERROR:
			return { ...state, affiliateListError: action.payload };
		case CREATE_AFFILIATE:
			state.affiliateList.content.push(action.payload.data);
			return { ...state, affiliateCreated: action.payload };
		case CREATE_AFFILIATE_ERROR:
			return { ...state, affiliateCreatedError: action.payload };
		case DELETE_AFFILIATE:
			const remainingAffiliates = state.affiliateList.content.filter((item) => item.id !== action.payload.id);
			state.affiliateList.content = remainingAffiliates;
			return { ...state, affiliateDeleted: action.payload };
		case GET_AFFILIATE:
			return { ...state, affiliate: action.payload };
		case GET_AFFILIATE_ACCOUNT_TYPE:
			return { ...state, affiliateAccountTypes: action.payload };
		case GET_AFFILIATE_ACCOUNT_TYPE_ERROR:
			return { ...state, affiliateAccountTypesError: action.payload };
		case GET_AFFILIATE_SCHEME_TYPE:
			return { ...state, affiliateSchemeTypes: action.payload };
		case GET_AFFILIATE_SCHEME_TYPE_ERROR:
			return { ...state, affiliateSchemeTypesError: action.payload };
		case GET_AFFILIATE_PRODUCT_TYPE:
			return { ...state, affiliateProductTypes: action.payload };
		case GET_AFFILIATE_PRODUCT_TYPE_ERROR:
			return { ...state, affiliateProductTypesError: action.payload };
		case GET_AFFILIATES_SRES:
			return { ...state, affiliateSres: action.payload };
		case GET_AFFILIATES_SRES_ERROR:
			return { ...state, affiliateSreError: action.payload };
		case ADD_AFFILIATE_PRODUCT_ACCOUNT:
			return { ...state, addAffiliateProductAccountResponse: action.payload };
		case ADD_AFFILIATE_PRODUCT_ACCOUNT_ERROR:
			return { ...state, addAffiliateProductAccountErrorResponse: action.payload };
		case CLEAR_ADD_AFFILIATE_PRODUCT_ACCOUNT_RESPONSE:
			return { ...state, addAffiliateProductAccountResponse: "", addAffiliateProductAccountErrorResponse: "" };
		case GET_AFFILIATE_PRODUCT_ACCOUNTS:
			return { ...state, affiliateProductAccounts: action.payload };
		case GET_AFFILIATE_PRODUCT_ACCOUNTS_ERROR:
			return { ...state, affiliateProductAccountsError: action.payload };
		case ADD_AFFILIATE_PROCESSOR_ACCOUNT:
			return { ...state, addAffiliateProcessorAccountResponse: action.payload };
		case ADD_AFFILIATE_PROCESSOR_ACCOUNT_ERROR:
			return { ...state, addAffiliateProcessorAccountErrorResponse: action.payload };
		case CLEAR_ADD_AFFILIATE_PROCESSOR_ACCOUNT_RESPONSE:
			return {
				...state,
				addAffiliateProcessorAccountResponse      : "",
				addAffiliateProcessorAccountErrorResponse : "",
			};
		case GET_AFFILIATE_PROCESSOR_ACCOUNTS:
			return { ...state, affiliateProcessorAccounts: action.payload };
		case GET_AFFILIATE_PROCESSOR_ACCOUNTS_ERROR:
			return { ...state, affiliateProcessorAccountsError: action.payload };
		case UPDATE_AFFILIATE:
			return { ...state, affiliateUpdated: action.payload.data };
		case UPDATE_AFFILIATE_PRODUCT_ACCOUNT:
			return { ...state, affiliateProductAccountUpdated: action.payload };
		case UPDATE_AFFILIATE_PROCESSOR_ACCOUNT:
			return { ...state, affiliateProcessorAccountUpdated: action.payload };
		case ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE:
			return { ...state, addCurrencyToScheme: action.payload }
		case ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE_ERROR:
			return { ...state, addCurrencyToSchemeError: action.payload }
		case CLEAR_ADD_SETTLEMENT_CURRENCY_MESSAGES:
			return {...state, addCurrencyToScheme:'',addCurrencyToSchemeError:''}
		default:
			return state;
	}
}
