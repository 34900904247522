import React from "react";
import Loadable from "react-loadable";
import DefaultLayout from "./containers/DefaultLayout";
import { FormattedMessage } from "react-intl";

function Loading() {
  return (
    <div>
      <FormattedMessage id="Loading" defaultMessage="Loading...." />
    </div>
  );
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const Settlement = Loadable({
  loader: () => import("./views/Settlement/Settlement"),
  loading: Loading,
});

const Processors = Loadable({
  loader: () => import("./views/Processors/Processor"),
  loading: Loading,
});

const Products = Loadable({
  loader: () => import("./views/Products/Products"),
  loading: Loading,
});

const ProductDetails = Loadable({
  loader: () => import("./views/Products/ProductDetails"),
  loading: Loading,
});

const UpdateProduct = Loadable({
  loader: () => import("./views/Products/UpdateProduct"),
  loading: Loading,
});

const Batches = Loadable({
  loader: () => import("./views/Batches/Batches"),
  loading: Loading,
});

const Summary = Loadable({
  loader: () => import("./views/Batches/Summary"),
  loading: Loading,
});

const ProductSetUp = Loadable({
  loader: () => import("./views/SetUp/SetUp"),
  loading: Loading,
});

const ProductEditSetUp = Loadable({
  loader: () => import("./views/SetUp/EditSetUp"),
  loading: Loading,
});

const EditProduct = Loadable({
  loader: () => import("./views/Products/EditProduct"),
  loading: Loading,
});

const AddProducts = Loadable({
  loader: () => import("./views/SetUp/AddProducts"),
  loading: Loading,
});

const Accounts = Loadable({
  loader: () => import("./views/Accounts/Accounts"),
  loading: Loading,
});

const AddAccount = Loadable({
  loader: () => import("./views/Accounts/AddAccounts"),
  loading: Loading,
});

const BatchesTransactions = Loadable({
  loader: () => import("./views/Batches/Transactions"),
  loading: Loading,
});

const ProductDefinitions = Loadable({
  loader: () => import("./views/SetUp/Definitions"),
  loading: Loading,
});

const AccountManualPosting = Loadable({
  loader: () => import("./views/Accounts/ManualPosting"),
  loading: Loading,
});

const AdminLogs = Loadable({
  loader: () => import("./views/Admin/Logs"),
  loading: Loading,
});

const ChannelLogs = Loadable({
  loader: () => import("./views/Admin/Channel/Logs"),
  loading: Loading,
});

const FileTemplates = Loadable({
  loader: () => import("./views/FileTemplates/TemplateList"),
  loading: Loading,
});

const AddFileTemplate = Loadable({
  loader: () => import("./views/FileTemplates/AddTemplate"),
  loading: Loading,
});

const EditFileTemplate = Loadable({
  loader: () => import("./views/FileTemplates/EditTemplate"),
  loading: Loading,
});

/**
 * Individual Processor
 */
// const ProcessorDetails= Loadable({
//   loader:() => import('./views/Processors/ProcessorDetails'),
//   loading:Loading
// });

const ProcessorSubGroups = Loadable({
  loader: () => import("./views/Processors/SubGroups"),
  loading: Loading,
});

const ProcessorSubGroupsProducts = Loadable({
  loader: () => import("./views/Processors/SubGroups/Products"),
  loading: Loading,
});

const ProcessorSubGroupsAddproduct = Loadable({
  loader: () => import("./views/Processors/SubGroups/AddProduct"),
  loading: Loading,
});

const ProcessorSubGroupsProductsAccounts = Loadable({
  loader: () => import("./views/Processors/SubGroups/Accounts"),
  loading: Loading,
});

const ProcessorSubGroupsProductsAddAccount = Loadable({
  loader: () => import("./views/Processors/SubGroups/AddAccounts"),
  loading: Loading,
});
const DailySummary = Loadable({
  loader: () => import("./views/Summary/Index"),
  loading: Loading,
});

const DailyPosting = Loadable({
  loader: () => import("./views/Summary/Post"),
  loading: Loading,
});

const DailyPostingAccounts = Loadable({
  loader: () => import("./views/Summary/PostAccounts"),
  loading: Loading,
});

const DailySummarySubGroups = Loadable({
  loader: () => import("./views/Summary/SubGroups"),
  loading: Loading,
});

const DailySummarySubGroupsProducts = Loadable({
  loader: () => import("./views/Summary/Products"),
  loading: Loading,
});

const DailySummarySubGroupsProductsAccount = Loadable({
  loader: () => import("./views/Summary/Accounts"),
  loading: Loading,
});

const EditAccount = Loadable({
  loader: () => import("./views/Accounts/EditAccount"),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import("./views/Transactions/Transactions"),
  loading: Loading,
});

const FileDefinitions = Loadable({
  loader: () => import("./views/FileDefinitions/FileDefinitions"),
  loading: Loading,
});

const AddFileDefinitions = Loadable({
  loader: () => import("./views/FileDefinitions/addFileDefinitions"),
  loading: Loading,
});

const ManualAddFileDefinitions = Loadable({
  loader: () => import("./views/FileDefinitions/manualAddFileDefinitions"),
  loading: Loading,
});

const EditFileDefinition = Loadable({
  loader: () => import("./views/FileDefinitions/editFileDefinition"),
  loading: Loading,
});

const ManualPostings = Loadable({
  loader: () => import("./views/ManualPostings/ManualPostings"),
  loading: Loading,
});

const Exceptions = Loadable({
  loader: () => import("./views/Exceptions/Exception"),
  loading: Loading,
});

const ExceptionDefinition = Loadable({
  loader: () => import("./views/Exceptions/Definitions"),
  loading: Loading,
});

const ProcessorChannelExceptionSummary = Loadable({
  loader: () => import("./views/Exceptions/Channels"),
  loading: Loading,
});

const AddException = Loadable({
  loader: () => import("./views/Exceptions/addDefinition"),
  loading: Loading,
});

const EditProcessorExceptionDefinition = Loadable({
  loader: () => import("./views/Exceptions/editDefinition"),
  loading: Loading,
});

const ChannelRows = Loadable({
  loader: () => import("./views/Exceptions/Rows"),
  loading: Loading,
});

const AccountReports = Loadable({
  loader: () => import("./views/Reports/Accounts"),
  loading: Loading,
});

const ChannelReports = Loadable({
  loader: () => import("./views/Reports/Channels"),
  loading: Loading,
});

const DailyReports = Loadable({
  loader: () => import("./views/Reports/Daily"),
  loading: Loading,
});

const Authorizations = Loadable({
  loader: () => import("./views/Authorizations/Authorizations"),
  loading: Loading,
});

const CheckerAuthorizations = Loadable({
  loader: () => import("./views/Authorizations/CheckerAuthorizations"),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import("./views/Settings/Settings"),
  loading: Loading,
});

const FileSets = Loadable({
  loader: () => import("./views/FileSets/FileSets"),
  loading: Loading,
});

const FileSetsDefinitions = Loadable({
  loader: () => import("./views/FileSets/FileDefinitions"),
  loading: Loading,
});

const AddFileSetDefinition = Loadable({
  loader: () => import("./views/FileSets/AddFileDefinitions"),
  loading: Loading,
});

const ProcessorFileSet = Loadable({
  loader: () => import("./views/Processors/FileSets"),
  loading: Loading,
});

const FileSetBatches = Loadable({
  loader: () => import("./views/FileSets/Batches"),
  loading: Loading,
});

const FileSetDefinitionBatches = Loadable({
  loader: () => import("./views/FileSets/BatchesDefinitions"),
  loading: Loading,
});

const FileSetDefinitionBatchesTransactions = Loadable({
  loader: () => import("./views/FileSets/BatchesDefinitionTransactions"),
  loading: Loading,
});

const Banks = Loadable({
  loader: () => import("./views/Banks/Banks"),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import("./views/Cards/Cards"),
  loading: Loading,
});

const Bins = Loadable({
  loader: () => import("./views/Bins/Bins"),
  loading: Loading,
});

// const Affiliates = Loadable({
//   loader: () => import('./views/Affiliates/Affiliates'),
//   loading:Loading
// })

const Affiliates2 = Loadable({
  loader: () => import("./views/Affiliates/Affiliates2"),
  loading: Loading,
});

const Affiliates3 = Loadable({
  loader: () => import("./views/Affiliates/Affiliates3"),
  loading: Loading,
});

const Sres = Loadable({
  loader: () => import("./views/Sres/Sres"),
  loading: Loading,
});

const AffiliatesBins = Loadable({
  loader: () => import("./views/Affiliates/Bins"),
  loading: Loading,
});

const AddAffiliateBin = Loadable({
  loader: () => import("./views/Affiliates/addBin"),
  loading: Loading,
});

const AffiliateSres = Loadable({
  loader: () => import("./views/Affiliates/Sres"),
  loading: Loading,
});

const AddAffiliateSre = Loadable({
  loader: () => import("./views/Affiliates/addSre"),
  loading: Loading,
});

const BankCards = Loadable({
  loader: () => import("./views/Banks/Cards"),
  loading: Loading,
});

const AddBankCards = Loadable({
  loader: () => import("./views/Banks/addCards"),
  loading: Loading,
});

const VisaDashboard = Loadable({
  loader: () => import("./views/Schemes/Dashboard"),
  loading: Loading,
});

const InterAffiliateDashboard = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate"),
  loading: Loading,
});

const MastercardDashboard = Loadable({
  loader: () => import("./views/Schemes/MasterCard"),
  loading: Loading,
});

const MastercardT112Transactions = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T112/Transactions"),
  loading: Loading,
});

const MastercardT112Summary = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T112/Summary"),
  loading: Loading,
});

const MastercardT464Transactions = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T464/Transactions"),
  loading: Loading,
});

const MastercardT461Transactions = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T461/Transactions"),
  loading: Loading,
});

const MastercardT140Transactions = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T140/Transactions"),
  loading: Loading,
});

const MasterCardT464Summary = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T464/Summary"),
  loading: Loading,
});
const MasterCardPosAcquirerSummaryDetail = Loadable({
  loader: () =>
    import("./views/Schemes/MasterCard/MastercardPosAcquirerSummaryDetail"),
  loading: Loading,
});

const InterAffiliateAcquirerSummary = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Acquirer/Summary"),
  loading: Loading,
});

const VisaSummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Summary"),
  loading: Loading,
});

const VisaAcquirerSummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/Summary"),
  loading: Loading,
});

const VisaIssuerSummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/Summary"),
  loading: Loading,
});

const VisaOctTransacactions = Loadable({
  loader: () => import("./views/Schemes/Visa/Oct/Transactions"),
  loading: Loading,
});

const VisaPostingDetails = Loadable({
  loader: () => import("./views/Schemes/Visa/VisaPostingDetails"),
  loading: Loading,
});

const VisaPostingReport = Loadable({
  loader: () => import("./views/Schemes/Visa/AllPostingSummaryReport"),
  loading: Loading,
});

const VisaIssuerPostingReport = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/VisaPostingReport"),
  loading: Loading,
});

const VisaAcquirerPostingReport = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/VisaPostingReport"),
  loading: Loading,
});

const VisaIssuerValidatedEntries = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/ValidatedEntries"),
  loading: Loading,
});

const VisaAcquirerValidatedEntries = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/ValidatedEntries"),
  loading: Loading,
});

const VisaIssuerUnValidatedEntries = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/UnvalidatedEntries"),
  loading: Loading,
});

const VisaAcquirerUnValidatedEntries = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/UnvalidatedEntries"),
  loading: Loading,
});

const InterAffiliateIssuerSummary = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Issuer/Summary"),
  loading: Loading,
});

const UserManagement = Loadable({
  loader: () => import("./views/Users/UserManagement"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/UsersList"),
  loading: Loading,
});

const FileSetBatchSummary = Loadable({
  loader: () => import("./views/FileSets/BatchesSummary"),
  loading: Loading,
});

const EditAffiliate = Loadable({
  loader: () => import("./views/Affiliates/EditAffiliate"),
  loading: Loading,
});

const AffiliateProductAccounts = Loadable({
  loader: () => import("./views/Affiliates/ProductsAccounts"),
  loading: Loading,
});

const MastercardReports = Loadable({
  loader: () => import("./views/Schemes/MasterCard/Reports"),
  loading: Loading,
});

const VisaReports = Loadable({
  loader: () => import("./views/Schemes/Visa/Reports"),
  loading: Loading,
});

const InterAffiliatePostingReports = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Reports"),
  loading: Loading,
});

const AffiliateProcessorAccounts = Loadable({
  loader: () => import("./views/Affiliates/ProcessorAccounts"),
  loading: Loading,
});

// const MastercardDashboard = Loadable({
//   loader:() => import('./views/Schemes/MastercardDashbaord'),
//   loading:Loading
// })
const GimSettlement = Loadable({
  loader: () => import("./views/Schemes/GimSettlement"),
  loading: Loading,
});
const GimPostingDetail = Loadable({
  loader: () => import("./views/Schemes/GIM/PostingSummary/PostingDetail"),
  loading: Loading,
});

const GimQueryDetails = Loadable({
  loader: () => import("./views/Schemes/GIM/PostingSummary/GimQueryDetails"),
  loading: Loading,
});
const GimSummaryDetail = Loadable({
  loader: () => import("./views/Schemes/GIM/PostingSummary/SummaryDetail"),
  loading: Loading,
});
const GimFileSummary = Loadable({
  loader: () => import("./views/Schemes/GIM/DetailFile/FileSummary"),
  loading: Loading,
});
const GimFileContent = Loadable({
  loader: () => import("./views/Schemes/GIM/DetailFile/FileContent"),
  loading: Loading,
});
const MastercardSettlement = Loadable({
  loader: () => import("./views/Schemes/MasterCardSettlement"),
  loading: Loading,
});
const ChinaUnionPaySettlement = Loadable({
  loader: () => import("./views/Schemes/ChinaUnionPay/ChinaUnionPaySettlement"),
  loading: Loading,
});
const ChinaUnionPayAcquirerFileDetails = Loadable({
  loader: () => import("./views/Schemes/ChinaUnionPay/AcquirerFileDetails"),
  loading: Loading,
});
const ChinaUnionPayFileSummaryDetails = Loadable({
  loader: () => import("./views/Schemes/ChinaUnionPay/SummaryFileDetails"),
  loading: Loading,
});
const ChinaUnionPayPostingDetails = Loadable({
  loader: () => import("./views/Schemes/ChinaUnionPay/PostingDetails"),
  loading: Loading,
});
const ChinaUnionPayPostingEntries = Loadable({
  loader: () => import("./views/Schemes/ChinaUnionPay/PostingEntries"),
  loading: Loading,
});
const MasterpassSettlement = Loadable({
  loader: () => import("./views/Schemes/Masterpass/MasterpassSettlement"),
  loading: Loading,
});
const MasterpassDetails = Loadable({
  loader: () => import("./views/Schemes/Masterpass/MasterpassDetails"),
  loading: Loading,
});
const MasterpassReportsIndex = Loadable({
  loader: () => import("./views/Schemes/Masterpass/Reports/index"),
  loading: Loading,
});

const MasterpassIssuerReport = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/IssuerReport/IssuerReport"),
  loading: Loading,
});
const MasterpassAcquirerReport = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/AcquirerReport/AcquirerReport"),
  loading: Loading,
});
const MasterpassSummReport = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/SummReport/SummReport"),
  loading: Loading,
});
const MasterpassFeesReport = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/FeesReport/FeesReport"),
  loading: Loading,
});
const MasterpassSettlementReport = Loadable({
  loader: () =>
    import(
      "./views/Schemes/Masterpass/Reports/SettlementReport/SettlementReport"
    ),
  loading: Loading,
});
const MasterpassQueryDetails = Loadable({
  loader: () => import("./views/Schemes/Masterpass/MasterpassQueryDetails"),
  loading: Loading,
});
const MasterpassIssuers = Loadable({
  loader: () => import("./views/Schemes/Masterpass/Reports/Issuer/Issuer"),
  loading: Loading,
});
const MasterpassAcquirers = Loadable({
  loader: () => import("./views/Schemes/Masterpass/Reports/Acquirer/Acquirer"),
  loading: Loading,
});
const MasterpassPostingReport = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/PostingReport/PostingReport"),
  loading: Loading,
});
const MasterpassPostingDetails = Loadable({
  loader: () =>
    import("./views/Schemes/Masterpass/Reports/MasterpassPostingDetails/index"),
  loading: Loading,
});

const InterswitchSettlement = Loadable({
  loader: () => import("./views/Schemes/InterswitchSettlement"),
  loading: Loading,
});

const InterswitchSettlementDailySummary = Loadable({
  loader: () => import("./views/Schemes/Interswitch/DailySummary"),
  loading: Loading,
});

const MastercardAdvisementDetail = Loadable({
  loader: () => import("./views/Schemes/MasterCard/AdvisementDetail"),
  loading: Loading,
});

const InterAffiliateSettlement = Loadable({
  loader: () => import("./views/Schemes/InterAffiliateSettlement"),
  loading: Loading,
});

const EcommerceSettlement = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/EcommerceSettlement"),
  loading: Loading,
});

const EcommerceDetails = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/EcommerceDetails"),
  loading: Loading,
});

const EcommerceIssuerDetails = Loadable({
  loader: () =>
    import("./views/Schemes/Ecommerce/Issuers/EcommerceIssuerDetail"),
  loading: Loading,
});

const EcommerceAcquirerDetails = Loadable({
  loader: () =>
    import("./views/Schemes/Ecommerce/Acquirers/EcommerceAcquirerDetails"),
  loading: Loading,
});
const EcommerceReports = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports"),
  loading: Loading,
});
const EcommerceSettlementReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/SettlementReport"),
  loading: Loading,
});
const EcommercePostingReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/PostingReport"),
  loading: Loading,
});
const EcommerceFeesReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/FeesReport"),
  loading: Loading,
});
const EcommerceSummReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/SummReport"),
  loading: Loading,
});
const EcommerceAcquirerReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/AcquirerReport"),
  loading: Loading,
});
const EcommerceIssuerReport = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/Rerports/IssuerReport"),
  loading: Loading,
});
const EcommercePostingDetails = Loadable({
  loader: () =>
    import("./views/Schemes/Ecommerce/Rerports/EcommerceCashPostingDetails"),
  loading: Loading,
});
const EcommerceQueryDetails = Loadable({
  loader: () => import("./views/Schemes/Ecommerce/EcommerceQueryDetails"),
  loading: Loading,
});
const VisaSettlement = Loadable({
  loader: () => import("./views/Schemes/VisaSettlement"),
  loading: Loading,
});

const InterAffiliateIssuerTransactions = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Issuer/Transactions"),
  loading: Loading,
});

const InterAffiliateAcquirerTransactions = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Acquirer/Transactions"),
  loading: Loading,
});

const InterAffiliateSummary = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Summary"),
  loading: Loading,
});

const InterAffiliateSourceSummary = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Summary"),
  loading: Loading,
});

const InterAffiliateSourceIssuerItem = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/Issuer/InterAffiliateSourceIssuerItem"
    ),
  loading: Loading,
});

const InterAffiliateSourceAcquirerItem = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/Acquirer/InterAffiliateSourceAcquirerItem"
    ),
  loading: Loading,
});

const InterAffiliateSummaryTransactions = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/SummaryTransactions"),
  loading: Loading,
});

const VisaAcquirerTransactions = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/Transactions"),
  loading: Loading,
});

const VisaIssuerTransactions = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/Transactions"),
  loading: Loading,
});

const MastercardT112Reports = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T112/Reports"),
  loading: Loading,
});

const MastercardT464Reports = Loadable({
  loader: () => import("./views/Schemes/MasterCard/T464/Reports"),
  loading: Loading,
});

const AcquirerInterAffiliateReports = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Acquirer/Reports"),
  loading: Loading,
});

const InterAffiliateReports = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/Issuer/Reports"),
  loading: Loading,
});

const IssuerVisaReports = Loadable({
  loader: () => import("./views/Schemes/Visa/Issuer/Reports"),
  loading: Loading,
});

const AcquirerVisaReports = Loadable({
  loader: () => import("./views/Schemes/Visa/Acquirer/Reports"),
  loading: Loading,
});

const VSSFiles = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/Index"),
  loading: Loading,
});

const AllVss110 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss110/All110s"),
  loading: Loading,
});

const AllVss116 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss116/All116s"),
  loading: Loading,
});

const Vss110DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss110/Summary"),
  loading: Loading,
});

const VSS116DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss116/Summary"),
  loading: Loading,
});

const AllVss120 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss120/All120s"),
  loading: Loading,
});

const VSS120DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss120/Summary"),
  loading: Loading,
});

const AllVss300 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss300/All300s"),
  loading: Loading,
});

const VSS300DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss300/Summary"),
  loading: Loading,
});

const AllVss900 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss900/All900s"),
  loading: Loading,
});

const VSS900DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss900/Summary"),
  loading: Loading,
});

const AllVss115 = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss115/All115s"),
  loading: Loading,
});

const VSS115DailySummary = Loadable({
  loader: () => import("./views/Schemes/Visa/Vss/vss115/Summary"),
  loading: Loading,
});

const DailyPostingSummary = Loadable({
  loader: () => import("./views/Schemes/DailyPostingSummary"),
  loading: Loading,
});

const MerchantPayment = Loadable({
  loader: () => import("./views/Merchants/Payment"),
  loading: Loading,
});

const MerchantPaymentDetails = Loadable({
  loader: () => import("./views/Merchants/PaymentDetails"),
  loading: Loading,
});

const History = Loadable({
  loader: () => import("./views/ExchangeRates/History"),
  loading: Loading,
});

const LogSummary = Loadable({
  loader: () => import("./views/Logs/Summary/Summary"),
  loading: Loading,
});

const PostilionDetails = Loadable({
  loader: () => import("./views/Logs/Summary/PostilionDetails"),
  loading: Loading,
});

const FlexcubeDetails = Loadable({
  loader: () => import("./views/Logs/Summary/FlexcubeDetails"),
  loading: Loading,
});

const POSacquirersDetails = Loadable({
  loader: () => import("./views/Logs/Summary/POSacquirersDetails"),
  loading: Loading,
});

const PostilionSummarySearchResult = Loadable({
  loader: () => import("./views/Logs/Summary/PostilionSummarySearchResult"),
  loading: Loading,
});

const FlexcubeSummarySearchResult = Loadable({
  loader: () => import("./views/Logs/Summary/FlexcubeSummarySearchResult"),
  loading: Loading,
});

const POSacquirerSummarySearchResult = Loadable({
  loader: () => import("./views/Logs/Summary/POSacquirerSummarySearchResult"),
  loading: Loading,
});

const FileUpload = Loadable({
  loader: () => import("./views/Processors/FileUpload"),
  loading: Loading,
});
const Collections = Loadable({
  loader: () => import("./views/Collections/Collections"),
  loading: Loading,
});
const Narrations = Loadable({
  loader: () => import("./views/Narrations/Narrations"),
  loading: Loading,
});
const DirectoryListing = Loadable({
  loader: () => import("./views/DirectoryListing/DirectoryListing"),
  loading: Loading,
});

const AdvisementSummary = Loadable({
  loader: () => import("./views/Schemes/MasterCard/AdvisementSummary"),
  loading: Loading,
});

const PostOffice = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/PostOffice/Summary"),
  loading: Loading,
});

const GrossSettlement = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/GrossSettlement"
    ),
  loading: Loading,
});

const RegisterSettlement = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/RegisterSettlement"
    ),
  loading: Loading,
});

const AcquirerStatus = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/AcquirerStatus"
    ),
  loading: Loading,
});

const SettlementReport = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/SettlementReport"
    ),
  loading: Loading,
});

const SettlementBreakDown = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/SettlementBreakDown/SettlementBreakDown"
    ),
  loading: Loading,
});

const PostingReport = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InterAffiliateSource/PostingReport"),
  loading: Loading,
});

const AffiliatePostingReport = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/AffiliatePostingReport"
    ),
  loading: Loading,
});

const SumSettlement = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InterAffiliateSource/SumSettlement"),
  loading: Loading,
});

const RegionCode = Loadable({
  loader: () => import("./views/Schemes/InterAffiliate/RegionCodes/RegionCode"),
  loading: Loading,
});
const AddRegionCode = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/RegionCodes/AddRegionCode"),
  loading: Loading,
});

const IssuerInterAffiliateSourceItem = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/IssuerInterAffiliateSourceItem"
    ),
  loading: Loading,
});

const AcquirerInterAffiliateSourceItem = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/AcquirerInterAffiliateSourceItem"
    ),
  loading: Loading,
});

const AffiliatePostingSummary = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/AffiliatePostingSummary"
    ),
  loading: Loading,
});

const SumSettlementFees = Loadable({
  loader: () =>
    import(
      "./views/Schemes/InterAffiliate/InterAffiliateSource/SumSettlementFees"
    ),
  loading: Loading,
});

const IssuerAudit = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InterAffiliateSource/IssuerAudit"),
  loading: Loading,
});

const AcquirerAudit = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InterAffiliateSource/AcquirerAudit"),
  loading: Loading,
});

const InterAffiliatePostingDetails = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InterAffiliatePostingDetails"),
  loading: Loading,
});

const MastercardPostingDetails = Loadable({
  loader: () => import("./views/Schemes/MasterCard/MastercardPostingDetails"),
  loading: Loading,
});

const Audit = Loadable({
  loader: () => import("./views/Audit/Audit"),
  loading: Loading,
});

const InteraffiliateQueryDetails = Loadable({
  loader: () =>
    import("./views/Schemes/InterAffiliate/InteraffiliateQueryDetails"),
  loading: Loading,
});
const MastercardQueryDetails = Loadable({
  loader: () => import("./views/Schemes/MasterCard/MastercardQueryDetails"),
  loading: Loading,
});

const ExpressCashQueryDetails = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/ExpressCashQueryDetails"),
  loading: Loading,
});
const ExpressCashDetails = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/ExpressCashDetails"),
  loading: Loading,
});

const ExpressCashAcquirer = Loadable({
  loader: () =>
    import("./views/Schemes/ExpressCash/Reports/Accquirer/Acquirer"),
  loading: Loading,
});
const ExpressCashPostingDetails = Loadable({
  loader: () =>
    import("./views/Schemes/ExpressCash/Reports/ExpressCashPostingDetails"),
  loading: Loading,
});
const ExpressCashIssuer = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/Issuer/Issuer"),
  loading: Loading,
});
const ExpressCash = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/ExpressCashSettlement"),
  loading: Loading,
});
const ExpressCashReportIndex = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/index"),
  loading: Loading,
});
const ExpressCashSettlementReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/SettlemetReport"),
  loading: Loading,
});
const ExpressCashPostingReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/PostingReport"),
  loading: Loading,
});
const ExpressCashFeesReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/FeesReport"),
  loading: Loading,
});
const ExpressCashSummReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/SummReport"),
  loading: Loading,
});
const ExpressCashAcquirerReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/AcquirerReport"),
  loading: Loading,
});
const ExpressCashIssuerReport = Loadable({
  loader: () => import("./views/Schemes/ExpressCash/Reports/IssuerReport"),
  loading: Loading,
});

const MVISASettlement = Loadable({
  loader: () => import("./views/Schemes/MVISA/MVISASettlement"),
  loading: Loading,
});
const MVISAQueryDetails = Loadable({
  loader: () => import("./views/Schemes/MVISA/MVISAQueryDetails"),
  loading: Loading,
});
const MVISADetails = Loadable({
  loader: () => import("./views/Schemes/MVISA/MVISADetails"),
  loading: Loading,
});
const MVISAIssuer = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/Issuer"),
  loading: Loading,
});
const MVISAAcquirer = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/Accquirer"),
  loading: Loading,
});
const MVISASummaryDetail = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/PostingDetails"),
  loading: Loading,
});
const MVISAReportIndex = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports"),
  loading: Loading,
});
const MVISASettlementReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/SettlemetReport"),
  loading: Loading,
});
const MVISAPostingReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/PostingReport"),
  loading: Loading,
});
const MVISAFeesReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/FeesReport"),
  loading: Loading,
});
const MVISASummReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/SummReport"),
  loading: Loading,
});
const MVISAAcquirerReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/AcquirerReport"),
  loading: Loading,
});
const MVISAIssuerReport = Loadable({
  loader: () => import("./views/Schemes/MVISA/Reports/IssuerReport"),
  loading: Loading,
});
const NostroReports = Loadable({
  loader: () => import("./views/Schemes/NostroReports/NostroReports"),
  loading: Loading,
});
const BillingReport = Loadable({
  loader: () => import("./views/Schemes/Billing"),
  loading: Loading,
});

const Posting = Loadable({
  loader: () => import("./views/Posting/index"),
  loading: Loading,
});
const AdminReports = Loadable({
  loader: () => import("./views/Admin/Reports/Reports"),
  loading: Loading,
});
const PostingTransaction = Loadable({
  loader: () => import("./views/Admin/Reports/PostingTransaction"),
  loading: Loading,
});
const MastercardResolvedFiles = Loadable({
  loader: () => import("./views/Schemes/MasterCard/ResolvedFiles"),
  loading: Loading,
});

const BillingReports = Loadable({
  loader: () => import("./views/Admin/Reports/BillingReports"),
  loading: Loading,
});

const CashManagement = Loadable({
  loader: () => import("./views/Admin/Reports/BillingReport/CashManagement"),
  loading: Loading,
});

const Consumer = Loadable({
  loader: () => import("./views/Admin/Reports/BillingReport/Consumer"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/",
    exact: true,
    name: <FormattedMessage id="home" defaultMessage="Home" />,
    component: DefaultLayout,
  },
  {
    path: "/dashboard",
    name: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
    component: Dashboard,
    viewPermission: null,
  },
  {
    path: "/settlement/products/:id",
    exact: true,
    name: (
      <FormattedMessage id="Product Set Up" defaultMessage="Product Set Up" />
    ),
    component: Settlement,
  },
  {
    path: "/settlement/products",
    name: (
      <FormattedMessage id="Product Set Up" defaultMessage="Product Set Up" />
    ),
    component: Settlement,
    exact: true,
  },
  {
    path: "/processors",
    name: <FormattedMessage id="Processors" defaultMessage="Processors" />,
    component: Processors,
    exact: true,
  },
  {
    path: "/processors/:id/filesets",
    name: "Processors FileSet",
    component: ProcessorFileSet,
    exact: true,
  },
  {
    path: "/products",
    name: <FormattedMessage id="Products" defaultMessage="Products" />,
    component: Products,
    exact: true,
    viewPermission: "settlement.can_view_products",
  },
  {
    path: "/products/addProducts",
    name: <FormattedMessage id="Add Product" defaultMessage="Add Product" />,
    component: AddProducts,
    exact: true,
  },
  {
    path: "/products/addProducts/:id",
    name: <FormattedMessage id="Add Product" defaultMessage="Add Product" />,
    component: AddProducts,
    exact: true,
  },
  {
    path: "/processors/:id/subgroups/:subgroupid/products",
    name: <FormattedMessage id="Products" defaultMessage="Products" />,
    exact: true,
    component: ProcessorSubGroupsProducts,
  },
  {
    path: "/processors/:id/subgroups/:subgroupid/products/addProduct",
    name: <FormattedMessage id="Add Product" defaultMessage="Add Product" />,
    exact: true,
    component: ProcessorSubGroupsAddproduct,
  },
  {
    path: "/processors/:id/subgroups/:subgroupid/products/:productid/accounts",
    name: <FormattedMessage id="Accounts" defaultMessage="Accounts" />,
    exact: true,
    component: ProcessorSubGroupsProductsAccounts,
  },
  {
    path: "/processors/:id/subgroups/:subgroupid/products/:productid/accounts/addAccount",
    name: <FormattedMessage id="Add Account" defaultMessage="Add Account" />,
    exact: true,
    component: ProcessorSubGroupsProductsAddAccount,
  },
  // {path:'/subgroups/:id/products',name:'View Products',component:SubGroupProducts,exact:true},
  {
    path: "/daily-summary",
    name: (
      <FormattedMessage id="Daily Summary" defaultMessage="Daily Summary" />
    ),
    component: DailySummary,
    exact: true,
  },
  {
    path: "/daily-summary/:id/subgroups",
    name: <FormattedMessage id="SubGroups" defaultMessage="Daily Summary" />,
    component: DailySummarySubGroups,
    exact: true,
  },
  {
    path: "/transactions",
    name: <FormattedMessage id="Transactions" defaultMessage="Transactions" />,
    component: Transactions,
    exact: true,
  },
  {
    path: "/reports",
    name: <FormattedMessage id="Reports" defaultMessage="Reports" />,
    component: Transactions,
    exact: true,
  },
  {
    path: "/daily-summary/:id/subgroups/products",
    name: <FormattedMessage id="Products" defaultMessage="Products" />,
    component: DailySummarySubGroupsProducts,
    exact: true,
  },
  {
    path: "/daily-summary/:id/subgroups/products/accounts",
    name: <FormattedMessage id="Account" defaultMessage="Account" />,
    component: DailySummarySubGroupsProductsAccount,
    exact: true,
  },
  {
    path: "/daily-posting",
    name: (
      <FormattedMessage id="Daily Posting" defaultMessage="Daily Posting" />
    ),
    component: DailyPosting,
    exact: true,
  },
  {
    path: "/daily-posting/:id/accounts",
    name: (
      <FormattedMessage
        id="Daily Posting Accounts"
        defaultMessage="Daily Posting Accounts"
      />
    ),
    component: DailyPostingAccounts,
    exact: true,
  },
  {
    path: "/settings",
    name: <FormattedMessage id="Settings" defaultMessage="Settings" />,
    component: Settings,
    exact: true,
  },
  {
    path: "/accounts",
    name: <FormattedMessage id="Accounts" defaultMessage="Accounts" />,
    component: Accounts,
    exact: true,
  },
  {
    path: "/accounts/addAccounts",
    name: <FormattedMessage id="Add Account" defaultMessage="Add Account" />,
    component: AddAccount,
    exact: true,
  },
  {
    path: "/products/addAccounts/:productId",
    name: <FormattedMessage id="Add Account" defaultMessage="Add Account" />,
    component: AddAccount,
    exact: true,
  },
  {
    path: "/accounts/:id/edit",
    name: <FormattedMessage id="Edit Account" defaultMessage="Edit Account" />,
    component: EditAccount,
    exact: true,
  },
  {
    path: "/file-definitions",
    name: (
      <FormattedMessage
        id="File Definitions"
        defaultMessage="File Definitions"
      />
    ),
    component: FileDefinitions,
    exact: true,
  },
  {
    path: "/file-definitions/add",
    name: (
      <FormattedMessage
        id="Add File Definitions"
        defaultMessage="Add File Definitions"
      />
    ),
    component: AddFileDefinitions,
    exact: true,
  },
  {
    path: "/file-definitions/manualAddition",
    name: (
      <FormattedMessage
        id="Add File Definitions Manually"
        defaultMessage="Add File Definitions Manually"
      />
    ),
    component: ManualAddFileDefinitions,
    exact: true,
  },
  {
    path: "/file-definitions/:id/edit",
    name: (
      <FormattedMessage
        id="Edit File Definition"
        defaultMessage="Edit File Definition"
      />
    ),
    component: EditFileDefinition,
    exact: true,
  },
  // {path:'/accounts/:id/details',name:'Account Details',component:},
  /**Real Bread Crumb */
  {
    path: "/accounts/:id/definitions",
    name: (
      <FormattedMessage
        id="Account Definitions"
        defaultMessage="Account Definitions"
      />
    ),
    component: ProductDefinitions,
    exact: true,
  },
  // {path:'/accounts/:id/definitions/manualPosting',name:'Manual Posting',component:AccountManualPosting,exact:true},
  {
    path: "/processors/:id/subgroups",
    name: <FormattedMessage id="SubGroups" defaultMessage="SubGroups" />,
    exact: true,
    component: ProcessorSubGroups,
  },
  {
    path: "/processors/:id/subgroups/addProduct",
    name: <FormattedMessage id="Add Product" defaultMessage="Add Product" />,
    component: AddProducts,
    exact: true,
  },
  {
    path: "/accounts/:accountid/definitions/editdefinition/:id",
    name: (
      <FormattedMessage
        id="Edit Account Definition"
        defaultMessage="Edit Account Definition"
      />
    ),
    component: ProductEditSetUp,
    exact: true,
  },
  {
    path: "/products/:id/accounts",
    name: (
      <FormattedMessage
        id="Product&#39;s Account"
        defaultMessage="Product&#39;s Account"
      />
    ),
    component: ProductDetails,
    exact: true,
  },
  {
    path: "/accounts/:id/definitions/setup",
    name: (
      <FormattedMessage
        id="Account Definition Set Up"
        defaultMessage="Account Definition Set Up"
      />
    ),
    component: ProductSetUp,
    exact: true,
  },
  {
    path: "/products/setup/:id",
    name: (
      <FormattedMessage id="Product Set Up" defaultMessage="Product Set Up" />
    ),
    component: ProductSetUp,
    exact: true,
  },
  {
    path: "/products/:id/update",
    name: (
      <FormattedMessage id="Update Product" defaultMessage="Update Product" />
    ),
    component: UpdateProduct,
    exact: true,
  },
  {
    path: "/products/:id/edit",
    name: <FormattedMessage id="Edit Product" defaultMessage="Edit Product" />,
    component: EditProduct,
    exact: true,
  },
  {
    path: "/batches",
    name: <FormattedMessage id="Batches" defaultMessage="Batches" />,
    component: Batches,
    exact: true,
  },
  {
    path: "/summary",
    name: (
      <FormattedMessage id="Batches Summary" defaultMessage="Batches Summary" />
    ),
    component: Summary,
    exact: true,
  },
  {
    path: "/batches/:id/transactions",
    name: (
      <FormattedMessage
        id="Batches Transactions"
        defaultMessage="Batches Transactions"
      />
    ),
    component: BatchesTransactions,
    exact: true,
  },
  {
    path: "/admin/logs",
    name: <FormattedMessage id="Admin Logs" defaultMessage="Admin Logs" />,
    component: AdminLogs,
    exact: true,
    viewPermission: "settlement.can_view_processing_logs",
  },
  {
    path: "/channel/logs",
    name: <FormattedMessage id="Channel Logs" defaultMessage="Channel Logs" />,
    component: ChannelLogs,
    exact: true,
    viewPermission: "settlement.can_view_processed_files",
  },
  {
    path: "/file/templates",
    name: (
      <FormattedMessage id="File Templates" defaultMessage="File Templates" />
    ),
    component: FileTemplates,
    exact: true,
    viewPermission: "settlement.can_view_file_templates",
  },
  {
    path: "/file/templates/addTemplate",
    name: (
      <FormattedMessage
        id="Add File Template"
        defaultMessage="Add File Template"
      />
    ),
    component: AddFileTemplate,
    exact: true,
    viewPermission: "settlement.can_manage_file_templates",
  },
  {
    path: "/file/templates/:id/update",
    name: (
      <FormattedMessage id="Edit Template" defaultMessage="Edit Template" />
    ),
    component: EditFileTemplate,
    exact: true,
    viewPermission: "settlement.can_manage_file_templates",
  },
  {
    path: "/manualPostings",
    name: (
      <FormattedMessage id="Manual Postings" defaultMessage="Manual Postings" />
    ),
    component: ManualPostings,
    exact: true,
  },
  {
    path: "/manualPostings/add",
    name: (
      <FormattedMessage
        id="Add Manual Posting"
        defaultMessage="Add Manual Posting"
      />
    ),
    component: AccountManualPosting,
    exact: true,
  },
  {
    path: "/exceptions",
    name: <FormattedMessage id="Exceptions" defaultMessage="Exceptions" />,
    component: Exceptions,
    exact: true,
  },
  {
    path: "/exceptions/:id/rows",
    name: (
      <FormattedMessage
        id="Processor Channel Rows"
        defaultMessage="Processor Channel Rows"
      />
    ),
    component: ChannelRows,
    exact: true,
  },
  {
    path: "/exceptions/definitions",
    name: (
      <FormattedMessage
        id="Processor Exception Definition"
        defaultMessage="Processor Exception Definition"
      />
    ),
    component: ExceptionDefinition,
    exact: true,
  },
  {
    path: "/exceptions/:id/channels",
    name: (
      <FormattedMessage
        id="Processor Channel Summary"
        defaultMessage="Processor Channel Summary"
      />
    ),
    component: ProcessorChannelExceptionSummary,
    exact: true,
  },
  {
    path: "/exceptions/:id/definitions/edit",
    name: (
      <FormattedMessage
        id="Edit Processor Exception Definiiton"
        defaultMessage="Edit Processor Exception Definiiton"
      />
    ),
    component: EditProcessorExceptionDefinition,
    exact: true,
  },
  {
    path: "/exceptions/definitions/addException",
    name: (
      <FormattedMessage id="Add Exception" defaultMessage="Add Exception" />
    ),
    component: AddException,
    exact: true,
  },
  // {path:'/processor/:id',name:'Processor',exact:true,component:ProcessorDetails},
  {
    path: "/reports/accounts",
    name: (
      <FormattedMessage id="Account Reports" defaultMessage="Account Reports" />
    ),
    component: AccountReports,
    exact: true,
  },
  {
    path: "/reports/channels",
    name: (
      <FormattedMessage id="Channel Reports" defaultMessage="Channel Reports" />
    ),
    component: ChannelReports,
    exact: true,
  },
  {
    path: "/reports/daily",
    name: (
      <FormattedMessage id="Daily Reports" defaultMessage="Daily Reports" />
    ),
    component: DailyReports,
    exact: true,
  },
  {
    path: "/checker-authorizations",
    name: (
      <FormattedMessage
        id="Checker Authorizations"
        defaultMessage="Checker Authorizations"
      />
    ),
    component: CheckerAuthorizations,
    exact: true,
  },
  {
    path: "/authorizations",
    name: (
      <FormattedMessage id="Authorizations" defaultMessage="Authorizations" />
    ),
    component: Authorizations,
    exact: true,
  },
  {
    path: "/filesets",
    name: <FormattedMessage id="FileSets" defaultMessage="FileSets" />,
    component: FileSets,
    exact: true,
  },
  {
    path: "/interaffiliates/:id/batches",
    name: "InterAffiliates Batches",
    component: FileSetBatches,
    exact: true,
  },
  {
    path: "/mastercard/:id/batches",
    name: "MasterCard Batches",
    component: FileSetBatches,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercardDashboard",
    name: "MasterCard Dashboard",
    component: MastercardDashboard,
    exact: true,
  },
  {
    path: "/interswitch/:id/batches",
    name: "Interswitch Batches",
    component: FileSetBatches,
    exact: true,
  },
  {
    path: "/visa/:id/batches",
    name: "Visa Batches",
    component: FileSetBatches,
    exact: true,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/filesets/:id/batches",
    name: (
      <FormattedMessage id="FileSet Batches" defaultMessage="FileSet Batches" />
    ),
    component: FileSetBatches,
    exact: true,
  },
  {
    path: "/filesets/:id/definitions",
    name: (
      <FormattedMessage
        id="FileSet Definitions"
        defaultMessage="FileSet Definitions"
      />
    ),
    component: FileSetsDefinitions,
    exact: true,
  },
  {
    path: "/filesets/:id/definitions/add",
    name: (
      <FormattedMessage
        id="Add FileSet Definition"
        defaultMessage="Add FileSet Definition"
      />
    ),
    component: AddFileSetDefinition,
    exact: true,
  },
  {
    path: "/filesets/:filesetId/batches/:batchesId/definitionBatches",
    name: (
      <FormattedMessage
        id="FileSetBatchesDefinition"
        defaultMessage="FileSetBatchesDefinition"
      />
    ),
    component: FileSetDefinitionBatches,
    exact: true,
  },
  {
    path: "/filesets/:filesetId/batches/:batchesId/summary",
    name: (
      <FormattedMessage
        id="FileSetBatchesSummary"
        defaultMessage="FileSetBatchesSummary"
      />
    ),
    component: FileSetBatchSummary,
    exact: true,
  },
  {
    path: "/filesets/:filesetId/batches/:batchesId/definitionBatches/:definitionId/transactions",
    name: (
      <FormattedMessage
        id="FileSetDefinitionBatchesTransactions"
        defaultMessage="FileSetDefinitionBatchesTransactions"
      />
    ),
    component: FileSetDefinitionBatchesTransactions,
    exact: true,
  },
  {
    path: "/mastercard/settlement/:date/detail",
    name: (
      <FormattedMessage
        id="Mastercard Advisement Detail"
        defaultMessage="Mastercard Advisement Detail"
      />
    ),
    component: MastercardAdvisementDetail,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t112/:fileId/transactions",
    name: (
      <FormattedMessage
        id="MasterCardT112Transactions"
        defaultMessage="MasterCardT112Transactions"
      />
    ),
    component: MastercardT112Transactions,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t112/:fileId/summary",
    name: (
      <FormattedMessage
        id="MasterCardT112Summary"
        defaultmessage="MasterCardT112Summary"
      />
    ),
    component: MastercardT112Summary,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t464/:fileId/summary",
    name: (
      <FormattedMessage
        id="MasterCardT464Summary"
        defaultMessage="MasterCardT464Summary"
      />
    ),
    component: MasterCardT464Summary,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t464/:fileId/transactions",
    name: (
      <FormattedMessage
        id="MasterCard T464 Transactions"
        defaultMessage="MastercardT464Transactions"
      />
    ),
    component: MastercardT464Transactions,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t461/:fileId/transactions",
    name: (
      <FormattedMessage
        id="MasterCard T461 Transactions"
        defaultMessage="MasterCard T461 transactions"
      />
    ),
    component: MastercardT461Transactions,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/t140/:fileId/transactions",
    name: (
      <FormattedMessage
        id="MasterCard T140 Transactions"
        defaultMessage="Mastercard T140 Transactions"
      />
    ),
    component: MastercardT140Transactions,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/reports",
    name: (
      <FormattedMessage id="Scheme Reports" defaultMessage="Scheme Reports" />
    ),
    component: MastercardReports,
    exact: true,
    viewPermission: "settlement.can_view_reports",
  },
  {
    path: "/mastercard/reports/t112",
    name: <FormattedMessage id="T112 Reports" defaultMessage="T112 Reports" />,
    component: MastercardT112Reports,
    exact: true,
    viewPermission: "settlement.can_view_reports",
  },
  {
    path: "/mastercard/reports/t464",
    name: <FormattedMessage id="T464 Reports" defaultMessage="T464 Reports" />,
    component: MastercardT464Reports,
    exact: true,
    viewPermission: "settlement.can_view_reports",
  },
  {
    path: "/mastercard/settlement/pos-acquirers/:date/:page",
    name: (
      <FormattedMessage
        id="POS-acquirer "
        defaultMessage="POS-acquirer Posting summary"
      />
    ),
    component: MasterCardPosAcquirerSummaryDetail,
    exact: true,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/interAffiliate/reports",
    name: (
      <FormattedMessage
        id="InterAffiliate Reports"
        defaultMessage="InterAffiliate Reports"
      />
    ),
    component: InterAffiliatePostingReports,
    exact: true,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/visa/reports",
    name: <FormattedMessage id="Visa Reports" defaultMessage="Visa Reports" />,
    component: VisaReports,
    exact: true,
    viewPermission: "settlement.can_view_visa_reports",
  },
  {
    path: "/visa/reports/issuer",
    name: (
      <FormattedMessage
        id="Visa Issuer Reports"
        defaultMessage="Visa Issuer Reports"
      />
    ),
    component: IssuerVisaReports,
    exact: true,
    viewPermission: "settlement.can_view_visa_reports",
  },
  {
    path: "/visa/reports/acquirer",
    name: (
      <FormattedMessage
        id="Visa Acquirer Reports"
        defaultMessage="Visa Acquirer Reports"
      />
    ),
    component: AcquirerVisaReports,
    exact: true,
    viewPermission: "settlement.can_view_visa_reports",
  },
  {
    path: "/interAffiliate/reports/posting",
    name: (
      <FormattedMessage
        id="InterAffiliate Posting Reports"
        defaultMessage="Issuer InterAffiliate Reports"
      />
    ),
    component: InterAffiliateReports,
    exact: true,
  },
  {
    path: "/interAffiliate/reports/acquirer",
    name: (
      <FormattedMessage
        id="Acquirer interAffiliate Reports"
        defaultMessage="Acquirer InterAffiliate Reports"
      />
    ),
    component: AcquirerInterAffiliateReports,
    exact: true,
  },
  {
    path: "/configuration/banks",
    name: <FormattedMessage id="Banks" defaultMessage="Banks" />,
    component: Banks,
    exact: true,
  },
  {
    path: "/configuration/cards",
    name: <FormattedMessage id="Cards" defaultMessage="Cards" />,
    component: Cards,
    exact: true,
  },
  {
    path: "/configuration/bins",
    name: <FormattedMessage id="Bins" defaultMessage="Bins" />,
    component: Bins,
    exact: true,
    viewPermission: "settlement.can_view_bins",
  },
  {
    path: "/configuration/affiliates",
    name: <FormattedMessage id="Affiliates" defaultMessage="Affiliates" />,
    component: Affiliates2,
    exact: true,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/affiliates3",
    name: <FormattedMessage id="Affilaites" defaultMessage="Affilaites" />,
    component: Affiliates3,
    exact: true,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/affiliates/:id/bins/addbin",
    name: <FormattedMessage id="Add Bin" defaultMessage="Add Bin" />,
    component: AddAffiliateBin,
    exact: true,
    viewPermission: "settlement.can_manage_bins",
  },
  {
    path: "/configuration/affiliates/:id/bins",
    name: <FormattedMessage id="Bins" defaultMessage="Bins" />,
    component: AffiliatesBins,
    exact: true,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/affiliates/:id/product-accounts",
    name: (
      <FormattedMessage
        id="Affiliate Product Accounts"
        defaultMessage="Affiliate Product Accounts"
      />
    ),
    component: AffiliateProductAccounts,
    exact: true,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/affiliates/:id/details",
    name: (
      <FormattedMessage
        id="Affiliate Details"
        defaultMessage="Affiliate Details"
      />
    ),
    component: AffiliateProcessorAccounts,
    exact: true,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/affiliates/:code/edit",
    name: (
      <FormattedMessage id="Edit Affiliate" defaultMessage="Edit Affiliate" />
    ),
    component: EditAffiliate,
    exact: true,
    viewPermission: [
      "settlement.can_manage_own_affiliate",
      "settlement.can_view_own_affiliate",
    ],
  },
  {
    path: "/configuration/affiliates/:id/sres",
    exact: true,
    name: (
      <FormattedMessage id="Affiliate Sres" defaultMessage="Affiliate Sres" />
    ),
    component: AffiliateSres,
    viewPermission: [
      "settlement.can_view_own_affiliate",
      "settlement.can_view_group_affiliates",
    ],
  },
  {
    path: "/configuration/sres",
    name: <FormattedMessage id="Sres" defaultMessage="Sres" />,
    component: Sres,
    exact: true,
    viewPermission: "settlement.can_view_sres",
  },
  {
    path: "/configuration/affiliates/:id/addSre",
    name: <FormattedMessage id="Add Sre" defaultMessage="Add Sre" />,
    exact: true,
    component: AddAffiliateSre,
    viewPermission: "settlement.can_manage_sres",
  },
  {
    path: "/configuration/banks/:id/cards",
    name: <FormattedMessage id="Cards" defaultMessage="Cards" />,
    exact: true,
    component: BankCards,
  },
  {
    path: "/configuration/banks/:id/cards/addCards",
    name: <FormattedMessage id="Add Card" defaultMessage="Add Card" />,
    exact: true,
    component: AddBankCards,
  },
  {
    path: "/visa/dashboard",
    name: (
      <FormattedMessage id="Visa Dashboard" defaultMessage="Visa Dashboard" />
    ),
    exact: true,
    component: VisaDashboard,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/inter-affiliate/dashboard",
    name: (
      <FormattedMessage
        id="InterAffiliate Dashboard"
        defaultMessage="InterAffiliate Dashboard"
      />
    ),
    exact: true,
    component: InterAffiliateDashboard,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/mastercard/dashboard",
    name: (
      <FormattedMessage
        id="Mastercard Dashboard"
        defaultMessage="Mastercard Dashboard"
      />
    ),
    exact: true,
    component: MastercardDashboard,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/gim/settlement",
    name: (
      <FormattedMessage id="gimSettlement" defaultMessage="GIM Settlement" />
    ),
    exact: true,
    component: GimSettlement,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/gim/settlement/posting-details",
    name: (
      <FormattedMessage
        id="gimPostingDetail"
        defaultMessage="GIM Posting Detail"
      />
    ),
    exact: true,
    component: GimPostingDetail,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/gim/settlement/:id/query/details",
    name: (
      <FormattedMessage id="Query Details" defaultMessage="Query Details" />
    ),
    exact: true,
    component: GimQueryDetails,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/gim/settlement/summary-detail-:id",
    name: (
      <FormattedMessage
        id="gimSummaryDetail"
        defaultMessage="GIM Summary Detail"
      />
    ),
    exact: true,
    component: GimSummaryDetail,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/gim/settlement/file-summary-:id",
    name: (
      <FormattedMessage id="gimFileSummary" defaultMessage="GIM File Summary" />
    ),
    exact: true,
    component: GimFileSummary,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/gim/settlement/file-detail-:id",
    name: (
      <FormattedMessage
        id="gimFileContent"
        defaultMessage="Transaction Details"
      />
    ),
    exact: true,
    component: GimFileContent,
    viewPermission: "settlement.can_view_gim",
  },
  {
    path: "/mastercard/settlement",
    name: (
      <FormattedMessage
        id="Mastercard Settlement"
        defaultMessage="Mastercard Settlement"
      />
    ),
    exact: true,
    component: MastercardSettlement,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/masterpass/settlement",
    name: <FormattedMessage id="Masterpass" defaultMessage="Masterpass" />,
    exact: true,
    component: MasterpassSettlement,
    viewPermission: "settlement.can_view_masterpass",
  },
  {
    path: "/masterpass/settlement/:id/details",
    name: (
      <FormattedMessage
        id="Masterpass Details"
        defaultMessage="Masterpass Details"
      />
    ),
    component: MasterpassDetails,
    exact: true,
    viewPermission: "settlement.can_view_masterpass",
  },
  {
    path: "/masterpass/settlement/:id/query/details",
    name: (
      <FormattedMessage
        id="Masterpass Query Details"
        defaultMessage="Masterpass Query Details"
      />
    ),
    component: MasterpassQueryDetails,
    exact: true,
    viewPermission: "settlement.can_view_masterpass",
  },
  {
    path: "/masterpass/reports",
    name: (
      <FormattedMessage
        id="Masterpass Reports"
        defaultMessage="Masterpass Reports"
      />
    ),
    component: MasterpassReportsIndex,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  {
    path: "/masterpass/settlement/reports/issuer-item/:id",
    name: (
      <FormattedMessage
        id="Masterpass Issuers"
        defaultMessage="Masterpass Issuers"
      />
    ),
    component: MasterpassIssuers,
    exact: true,
    viewPermission: "settlement.can_view_masterpass",
  },
  {
    path: "/masterpass/settlement/reports/acquirer-item/:id",
    name: (
      <FormattedMessage
        id="Masterpass Acquirers"
        defaultMessage="Masterpass Acquirers"
      />
    ),
    component: MasterpassAcquirers,
    exact: true,
    viewPermission: "settlement.can_view_masterpass",
  },
  {
    path: "/masterpass/reports/posting",
    name: (
      <FormattedMessage
        id="Masterpass Posting Report"
        defaultMessage="Masterpass Posting Report"
      />
    ),
    component: MasterpassPostingReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  // start
  {
    path: "/masterpass/reports/settlement-report",
    name: (
      <FormattedMessage
        id="Masterpass Settlement Report"
        defaultMessage="Masterpass Settlement Report"
      />
    ),
    component: MasterpassSettlementReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  {
    path: "/masterpass/reports/fees-report",
    name: (
      <FormattedMessage
        id="Masterpass Fees Report"
        defaultMessage="Masterpass Fees Report"
      />
    ),
    component: MasterpassFeesReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  {
    path: "/masterpass/reports/summ-report",
    name: (
      <FormattedMessage
        id="Masterpass Summ Report"
        defaultMessage="Masterpass Summ Report"
      />
    ),
    component: MasterpassSummReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  {
    path: "/masterpass/reports/acquirer-report",
    name: (
      <FormattedMessage
        id="Masterpass Acquirer Report"
        defaultMessage="Masterpass Acquirer Report"
      />
    ),
    component: MasterpassAcquirerReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  {
    path: "/masterpass/reports/issuer-report",
    name: (
      <FormattedMessage
        id="Masterpass Issuer Report"
        defaultMessage="Masterpass Issuer Report"
      />
    ),
    component: MasterpassIssuerReport,
    exact: true,
    viewPermission: "settlement.can_view_masterpass_reports",
  },
  // end
  {
    path: "/masterpass/settlement/reports/summary-detail/:id",
    name: (
      <FormattedMessage
        id="Masterpass Posting Details"
        defaultMessage="Masterpass Posting Details"
      />
    ),
    component: MasterpassPostingDetails,
    exact: true,
    viewPermission: "settlement.can_view_chinapay_summary", //to be created and changed for masterpass
  },
  {
    path: "/cup/settlement",
    name: (
      <FormattedMessage id="China Union Pay" defaultMessage="China Union Pay" />
    ),
    exact: true,
    component: ChinaUnionPaySettlement,
    viewPermission: "settlement.can_view_chinapay_summary",
  },
  {
    path: "/cup/settlement/acquirer/:id/details",
    name: (
      <FormattedMessage
        id="Acquirer File Details"
        defaultMessage="Acquirer File Details"
      />
    ),
    exact: true,
    component: ChinaUnionPayAcquirerFileDetails,
    viewPermission: "settlement.can_view_chinapay_details",
  },
  {
    path: "/cup/settlement/file-summary-detail/:id",
    name: (
      <FormattedMessage
        id="File Summary Details"
        defaultMessage="File Summary Details"
      />
    ),
    exact: true,
    component: ChinaUnionPayFileSummaryDetails,
    viewPermission: "settlement.can_view_chinapay_details",
  },
  {
    path: "/cup/settlement/posting-detail/details",
    name: (
      <FormattedMessage id="Posting Details" defaultMessage="Posting Details" />
    ),
    exact: true,
    component: ChinaUnionPayPostingDetails,
    viewPermission: "settlement.can_view_chinapay_details",
  },
  {
    path: "/cup/settlement/:id/query/details",
    name: (
      <FormattedMessage
        id="Affiliate Posting Entries"
        defaultMessage="Affiliate Posting Entries"
      />
    ),
    component: ChinaUnionPayPostingEntries,
    exact: true,
    viewPermission: "settlement.can_view_chinapay_details",
  },
  {
    path: "/interAffiliate/settlement",
    name: (
      <FormattedMessage
        id="InterAffiliate Settlement"
        defaultMessage="InterAffiliate Settlement"
      />
    ),
    exact: true,
    component: InterAffiliateSettlement,
    viewPermission: [
      "settlement.can_view_interaffiliates",
      "settlement.can_view_schemes",
    ],
  },
  {
    path: "/interAffiliate/settlement/acquirer/:date/summary",
    name: (
      <FormattedMessage
        id="InterAffiliate Acquirer Summary"
        defaultMessage="InterAffiliate Acquirer Summary"
      />
    ),
    component: InterAffiliateAcquirerSummary,
    exact: true,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interAffiliate/settlement/issuer/:date/summary",
    name: (
      <FormattedMessage
        id="InterAffiliate Issuer Summary"
        defaultMessage="InterAffiliate Issuer Summary"
      />
    ),
    component: InterAffiliateIssuerSummary,
    exact: true,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/xpresscash/settlement",
    name: (
      <FormattedMessage
        id="Express Cash Settlement"
        defaultMessage="Express Cash Settlement"
      />
    ),
    component: ExpressCash,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/settlement/:id/query/details",
    name: (
      <FormattedMessage
        id="Express Cash Query Details"
        defaultMessage="Express Cash Query Details"
      />
    ),
    component: ExpressCashQueryDetails,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/settlement/:id/details",
    name: (
      <FormattedMessage
        id="Express Cash Details"
        defaultMessage="Express Cash Details"
      />
    ),
    component: ExpressCashDetails,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/settlement/reports/acquirer-item/:id",
    name: (
      <FormattedMessage
        id="Express Cash Acquirer Report"
        defaultMessage="Express Cash Acquirer Report"
      />
    ),
    component: ExpressCashAcquirer,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/settlement/reports/summary-detail/:id",
    name: (
      <FormattedMessage
        id="Express Cash Posting Details"
        defaultMessage="Express Cash Posting Details"
      />
    ),
    component: ExpressCashPostingDetails,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/settlement/reports/issuer-item/:id",
    name: (
      <FormattedMessage
        id="Express Cash Issuer Report"
        defaultMessage="Express Cash Issuer Report"
      />
    ),
    component: ExpressCashIssuer,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash",
  },
  {
    path: "/xpresscash/reports",
    name: (
      <FormattedMessage
        id="Express Cash Reports"
        defaultMessage="Express Cash Reports"
      />
    ),
    component: ExpressCashReportIndex,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/settlement-report",
    name: (
      <FormattedMessage
        id="Express Cash Settlement Report"
        defaultMessage="Express Cash Settlement Report"
      />
    ),
    component: ExpressCashSettlementReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/posting",
    name: (
      <FormattedMessage
        id="Express Cash Posting Report"
        defaultMessage="Express Cash Posting Report"
      />
    ),
    component: ExpressCashPostingReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/fees-report",
    name: (
      <FormattedMessage
        id="Express Cash Fees Report"
        defaultMessage="Express Cash Fees Report"
      />
    ),
    component: ExpressCashFeesReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/summ-report",
    name: (
      <FormattedMessage
        id="Express Cash Summ Report"
        defaultMessage="Express Cash Summ Report"
      />
    ),
    component: ExpressCashSummReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/acquirer-report",
    name: (
      <FormattedMessage
        id="Express Cash Acquirer Report"
        defaultMessage="Express Cash Acquirer Report"
      />
    ),
    component: ExpressCashAcquirerReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/xpresscash/reports/issuer-report",
    name: (
      <FormattedMessage
        id="Express Cash Issuer Report"
        defaultMessage="Express Cash Issuer Report"
      />
    ),
    component: ExpressCashIssuerReport,
    exact: true,
    viewPermission: "settlement.can_view_xpresscash_reports",
  },
  {
    path: "/mvisa/settlement",
    name: (
      <FormattedMessage
        id="MVISA Settlement"
        defaultMessage="MVISA Settlement"
      />
    ),
    component: MVISASettlement,
    exact: true,
    viewPermission: "settlement.can_view_mvisa",
  },
  {
    path: "/mvisa/settlement/:id/query/details",
    name: (
      <FormattedMessage
        id="MVISA Query Details"
        defaultMessage="MVISA Query Details"
      />
    ),
    component: MVISAQueryDetails,
    exact: true,
    viewPermission: "settlement.can_view_mvisa",
  },
  {
    path: "/mvisa/settlement/:id/details",
    name: (
      <FormattedMessage id="MVISA Details" defaultMessage="MVISA Details" />
    ),
    component: MVISADetails,
    exact: true,
    viewPermission: "settlement.can_view_mvisa",
  },
  {
    path: "/mvisa/settlement/reports/issuer-item/:id",
    name: <FormattedMessage id="MVISA Issuer" defaultMessage="MVISA Issuer" />,
    component: MVISAIssuer,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/settlement/reports/acquirer-item/:id",
    name: (
      <FormattedMessage id="MVISA Acquuirer" defaultMessage="MVISA Acquuirer" />
    ),
    component: MVISAAcquirer,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/settlement/reports/summary-detail/:id",
    name: (
      <FormattedMessage
        id="MVISA Summary Details"
        defaultMessage="MVISA Summary Details"
      />
    ),
    component: MVISASummaryDetail,
    exact: true,
    viewPermission: "settlement.can_view_mvisa",
  },
  {
    path: "/mvisa/reports",
    name: (
      <FormattedMessage id="MVISA Reports" defaultMessage="MVISA Reports" />
    ),
    component: MVISAReportIndex,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/settlement-report",
    name: (
      <FormattedMessage
        id="MVISA Settlement Report"
        defaultMessage="MVISA Settlement Report"
      />
    ),
    component: MVISASettlementReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/posting",
    name: (
      <FormattedMessage
        id="MVISA Posting Report"
        defaultMessage="MVISA Posting Report"
      />
    ),
    component: MVISAPostingReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/fees-report",
    name: (
      <FormattedMessage
        id="MVISA Fees Report"
        defaultMessage="MVISA Fees Report"
      />
    ),
    component: MVISAFeesReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/summ-report",
    name: (
      <FormattedMessage
        id="MVISA Summ Report"
        defaultMessage="MVISA Summ Report"
      />
    ),
    component: MVISASummReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/acquirer-report",
    name: (
      <FormattedMessage
        id="MVISA Acquirer Report"
        defaultMessage="MVISA Acquirer Report"
      />
    ),
    component: MVISAAcquirerReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/mvisa/reports/issuer-report",
    name: (
      <FormattedMessage
        id="MVISA Issuer Report"
        defaultMessage="MVISA Issuer Report"
      />
    ),
    component: MVISAIssuerReport,
    exact: true,
    viewPermission: "settlement.can_view_mvisa_reports",
  },
  {
    path: "/visa/settlement",
    name: (
      <FormattedMessage id="Visa Settlement" defaultMessage="Visa Settlement" />
    ),
    exact: true,
    component: VisaSettlement,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/interswitch/settlement",
    name: (
      <FormattedMessage
        id="Interswitch Settlement"
        defaultMessage="Interswitch Settlement"
      />
    ),
    exact: true,
    component: InterswitchSettlement,
  },
  {
    path: "/interswitch/settlement/:id/summary",
    name: (
      <FormattedMessage
        id="Interswitch Settlement Summary"
        defaultMessage="Interswitch Settlement Summary"
      />
    ),
    exact: true,
    component: InterswitchSettlementDailySummary,
  },
  {
    path: "/visa/settlement/vssFiles/:date",
    name: <FormattedMessage id="VSS Files" defaultMessage="VSS Files" />,
    exact: true,
    component: VSSFiles,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss110",
    name: <FormattedMessage id="VSS 110" defaultMessage="VSS 110" />,
    exact: true,
    component: AllVss110,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss116",
    name: <FormattedMessage id="VSS 116" defaultMessage="VSS 116" />,
    exact: true,
    component: AllVss116,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss116/details",
    name: (
      <FormattedMessage id="VSS 116 Details" defaultMessage="VSS 116 Details" />
    ),
    exact: true,
    component: VSS116DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss120",
    name: <FormattedMessage id="VSS 120" defaultMessage="VSS 120" />,
    exact: true,
    component: AllVss120,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss120/details",
    name: (
      <FormattedMessage id="VSS 120 Details" defaultMessage="VSS 120 Details" />
    ),
    exact: true,
    component: VSS120DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss115",
    name: <FormattedMessage id="VSS 115" defaultMessage="VSS 115" />,
    exact: true,
    component: AllVss115,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss115/details",
    name: (
      <FormattedMessage id="VSS 115 Details" defaultMessage="VSS 115 Details" />
    ),
    exact: true,
    component: VSS115DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss300",
    name: <FormattedMessage id="VSS 300" defaultMessage="VSS 300" />,
    exact: true,
    component: AllVss300,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss300/details",
    name: (
      <FormattedMessage id="VSS 300 Details" defaultMessage="VSS 300 Details" />
    ),
    exact: true,
    component: VSS300DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/vss900s",
    name: <FormattedMessage id="VSS 900" defaultMessage="VSS 900" />,
    exact: true,
    component: AllVss900,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss900s/details",
    name: (
      <FormattedMessage id="VSS 900 Details" defaultMessage="VSS 900 Details" />
    ),
    exact: true,
    component: VSS900DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/vss110/details",
    name: (
      <FormattedMessage id="VSS 110 Details" defaultMessage="VSS 110 Details" />
    ),
    exact: true,
    component: Vss110DailySummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/interAffiliate/settlement/acquirer/:date/transactions",
    name: (
      <FormattedMessage
        id="Acquirer Transactions"
        defaultMessage="Acquirer Transactions"
      />
    ),
    exact: true,
    component: InterAffiliateAcquirerTransactions,
    viewPermission: "settlement.can_view_interaffiliates",
  },

  {
    path: "/interAffiliate/settlement/issuer/:date/transactions",
    name: (
      <FormattedMessage
        id="Issuer Transactions"
        defaultMessage="Issuer Transactions"
      />
    ),
    exact: true,
    component: InterAffiliateIssuerTransactions,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/visa/settlement/acquirer/:date/transactions/:searchBy/:page",
    name: (
      <FormattedMessage
        id="Acquirer Transactions"
        defaultMessage="Acquirer Transactions"
      />
    ),
    exact: true,
    component: VisaAcquirerTransactions,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/issuer/:date/transactions/:searchBy/:page",
    name: (
      <FormattedMessage
        id="Issuer Transactions"
        defaultMessage="Issuer Transactions"
      />
    ),
    exact: true,
    component: VisaIssuerTransactions,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/interAffiliate/settlement/summary/:date/transactions",
    name: (
      <FormattedMessage
        id="Summary Transactions"
        defaultMessage="Summary Transactions"
      />
    ),
    exact: true,
    component: InterAffiliateSummaryTransactions,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interAffiliate/settlement/:date/summary",
    name: (
      <FormattedMessage
        id="InterAffiliate Summary"
        defaultMessage="InterAffiliate Summary"
      />
    ),
    exact: true,
    component: InterAffiliateSummary,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interAffiliate/settlement/source/summary",
    name: (
      <FormattedMessage
        id="InterAffiliate Summary"
        defaultMessage="InterAffiliate Summary"
      />
    ),
    exact: true,
    component: InterAffiliateSourceSummary,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interAffiliate/settlement/source/issuer/:date/item/:page",
    name: (
      <FormattedMessage
        id="InterAffiliate Details"
        defaultMessage="InterAffiliate Details"
      />
    ),
    exact: true,
    component: InterAffiliateSourceIssuerItem,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interAffiliate/settlement/source/acquirer/:date/item/:page",
    name: (
      <FormattedMessage
        id="InterAffiliate Details"
        defaultMessage="InterAffiliate Details"
      />
    ),
    exact: true,
    component: InterAffiliateSourceAcquirerItem,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/visa/settlement/:date/posting",
    name: <FormattedMessage id="Visa Summary" defaultMessage="Visa Summary" />,
    exact: true,
    component: VisaSummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:date/combine-posting-summary",
    name: (
      <FormattedMessage
        id="Combine Visa Posting Report"
        defaultMessage="Combine Visa Posting Report"
      />
    ),
    exact: true,
    component: VisaPostingReport,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/issuer/:date/posting-summary",
    name: (
      <FormattedMessage
        id="Visa Issuer Posting Report"
        defaultMessage="Visa Issuer Posting Report"
      />
    ),
    exact: true,
    component: VisaIssuerPostingReport,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/acquirer/:date/posting-summary",
    name: (
      <FormattedMessage
        id="Visa Acquirer Posting Report"
        defaultMessage="Visa Acquirer Posting Report"
      />
    ),
    exact: true,
    component: VisaAcquirerPostingReport,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/issuer/:date/unvalidated-entries",
    name: (
      <FormattedMessage
        id="Visa Issuer Unvalidated Entries"
        defaultMessage="Visa Issuer Unvalidated Entries"
      />
    ),
    exact: true,
    component: VisaIssuerUnValidatedEntries,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/acquirer/:date/unvalidated-entries",
    name: (
      <FormattedMessage
        id="Visa Acquirer Unvalidated Entries"
        defaultMessage="Visa Acquirer Unvalidated Entries"
      />
    ),
    exact: true,
    component: VisaAcquirerUnValidatedEntries,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/issuer/:date/validated-entries",
    name: (
      <FormattedMessage
        id="Visa Issuer Validated Entries"
        defaultMessage="Visa Issuer Validated Entries"
      />
    ),
    exact: true,
    component: VisaIssuerValidatedEntries,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/acquirer/:date/validated-entries",
    name: (
      <FormattedMessage
        id="Visa Acquirer Validated Entries"
        defaultMessage="Visa Acquirer Validated Entries"
      />
    ),
    exact: true,
    component: VisaAcquirerValidatedEntries,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/acquirers/:date/summary",
    name: (
      <FormattedMessage
        id="Visa Acquirer Summary"
        defaultMessage="Visa Acquirer Summary"
      />
    ),
    exact: true,
    component: VisaAcquirerSummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/issuers/:date/summary",
    name: (
      <FormattedMessage
        id="Visa Issuer Summary"
        defaultMessage="Visa Issuer Summary"
      />
    ),
    exact: true,
    component: VisaIssuerSummary,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/oct/:id/details",
    name: (
      <FormattedMessage
        id="Visa OCT Details"
        defaultMessage="Visa OCT Details"
      />
    ),
    exact: true,
    component: VisaOctTransacactions,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/visa/settlement/:id/details",
    name: (
      <FormattedMessage
        id="Visa Posting Details"
        defaultMessage="Visa Posting Details"
      />
    ),
    exact: true,
    component: VisaPostingDetails,
    viewPermission: "settlement.can_view_visa",
  },
  {
    path: "/user-management",
    name: (
      <FormattedMessage id="User Management" defaultMessage="User Management" />
    ),
    exact: true,
    component: UserManagement,
    viewPermission: "settlement.can_manage_users",
  },
  {
    path: "/users-list",
    name: <FormattedMessage id="Users" defaultMessage="Users" />,
    exact: true,
    component: Users,
    viewPermission: "settlement.can_manage_users",
  },
  {
    path: "/posting-summary",
    name: (
      <FormattedMessage id="Posting Summary" defaultMessage="Posting Summary" />
    ),
    exact: true,
    component: DailyPostingSummary,
  },
  {
    path: "/merchant-payments",
    name: (
      <FormattedMessage
        id="Merchant Payments"
        defaultMessage="Merchant Payments"
      />
    ),
    exact: true,
    component: MerchantPayment,
  },
  {
    path: "/merchant-payments/:id/details",
    name: (
      <FormattedMessage
        id="Merchant Payment Details"
        defaultMessage="Merchant Payment Details"
      />
    ),
    exact: true,
    component: MerchantPaymentDetails,
  },
  {
    path: "/affiliate/exchange-rates/",
    name: <FormattedMessage id="Fx History" defaultMessage="Fx History" />,
    exact: true,
    component: History,
    viewPermission: "settlement.can_view_forex",
  },
  {
    path: "/downstream/logs/:logType",
    name: (
      <FormattedMessage id="Downstream Log" defaultMessage="Downstream Log" />
    ),
    exact: true,
    component: LogSummary,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/postilion/:date/:page",
    name: (
      <FormattedMessage
        id="Postilion Details"
        defaultMessage="Postilion Details"
      />
    ),
    exact: true,
    component: PostilionDetails,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/flexcube/:date/:page",
    name: (
      <FormattedMessage
        id="Flexcube Details"
        defaultMessage="Flexcube Details"
      />
    ),
    exact: true,
    component: FlexcubeDetails,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/pos-acquirers/:date/:page",
    name: (
      <FormattedMessage
        id="POS Acquirers Details"
        defaultMessage="POS Acquirers Details"
      />
    ),
    exact: true,
    component: POSacquirersDetails,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/postilion/:date/:searchBy/:searchValue/",
    name: (
      <FormattedMessage
        id="Postilion Search Result"
        defaultMessage="Postilion Search Result"
      />
    ),
    exact: true,
    component: PostilionSummarySearchResult,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/flexcube/:date/:searchBy/:searchValue",
    name: (
      <FormattedMessage
        id="Flexcube Search Result"
        defaultMessage="Flexcube Search Result"
      />
    ),
    exact: true,
    component: FlexcubeSummarySearchResult,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/downstream/logs/pos-acquirers/:date/:searchBy/:searchValue",
    name: (
      <FormattedMessage
        id="POS Acquirers Search Result"
        defaultMessage="POS Acquirers Search Result"
      />
    ),
    exact: true,
    component: POSacquirerSummarySearchResult,
    viewPermission: "settlement.can_view_downstream_logs",
  },
  {
    path: "/uploads",
    name: <FormattedMessage id="Upload Files" defaultMessage="Upload Files" />,
    exact: true,
    component: FileUpload,
    viewPermission: "settlement.can_upload_files",
  },
  {
    path: "/collections",
    name: <FormattedMessage id="Collections" defaultMessage="Collections" />,
    exact: true,
    component: Collections,
    viewPermission: "settlement.can_list_collections",
  },
  {
    path: "/narrations",
    name: <FormattedMessage id="Narrattions" defaultMessage="Narrations" />,
    exact: true,
    component: Narrations,
    viewPermission: "settlement.can_view_narration",
  },
  {
    path: "/directorylisting",
    name: (
      <FormattedMessage
        id="Directory Listing"
        defaultMessage="Directory Listing"
      />
    ),
    exact: true,
    component: DirectoryListing,
    viewPermission: "settlement.can_list_directory",
  },
  {
    path: "/mastercard/settlement/advisement/:date/summary",
    name: (
      <FormattedMessage
        id="Advisement Summary"
        defaultMessage="Advisement Summary"
      />
    ),
    exact: true,
    component: AdvisementSummary,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/interaffiliate/settlement/postoffice/:date/summary/:page",
    name: <FormattedMessage id="Post Office" defaultMessage="Post Office" />,
    exact: true,
    component: PostOffice,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interaffiliate/reports/gross-settlement",
    name: (
      <FormattedMessage
        id="Gross Settlement"
        defaultMessage="Gross Settlement"
      />
    ),
    exact: true,
    component: GrossSettlement,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/register-settlement",
    name: (
      <FormattedMessage
        id="Register Settlement"
        defaultMessage="Register Settlement"
      />
    ),
    exact: true,
    component: RegisterSettlement,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/acquirer-status",
    name: (
      <FormattedMessage id="Acquirer Status" defaultMessage="Acquirer Status" />
    ),
    exact: true,
    component: AcquirerStatus,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/settlemment-report",
    name: (
      <FormattedMessage
        id="Settlement Report"
        defaultMessage="Settlement Report"
      />
    ),
    exact: true,
    component: SettlementReport,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/settlement-breakdown",
    name: (
      <FormattedMessage
        id="Settlement BreakDown"
        defaultMessage="Settlement BreakDown"
      />
    ),
    exact: true,
    component: SettlementBreakDown,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/posting-report",
    name: (
      <FormattedMessage id="Posting Report" defaultMessage="Posting Report" />
    ),
    exact: true,
    component: PostingReport,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/settlement/reports/affiliate-posting-report",
    name: (
      <FormattedMessage
        id="Affiliate Posting Report"
        defaultMessage="Affiliate Posting Report"
      />
    ),
    exact: true,
    component: AffiliatePostingReport,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interaffiliate/reports/sum-settlement",
    name: (
      <FormattedMessage id="Sum Settlement" defaultMessage="Sum Settlement" />
    ),
    exact: true,
    component: SumSettlement,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/region-codes",
    name: <FormattedMessage id="Region Codes" defaultMessage="Region Codes" />,
    exact: true,
    component: RegionCode,
    viewPermission: "settlement.can_view_regions",
  },
  {
    path: "/interaffiliate/reports/region-codes/add",
    name: (
      <FormattedMessage
        id="Add Region Codes"
        defaultMessage="Add Region Codes"
      />
    ),
    exact: true,
    component: AddRegionCode,
    viewPermission: "settlement.can_manage_regions",
  },
  {
    path: "/interaffiliate/settlement/reports/issuer-item",
    name: (
      <FormattedMessage
        id="Issuer Report Source Item"
        defaultMessage="Issuer Report Source Item"
      />
    ),
    exact: true,
    component: IssuerInterAffiliateSourceItem,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interaffiliate/settlement/reports/acquirer-item",
    name: (
      <FormattedMessage
        id="Acquirer Report Source Item"
        defaultMessage="Acquirer Report Source Item"
      />
    ),
    exact: true,
    component: AcquirerInterAffiliateSourceItem,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interaffiliate/settlement/reports/posting-summary",
    name: (
      <FormattedMessage id="Posting Summary" defaultMessage="Posting Summary" />
    ),
    exact: true,
    component: AffiliatePostingSummary,
    viewPermission: "settlement.can_view_interaffiliates",
  },
  {
    path: "/interaffiliate/reports/sum-settlement-fees",
    name: (
      <FormattedMessage
        id="Sum Settlement Fees"
        defaultMessage="Sum Settlement Fees"
      />
    ),
    exact: true,
    component: SumSettlementFees,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/issuer-audit",
    name: <FormattedMessage id="Issuer Audit" defaultMessage="Issuer Audit" />,
    exact: true,
    component: IssuerAudit,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/reports/acquirer-audit",
    name: (
      <FormattedMessage id="Acquirer Audit" defaultMessage="Acquirer Audit" />
    ),
    exact: true,
    component: AcquirerAudit,
    viewPermission: "can_view_interaffiliates",
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/interaffiliate/settlement/reports/:id/posting-details",
    name: (
      <FormattedMessage
        id="InterAffiliate Posting Details"
        defaultMessage="InterAffiliate Posting Details"
      />
    ),
    exact: true,
    component: InterAffiliatePostingDetails,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/mastercard/settlement/reports/posting-details",
    name: (
      <FormattedMessage
        id="Mastercard Posting Details"
        defaultMessage="Mastercard Posting Details"
      />
    ),
    exact: true,
    component: MastercardPostingDetails,
    viewPermission: "settlement.can_view_mastercard",
  },
  {
    path: "/mastercard/settlement/:id/query/details",
    name: (
      <FormattedMessage id="Query Details" defaultMessage="Query Details" />
    ),
    exact: true,
    component: MastercardQueryDetails,
    viewPermission: "settlement.can_view_mastercard_reports",
  },
  {
    path: "/admin/audit",
    name: <FormattedMessage id="Audit View" defaultMessage="Audit View" />,
    exact: true,
    component: Audit,
    viewPermission: "settlement.can_view_audit_logs",
  },
  {
    path: "/interaffiliate/settlement/:id/query/details",
    name: (
      <FormattedMessage id="Query Details" defaultMessage="Query Details" />
    ),
    exact: true,
    component: InteraffiliateQueryDetails,
    viewPermission: "settlement.can_view_interaffiliate_reports",
  },
  {
    path: "/narrations",
    name: <FormattedMessage id="Narrations" defaultMessage="Narrations" />,
    exact: true,
    component: Narrations,
    viewPermission: "settlement.can_manage_collections",
  },
  {
    path: "/ecommerce/settlement",
    name: (
      <FormattedMessage
        id="Ecommerce Settlement"
        defaultMessage="Ecommerce Settlement"
      />
    ),
    exact: true,
    component: EcommerceSettlement,
    viewPermission: "settlement.can_view_ecommerce",
  },
  {
    path: "/ecommerce/settlement/:id/details",
    name: (
      <FormattedMessage
        id="Ecommerce Settlement"
        defaultMessage="Ecommerce Settlement"
      />
    ),
    exact: true,
    component: EcommerceDetails,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/settlement/issuer/:id/details/",
    name: (
      <FormattedMessage id="Issuer Details" defaultMessage="Issuer Details" />
    ),
    exact: true,
    component: EcommerceIssuerDetails,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/settlement/acquirer/:id/details/",
    name: (
      <FormattedMessage
        id="Acquirer Details"
        defaultMessage="Acquirer Details"
      />
    ),
    exact: true,
    component: EcommerceAcquirerDetails,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports",
    name: (
      <FormattedMessage
        id="Ecommerce Report"
        defaultMessage="Ecommerce Report"
      />
    ),
    exact: true,
    component: EcommerceReports,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/settlement-report",
    name: (
      <FormattedMessage
        id="Ecommerce Settlement Report"
        defaultMessage="Ecommerce Settlement Report"
      />
    ),
    component: EcommerceSettlementReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/posting",
    name: (
      <FormattedMessage
        id="Ecommerce Posting Report"
        defaultMessage="Ecommerce Posting Report"
      />
    ),
    component: EcommercePostingReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/fees-report",
    name: (
      <FormattedMessage
        id="Ecommerce Fees Report"
        defaultMessage="Ecommerce Fees Report"
      />
    ),
    component: EcommerceFeesReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/summ-report",
    name: (
      <FormattedMessage
        id="Ecommerce Summ Report"
        defaultMessage="Ecommerce Summ Report"
      />
    ),
    component: EcommerceSummReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/acquirer-report",
    name: (
      <FormattedMessage
        id="Ecommerce Acquirer Report"
        defaultMessage="Ecommerce Acquirer Report"
      />
    ),
    component: EcommerceAcquirerReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/reports/issuer-report",
    name: (
      <FormattedMessage
        id="Ecommerce Issuer Report"
        defaultMessage="Ecommerce Issuer Report"
      />
    ),
    component: EcommerceIssuerReport,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/settlement/reports/summary-detail/:id",
    name: (
      <FormattedMessage
        id="Ecommerce Posting Details"
        defaultMessage="Ecommerce Posting Details"
      />
    ),
    component: EcommercePostingDetails,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/ecommerce/settlement/:id/query/details",
    name: (
      <FormattedMessage
        id="Ecommerce Query Details"
        defaultMessage="Ecommerce Query Details"
      />
    ),
    component: EcommerceQueryDetails,
    exact: true,
    viewPermission: "settlement.can_view_ecommerce_reports",
  },
  {
    path: "/mastercard/reports/nostro-reports",
    name: (
      <FormattedMessage
        id="Mastercard Nostro Reports"
        defaultMessage="Mastercard Nostro Reports"
      />
    ),
    component: NostroReports,
    exact: true,
    viewPermission: "settlement.can_view_nostro_reports",
  },
  {
    path: "/visa/reports/nostro-reports",
    name: (
      <FormattedMessage
        id="Visa Nostro Reports"
        defaultMessage="Visa Nostro Reports"
      />
    ),
    component: NostroReports,
    exact: true,
    viewPermission: "settlement.can_view_nostro_reports",
  },
  {
    path: "/billing/reports",
    name: (
      <FormattedMessage id="Billing Report" defaultMessage="Billing Report" />
    ),
    component: BillingReport,
    exact: true,
    viewPermission: "settlement.can_view_visa_reports", //change later
  },
  {
    path: "/posting",
    name: <FormattedMessage id="Posting" defaultMessage="Posting" />,
    component: Posting,
    exact: true,
    viewPermission: "settlement.can_view_posting",
  },
  {
    path: "/admin/reports",
    exact: true,
    name: <FormattedMessage id="reports" defaultMessage="Reports" />,
    component: AdminReports,
    viewPermission: "settlement.can_view_admin_reports",
  },
  {
    path: "/admin/posting-transaction",
    exact: true,
    name: (
      <FormattedMessage
        id="postransaction"
        defaultMessage="PostingTransaction"
      />
    ),
    component: PostingTransaction,
    viewPermission: "settlement.can_view_admin_reports",
  },
  {
    path: "/mastercard/settlement/resolved",
    exact: true,
    name: <FormattedMessage id="resolved" defaultMessage="Resolved Files" />,
    component: MastercardResolvedFiles,
  },
  {
    path: "/admin/cash-billing-reports",
    exact: true,
    name: (
      <FormattedMessage
        id="cashbillingreports"
        defaultMessage="Cash Billing Reports"
      />
    ),
    component: BillingReports,
    viewPermission: "settlement.can_view_admin_reports",
  },
  {
    path: "/admin/cash-billing-reports/cash-management",
    exact: true,
    name: (
      <FormattedMessage
        id="cashmanagements"
        defaultMessage="Cash Managements"
      />
    ),
    component: CashManagement,

    viewPermission: "settlement.can_view_admin_reports",
  },
  {
    path: "/admin/cash-billing-reports/consumer",
    exact: true,
    name: <FormattedMessage id="consumer" defaultMessage="Consumer" />,
    component: Consumer,
    viewPermission: "settlement.can_view_admin_reports",
  }, 
];

export default routes;
