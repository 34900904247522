
import {
    GET_REGISTERED_SCHEMES, GET_REGISTERED_SCHEMES_SUCCESS, GET_REGISTERED_SCHEMES_FAILURE,
    GET_APPROVED_AFFILIATE_SCHEME,
    GET_APPROVED_AFFILIATE_SCHEME_SUCCESS,
    GET_APPROVED_AFFILIATE_SCHEME_FAILURE,
    GET_REPORT_SCHEMES,
    GET_REPORT_SCHEMES_SUCCESS,
    GET_REPORT_SCHEMES_FAILURE,
} from '../actions/types';


const initialState = {
    schemes: [],
    schemesLoading: false,
    schemesError: "",
    approvedSchemes: [],
    approvedSchemesLoading: false,
    approvedSchemesError: "",
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REGISTERED_SCHEMES: {
            return { ...state, schemesLoading: action.payload, schemesError: "" }
        }
        case GET_REGISTERED_SCHEMES_SUCCESS: {
            return { ...state, schemesLoading: false, schemesError: "", schemes: action.payload }
        }
        case GET_REGISTERED_SCHEMES_FAILURE: {
            return { ...state, schemesLoading: false, schemesError: action.payload }
        }
        case GET_APPROVED_AFFILIATE_SCHEME: {
            return { ...state, approvedSchemesLoading: action.payload, approvedSchemesError: "" }
        }
        case GET_APPROVED_AFFILIATE_SCHEME_SUCCESS: {
            return { ...state, approvedSchemesLoading: false, approvedSchemesError: "", approvedSchemes: action.payload }
        }
        case GET_APPROVED_AFFILIATE_SCHEME_FAILURE: {
            return { ...state, approvedSchemesLoading: false, approvedSchemesError: action.payload }
        }
        case GET_REPORT_SCHEMES: {
            return { ...state, schemesLoading: true, schemesError: "" };
          }
          case GET_REPORT_SCHEMES_SUCCESS: {
            return {
              ...state,
              schemesLoading: false,
              schemesError: "",
              schemes: action.payload,
            };
          }
          case GET_REPORT_SCHEMES_FAILURE: {
            return { ...state, schemesLoading: false, schemesError: action.payload };
          }
        default:
            return state;
    }
}