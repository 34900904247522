import {
  CREATE_FILE_SET,
  CREATE_FILE_SET_ERROR,
  GET_FILE_SETS,
  GET_FILE_SETS_ERROR,
  UPDATE_FILE_SET,
  UPDATE_FILE_SET_ERROR,
  DELETE_FILE_SET,
  DELETE_FILE_SET_ERROR,
  GET_FILE_SETS_PAGINATED,
  GET_FILE_SETS_PAGINATED_ERROR,
  GET_FILE_SET,
  GET_FILE_SET_ERROR,
  GET_FILE_SET_DEFINITIONS,
  GET_FILE_SET_DEFINITIONS_ERROR,
  ADD_FILE_DEFINITIONS_TO_FILESET,
  ADD_FILE_DEFINITIONS_TO_FILESET_ERROR,
  DELETE_FILE_SET_FILE_DEFINITION,
  DELETE_FILE_SET_FILE_DEFINITION_ERROR,
  GET_FILE_SET_BATCHES_PAGINATED,
  GET_FILE_SET_BATCHES_PAGINATED_ERROR,
  GET_FILE_SET_DEFINITION_BATCHES_PAGINATED,
  GET_FILE_SET_DEFINITION_BATCHES_PAGINATED_ERROR,
  GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED,
  GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR,
  GET_FILESET_BATCH_DETAIL,
  GET_FILESET_BATCH_DETAIL_ERROR,
  GET_FILESET_BATCH_DEFINITION_DETAIL,
  GET_FILESET_BATCH_DEFINITION_DETAIL_ERROR,
  GET_FILESET_BATCHES_SUMMARY,
  GET_FILESET_BATCHES_SUMMARY_ERROR,
  GET_FILE_SET_STATUS,
  GET_FILE_SET_STATUS_ERROR,
  GET_GIM_POSTING_SUMMARIES,
  GET_GIM_POSTING_SUMMARIES_ERROR,
  GET_GIM_SUMMARY_DETAIL,
  GET_GIM_SUMMARY_DETAIL_ERROR,
  GET_GIM_POSTING_DETAIL,
  GET_GIM_POSTING_DETAIL_ERROR,
  GET_GIM_DETAIL_FILES,
  GET_GIM_DETAIL_FILES_ERROR,
  GET_GIM_FILE_CONTENT,
  GET_GIM_FILE_CONTENT_ERROR,
  GET_GIM_FILE_SUMMARY,
  GET_GIM_FILE_SUMMARY_ERROR,
  GET_T112S,
  GET_T112S_ERROR,
  GET_T464S,
  GET_T464S_ERROR,
  GET_ADVISEMENT,
  GET_ADVISEMENT_ERROR,
  GET_T140S,
  GET_T140S_ERROR,
  GET_T464_TRANSACTIONS,
  GET_T464_TRANSACTIONS_ERROR,
  GET_T112_TRANSACTIONS,
  GET_T112_TRANSACTIONS_ERROR,
  GET_T112_SUMMARY,
  GET_T112_SUMMARY_ERROR,
  GET_T112_SUMMARY_LOADING,
  GET_T464_SUMMARY,
  GET_T464_SUMMARY_ERROR,
  GET_T461S,
  GET_T461S_ERROR,
  GET_T461_TRANSACTIONS,
  GET_T461_TRANSACTIONS_ERROR,
  GET_T140S_TRANSACTIONS,
  GET_T140S_TRANSACTIONS_ERROR,
  GET_T140S_TRANSACTIONS_LOADING,
  GET_INTERAFILIATE_ISSUERS,
  GET_INTERAFILIATE_ISSUERS_ERROR,
  GET_INTERAFILIATE_ACQUIRER,
  GET_INTERAFILIATE_ACQUIRER_ERROR,
  GET_ACQUIRER_TRANSACTIONS,
  GET_ACQUIRER_TRANSACTIONS_ERROR,
  GET_ISSUER_TRANSACTIONS,
  GET_ISSUER_TRANSACTIONS_ERROR,
  GET_VISA_ISSUER,
  GET_VISA_ISSUER_ERROR,
  GET_VISA_OCT,
  GET_VISA_OCT_ERROR,
  GET_VISA_ISSUER_TRANSACTIONS,
  GET_VISA_ISSUER_TRANSACTIONS_ERROR,
  GET_VISA_ACQUIRER_ERROR,
  GET_VISA_ACQUIRER,
  GET_VISA_ACQUIRER_TRANSACTIONS,
  GET_VISA_ACQUIRER_TRANSACTIONS_ERROR,
  GET_VISA_OCT_TRANSACTIONS,
  GET_VISA_OCT_TRANSACTIONS_ERROR,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_ERROR,
  GET_INTERAFFILIATE_ISSUER_SUMMARY,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SUMMARY,
  GET_INTERAFFILIATE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS,
  GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS_ERROR,
  GET_VISA_ACQUIRER_SUMMARY,
  GET_VISA_ACQUIRER_SUMMARY_ERROR,
  GET_VISA_ISSUER_SUMMARY,
  GET_VISA_ISSUER_SUMMARY_ERROR,
  GET_VISA_ISSUER_SUMMARY_STATS,
  GET_VISA_ISSUER_SUMMARY_STATS_ERROR,
  GET_T112_SUMMARY_STATS,
  GET_T112_SUMMARY_STATS_ERROR,
  GET_T464_SUMMARY_STATS,
  GET_t464_SUMMARY_STATS_ERROR,
  GET_VISA_ACQUIRER_SUMMARY_STATS,
  GET_VISA_ACQUIRER_SUMMARY_STATS_ERROR,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS_ERROR,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS_ERROR,
  GET_VISA_SUMMARY,
  GET_VISA_SUMMARY_ERROR,
  GET_VISA_POSTING_SUMMARY,
  GET_VISA_POSTING_SUMMARY_LOADING,
  GET_VISA_POSTING_SUMMARY_ERROR,
  GET_INTERAFFILIATE_POSTING_SUMMARY,
  GET_INTERAFFILIATE_POSTING_SUMMARY_ERROR,
  GET_ADVISEMENT_DETAIL,
  GET_ADVISEMENT_DETAIL_ERROR,
  GET_ALL_VSS,
  GET_ALL_VSS_ERROR,
  GET_VSS_110,
  GET_VSS_110_ERROR,
  GET_VSS_110_SUMMARY,
  GET_VSS_110_SUMMARY_ERROR,
  GET_VSS_115,
  GET_VSS_115_ERROR,
  GET_VSS_115_SUMMARY,
  GET_VSS_115_SUMMARY_ERROR,
  GET_VSS_116,
  GET_VSS_116_SUMMARY,
  GET_VSS_116_SUMMARY_ERROR,
  GET_VSS_116_ERROR,
  GET_VSS_120,
  GET_VSS_120_ERROR,
  GET_VSS_120_SUMMARY,
  GET_VSS_120_SUMMARY_ERROR,
  GET_VSS_300,
  GET_VSS_300_ERROR,
  GET_VSS_300_SUMMARY,
  GET_VSS_300_SUMMARY_ERROR,
  GET_VSS_900,
  GET_VSS_900_ERROR,
  GET_VSS_900_SUMMARY,
  GET_VSS_900_SUMMARY_ERROR,
  GET_INTERSWITCH_COMPUTATIONS,
  GET_INTERSWITCH_COMPUTATIONS_ERROR,
  GET_DAILY_INTERSWITCH_SUMMARY,
  GET_DAILY_INTERSWITCH_SUMMARY_ERROR,
  GET_DAILY_POSTING_SUMMARY,
  GET_DAILY_POSTING_SUMMARY_ERROR,
  GET_VISA_POSTING_OVERVIEW,
  GET_VISA_POSTING_OVERVIEW_ERROR,
  GET_INTERAFFILIATE_POSTING_OVERVIEW,
  GET_INTERAFFILIATE_POSTING_OVERVIEW_ERROR,
  GET_VISA_POSTING_OVERVIEW_LOADING,
  SEND_SELECTED_AFFILIATE_CODE,
  GET_VISA_ISSUER_TRANSACTIONS_LOADING,
  DOWNLOADING_STATUS,
  VISA_ISSUER_TRANSACTIONS_UPDATE,
  VISA_ACQUIRER_TRANSACTIONS_UPDATE,
  GET_POSTILION_SUMMARY,
  GET_POSTILION_SUMMARY_ERROR,
  GET_FLEXCUBE_SUMMARY,
  GET_FLEXCUBE_SUMMARY_ERROR,
  GET_POS_ACQUIRERS_SUMMARY,
  GET_POS_ACQUIRERS_SUMMARY_ERROR,
  GET_POSTILION_SUMMARY_ITEM,
  GET_POSTILION_SUMMARY_ITEM_ERROR,
  GET_FLEXCUBE_SUMMARY_ITEM,
  GET_FLEXCUBE_SUMMARY_ITEM_ERROR,
  GET_POSACQUIRERS_SUMMARY_ITEM,
  GET_POSACQUIRERS_SUMMARY_ITEM_ERROR,
  GET_POSTILION_SUMMARY_SEARCH,
  GET_FLEXCUBE_SUMMARY_SEARCH,
  GET_POSTILION_SUMMARY_SEARCH_ERROR,
  GET_FLEXCUBE_SUMMARY_SEARCH_ERROR,
  VISA_ISSUER_POSTING_REPORT_SUMMARY,
  VISA_ISSUER_POSTING_REPORT_SUMMARY_ERROR,
  ALL_POSTING_REPORT_SUMMARY,
  ALL_POSTING_REPORT_SUMMARY_ERROR,
  VISA_ACQUIRER_POSTING_REPORT_SUMMARY,
  VISA_ACQUIRER_POSTING_REPORT_SUMMARY_ERROR,
  VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES,
  VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
  VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES,
  VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
  DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM,
  DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM,
  DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM_ERROR,
  DELETE_VISA_POSTING_SUMMARY_ITEM,
  CONFIRM_DELETE,
  CANCEL_DELETE,
  GET_T464_TRANSACTIONS_LOADING,
  GET_T112_TRANSACTIONS_LOADING,
  GET_T464_SUMMARY_LOADING,
  GET_T461_TRANSACTIONS_LOADING,
  GET_ADVISEMENT_SUMMARY_ERROR,
  GET_ADVISEMENT_SUMMARY,
  GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM,
  GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM_ERROR,
  MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL_ERROR,
  GET_INTERAFFILIATE_SOURCE_SUMMARY,
  GET_INTERAFFILIATE_SOURCE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SOURCE_ITEM,
  GET_INTERAFFILIATE_SOURCE_ITEM_ERROR,
  GET_INTERAFFILIATE_GROSS_SETTLEMENT,
  GET_INTERAFFILIATE_GROSS_SETTLEMENT_ERROR,
  GET_INTERAFFILIATE_REGISTER_SETTLEMENT,
  GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR,
  GET_INTERAFFILIATE_SUMM_SETTLEMENT,
  GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR,
  GET_SETTLEMENT_BREAKDOWN,
  GET_SETTLEMENT_BREAKDOWN_ERROR,
  GET_SETTLEMENT_REPORT_ERROR,
  GET_SETTLEMENT_REPORT,
  GET_POSTING_REPORT_ERROR,
  GET_POSTING_REPORT,
  GET_AFFILIATE_ISSUER_SOURCE_ITEMS,
  GET_AFFILIATE_ISSUER_SOURCE_ITEMS_ERROR,
  GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS,
  GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS_ERROR,
  GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY,
  GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY_ERROR,
  GET_SUM_SETTLEMENT_FEES,
  GET_SUM_SETTLEMENT_FEES_ERROR,
  GET_ISSUER_AUDIT,
  GET_ISSUER_AUDIT_ERROR,
  GET_ACQUIRER_AUDIT,
  GET_ACQUIRER_AUDIT_ERROR,
  GET_VISA_POSTING_DETAILS,
  GET_VISA_POSTING_DETAILS_ERROR,
  GET_INTERAFFILIATE_POSTING,
  GET_INTERAFFILIATE_POSTING_ERROR,
  MASTERCARD_POSTING,
  MASTERCARD_POSTING_ERROR,
  MASTERCARD_POSTING_DETAILS,
  MASTERCARD_POSTING_DETAILS_ERROR,
  GET_GENERIC_POSTING_RECORDS,
  GET_GENERIC_POSTING_RECORDS_ERROR,
  FETCH_POSTING_ENTRY_META,
  FETCH_POSTING_ENTRY_META_ERROR,
  FETCH_AUDIT_LOGS,
  FETCH_AUDIT_LOGS_ERROR,
  DELETE_FLEXCUBE_CONTENT,
  DELETE_FLEXCUBE_CONTENT_ERROR,
  INTER_AFFILIATE_QUERY_DETAILS,
  INTER_AFFILIATE_QUERY_DETAILS_ERROR,
  GET_AUTO_POSTING_STATUS,
  GET_AUTO_POSTING_STATUS_ERROR,
  DELETE_INTERAFFILIATE_CHANNEL_LOG,
  DELETE_INTERAFFILIATE_CHANNEL_LOG_ERROR,
  DELETE_POS_CONTENT_ERROR,
  DELETE_POS_CONTENT,
  DELETE_POSTILION_CONTENT_ERROR,
  DELETE_POSTILION_CONTENT,
  DIS_MISS_ERROR,
  GET_ALL_XPRESSCASH_SUMMARY,
  GET_ALL_XPRESSCASH_SUMMARY_START,
  GET_ALL_XPRESSCASH_SUMMARY_SUCCESS,
  GET_ALL_XPRESSCASH_SUMMARY_FAILURE,
  GET_ALL_XPRESSCASH_DETAILS_START,
  GET_ALL_XPRESSCASH_DETAILS_SUCCESS,
  GET_ALL_XPRESSCASH_DETAILS_FAILURE,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_START,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_SUCCESS,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_FAILURE,
  GET_ALL_XPRESSCASH_ACQUIRER_START,
  GET_ALL_XPRESSCASH_ACQUIRER_SUCCESS,
  GET_ALL_XPRESSCASH_ACQUIRER_FAILURE,
  GET_ALL_XPRESSCASH_ISSUER_START,
  GET_ALL_XPRESSCASH_ISSUER_SUCCESS,
  GET_ALL_XPRESSCASH_ISSUER_FAILURE,
  GET_XPRESSCASH_SETTLEMENT_REPORT_START,
  GET_XPRESSCASH_SETTLEMENT_REPORT_SUCCESS,
  GET_XPRESSCASH_SETTLEMENT_REPORT_FAILURE,
  GET_XPRESSCASH_POSTING_REPORT_START,
  GET_XPRESSCASH_POSTING_REPORT_SUCCESS,
  GET_XPRESSCASH_POSTING_REPORT_FAILURE,
  GET_QUERY_DETAILS,
  GET_QUERY_DETAILS_ERROR,
  GET_XPRESSCASH_FEES_REPORT_START,
  GET_XPRESSCASH_FEES_REPORT_SUCCESS,
  GET_XPRESSCASH_FEES_REPORT_FAILURE,
  GET_XPRESSCASH_FEES_SUMM_START,
  GET_XPRESSCASH_FEES_SUMM_SUCCESS,
  GET_XPRESSCASH_FEES_SUMM_FAILURE,
  GET_XPRESSCASH_AQUIRER_REPORT_START,
  GET_XPRESSCASH_AQUIRER_REPORT_SUCCESS,
  GET_XPRESSCASH_AQUIRER_REPORT_FAILURE,
  GET_XPRESSCASH_ISSUER_REPORT_START,
  GET_XPRESSCASH_ISSUER_REPORT_SUCCESS,
  GET_XPRESSCASH_ISSUER_REPORT_FAILURE,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_START,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_FAILURE,
  GET_ALL_MVISA_SUMMARY_START,
  GET_ALL_MVISA_SUMMARY_SUCCESS,
  GET_ALL_MVISA_SUMMARY_FAILURE,
  GET_ALL_MVISA_DETAILS_START,
  GET_ALL_MVISA_DETAILS_SUCCESS,
  GET_ALL_MVISA_DETAILS_FAILURE,
  GET_ALL_MVISA_QUERY_DETAILS_START,
  GET_ALL_MVISA_QUERY_DETAILS_SUCCESS,
  GET_ALL_MVISA_QUERY_DETAILS_FAILURE,
  GET_CHINAPAY_ACQUIRER_FILE_SUMMARY,
  GET_CHINAPAY_ACQUIRER_FILE_SUMMARY_ERROR,
  GET_CHINAPAY_ACQUIRER_FILE_DETAILS,
  GET_CHINAPAY_ACQUIRER_FILE_DETAILS_ERROR,
  CHINAPAY_LOADING,
  GET_CHINAPAY_SUMMARY,
  GET_CHINAPAY_SUMMARY_ERROR,
  GET_CHINAPAY_SUMMARY_DETAILS,
  GET_CHINAPAY_SUMMARY_DETAILS_ERROR,
  GET_CHINAPAY_POSTING_SUMMARY,
  GET_CHINAPAY_POSTING_SUMMARY_ERROR,
  GET_CHINAPAY_POSTING_DETAILS,
  GET_CHINAPAY_POSTING_DETAILS_ERROR,
  GET_CHINAPAY_POSTING_ENTRIES,
  GET_CHINAPAY_POSTING_ENTRIES_ERROR,
  // MASTERPASS
  MASTERPASS_LOADING,
  GET_MASTERPASS_SUMMARIES,
  GET_MASTERPASS_SUMMARIES_ERROR,
  GET_MASTERPASS_DETAILS,
  GET_MASTERPASS_DETAILS_ERROR,
  GET_MASTERPASS_ACQUIRERS,
  GET_MASTERPASS_ACQUIRERS_ERROR,
  GET_MASTERPASS_ISSUERS,
  GET_MASTERPASS_ISSUERS_ERROR,
  GET_MASTERPASS_SUMMARY,
  GET_MASTERPASS_SUMMARY_ERROR,
  GET_MASTERPASS_SETTLEMENT_REPORT,
  GET_MASTERPASS_SETTLEMENT_REPORT_ERROR,
  GET_MASTERPASS_POSTING_REPORT,
  GET_MASTERPASS_POSTING_REPORT_ERROR,
  GET_MASTERPASS_SETTLEMENT_FEES_REPORT,
  GET_MASTERPASS_SETTLEMENT_FEES_REPORT_ERROR,
  GET_MASTERPASS_SUMM_SETTLEMENT_REPORT,
  GET_MASTERPASS_SUMM_SETTLEMENT_REPORT_ERROR,
  GET_MASTERPASS_ACQUIRER_REPORT,
  GET_MASTERPASS_ACQUIRER_REPORT_ERROR,
  GET_MASTERPASS_ISSUER_REPORT,
  GET_MASTERPASS_ISSUER_REPORT_ERROR,
  GET_MASTERPASS_QUERY_DETAILS,
  GET_MASTERPASS_QUERY_DETAILS_ERROR,
  GET_ALL_MVISA_ACQUIRER_START,
  GET_ALL_MVISA_ACQUIRER_SUCCESS,
  GET_ALL_MVISA_ACQUIRER_FAILURE,
  GET_ALL_MVISA_ISSUER_START,
  GET_ALL_MVISA_ISSUER_SUCCESS,
  GET_ALL_MVISA_ISSUER_FAILURE,
  GET_ALL_MVISA_SINGLE_SUMMARY_START,
  GET_ALL_MVISA_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_MVISA_SINGLE_SUMMARY_FAILURE,
  GET_MVISA_SUM_REPORT_START,
  GET_MVISA_SUM_REPORT_SUCCESS,
  GET_MVISA_SUM_REPORT_FAILURE,
  GET_XPRESSCASH_MVISA_REPORT_START,
  GET_XPRESSCASH_MVISA_REPORT_SUCCESS,
  GET_XPRESSCASH_MVISA_REPORT_FAILURE,
  GET_MVISA_POSTING_REPORT_START,
  GET_MVISA_POSTING_REPORT_SUCCESS,
  GET_MVISA_POSTING_REPORT_FAILURE,
  GET_MVISA_FEES_REPORT_START,
  GET_MVISA_FEES_REPORT_SUCCESS,
  GET_MVISA_FEES_REPORT_FAILURE,
  GET_MVISA_ACQUIRER_REPORT_START,
  GET_MVISA_ACQUIRER_REPORT_SUCCESS,
  GET_MVISA_ACQUIRER_REPORT_FAILURE,
  GET_MVISA_ISSUER_REPORT_START,
  GET_MVISA_ISSUER_REPORT_SUCCESS,
  GET_MVISA_ISSUER_REPORT_FAILURE,
  DELETE_POSTING_LOG_ID_AND_RECORDS,
  DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR,
  DELETE_CHANNEL_LOG,
  DELETE_CHANNEL_LOG_ERROR,
  FETCH_ALL_ECOMMERCE_SCHEME,
  FETCH_ALL_ECOMMERCE_SCHEME_ERROR,
  ECOMMERCE_DETAILS,
  ECOMMERCE_DETAILS_ERROR,
  ECOMMERCE_ISSUER_DETAILS,
  ECOMMERCE_ISSUER_DETAILS_ERROR,
  ECOMMERCE_ACQUIRER_DETAILS,
  ECOMMERCE_ACQUIRER_DETAILS_ERROR,
  GET_ECOMMERCE_SETTLEMENT_REPORT_START,
  GET_ECOMMERCE_SETTLEMENT_REPORT_SUCCESS,
  GET_ECOMMERCE_SETTLEMENT_REPORT_FAILURE,
  GET_ECOMMERCE_POSTING_REPORT_START,
  GET_ECOMMERCE_POSTING_REPORT_SUCCESS,
  GET_ECOMMERCE_POSTING_REPORT_FAILURE,
  GET_ECOMMERCE_FEES_REPORT_START,
  GET_ECOMMERCE_FEES_REPORT_SUCCESS,
  GET_ECOMMERCE_FEES_REPORT_FAILURE,
  GET_ECOMMERCE_SUMM_REPORT_START,
  GET_ECOMMERCE_SUMM_REPORT_SUCCESS,
  GET_ECOMMERCE_SUMM_REPORT_FAILURE,
  GET_ECOMMERCE_AQUIRER_REPORT_START,
  GET_ECOMMERCE_AQUIRER_REPORT_SUCCESS,
  GET_ECOMMERCE_AQUIRER_REPORT_FAILURE,
  GET_ECOMMERCE_ISSUER_REPORT_START,
  GET_ECOMMERCE_ISSUER_REPORT_SUCCESS,
  GET_ECOMMERCE_ISSUER_REPORT_FAILURE,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_START,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_FAILURE,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_START,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_SUCCESS,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_FAILURE,
  DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM_ERROR,
  CLEAR_VISA_ALERT_MESSAGES,
  GET_NOSTRO_REPORTS,
  GET_NOSTRO_REPORTS_LOADING,
  GET_NOSTRO_REPORTS_ERROR,
  TRIGGER_POSTING,
  TRIGGER_POSTING_ERROR,
  RESET_TRIGGER_RESPONSE,
  GET_MASTERCARD_RESOLVED_FILES,
  GET_MASTERCARD_RESOLVED_FILES_FAILURE,
  CLEAR_RESOLVED_FILES_MESSAGE,
} from "../actions/types";

import { deleteObjectItemByValue } from "../Utils/utilityFunctions";

export default function (state = {}, action) {
  switch (action.type) {
    case CANCEL_DELETE:
      return { ...state, deleteStatus: false };
    case CONFIRM_DELETE:
      return {
        ...state,
        deleteItem: action.payload.data,
        deleteStatus: true,
        deleteItemName: action.payload.itemName,
      };
    case GET_AUTO_POSTING_STATUS:
      return {
        ...state,
        auto_posting: action.payload,
        auto_posting_error: false,
      };
    case GET_AUTO_POSTING_STATUS_ERROR:
      return {
        ...state,
        auto_posting: false,
        auto_posting_error: action.payload,
      };
    case INTER_AFFILIATE_QUERY_DETAILS:
      return {
        ...state,
        query_details: action.payload.data,
        query_details_error: false,
      };
    case INTER_AFFILIATE_QUERY_DETAILS_ERROR:
      return {
        ...state,
        query_details_error: action.payload,
        query_details: false,
      };
    case DELETE_INTERAFFILIATE_CHANNEL_LOG: // actually changed now the File
      return {
        ...state,
        interAffiliatePostOffice: {
          ...state.interAffiliatePostOffice,
          data: {
            ...state.interAffiliatePostOffice.data,
            content: state.interAffiliatePostOffice.data.content.filter(
              (log) => log.id !== action.payload.data
            ),
          },
        },
      };
    case FETCH_AUDIT_LOGS:
      return {
        ...state,
        audit: action.payload.data,
        auditError: false,
      };
    case FETCH_AUDIT_LOGS_ERROR:
      return { ...state, auditError: action.payload, audit: false };
    case DELETE_VISA_POSTING_SUMMARY_ITEM:
      return {
        ...state,
        visaSummary: {
          ...state.visaSummary,
          data: {
            ...state.visaSummary.data,
            content: state.visaSummary.data.content.filter(
              (item) => item.id !== action.payload.data
            ),
          },
        },
        deleteStatus: false,
      };
    case FETCH_POSTING_ENTRY_META:
      return {
        ...state,
        postingEntryMeta: action.payload,
        postingEntryMetaError: false,
      };
    case FETCH_POSTING_ENTRY_META_ERROR:
      return {
        ...state,
        postingEntryMetaError: action.payload,
        postingEntryMeta: false,
      };
    case DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM:
      return {
        ...state,
        visaAcquirerDeleted: action.payload,
        deleteStatus: false,
      };
    case DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        visaAcquirerDeletedError: action.payload,
      };
    case DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM:
      return {
        ...state,
        visaIssuerDeleted: action.payload,
        deleteStatus: false,
      };
    case DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        visaIssuerDeletedError: action.payload,
      };
    case CLEAR_VISA_ALERT_MESSAGES:
      return {
        ...state,
        visaAcquirerDeleted: null,
        visaAcquirerDeletedError: null,
        visaIssuerDeleted: null,
        visaIssuerDeletedError: null,
      };
    case VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES:
      return {
        ...state,
        validatedEntries: action.payload.data,
        validatedEntriesError: false,
      };
    case VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR:
      return {
        ...state,
        validatedEntriesError: action.payload,
        validatedEntries: false,
      };
    case VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES:
      return {
        ...state,
        validatedEntries: action.payload.data,
        validatedEntriesError: false,
      };
    case VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR:
      return {
        ...state,
        validatedEntriesError: action.payload,
        validatedEntries: false,
      };
    case ALL_POSTING_REPORT_SUMMARY:
      return {
        ...state,
        allpostingSummaryReport: action.payload.data,
        allpostingSummaryReportError: false,
      };
    case ALL_POSTING_REPORT_SUMMARY_ERROR:
      return {
        ...state,
        allpostingSummaryReportError: action.payload,
        allpostingSummaryReport: false,
      };
    case VISA_ISSUER_POSTING_REPORT_SUMMARY:
      return {
        ...state,
        postingReportSummary: action.payload.data,
        postingReportSummaryError: false,
      };
    case VISA_ISSUER_POSTING_REPORT_SUMMARY_ERROR:
      return {
        ...state,
        postingReportSummaryError: action.payload,
        postingReportSummary: false,
      };
    case VISA_ACQUIRER_POSTING_REPORT_SUMMARY:
      return { ...state, postingReportSummary: action.payload.data };
    case VISA_ACQUIRER_POSTING_REPORT_SUMMARY_ERROR:
      return {
        ...state,
        postingReportSummaryError: action.payload,
        postingReportSummary: false,
      };
    case DOWNLOADING_STATUS:
      return {
        ...state,
        download_status: action.payload.data,
        postingReportSummary: false,
      };
    case SEND_SELECTED_AFFILIATE_CODE:
      return { ...state, selectedAffiliateCode: action.payload.data };
    case GET_FILE_SETS:
      return { ...state, fileSets: action.payload };
    case GET_FILE_SETS_ERROR:
      return { ...state, fileSetsError: action.payload };
    case GET_POSTILION_SUMMARY:
      return {
        ...state,
        postilionLogSummary: action.payload,
        postilionLogSummaryError: false,
      };
    case GET_POSTILION_SUMMARY_ERROR:
      return {
        ...state,
        postilionLogSummaryError: action.payload,
        postilionLogSummary: false,
      };
    case GET_POSTILION_SUMMARY_ITEM:
      return {
        ...state,
        postilionLogSummaryItem: action.payload,
        postilionLogSummaryItemError: false,
      };
    case GET_POSTILION_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        postilionLogSummaryItemError: action.payload,
        postilionLogSummaryItem: false,
      };
    case GET_FLEXCUBE_SUMMARY:
      return {
        ...state,
        flexcubeLogSummary: action.payload,
        flexcubeLogSummaryItem: false,
      };
    case GET_FLEXCUBE_SUMMARY_ERROR:
      return {
        ...state,
        flexcubeLogSummaryError: action.payload,
        flexcubeLogSummary: false,
      };
    case DIS_MISS_ERROR:
      return { ...state, deleteStatus: action.payload };
    case DELETE_POSTILION_CONTENT:
      return {
        ...state,
        deleteStatus: `${action.payload} is deleted!`,
      };
    case DELETE_POSTILION_CONTENT_ERROR:
      return { ...state, deleteError: action.payload };
    case DELETE_FLEXCUBE_CONTENT:
      return {
        ...state,
        deleteStatus: `${action.payload} is deleted!`,
      };
    case DELETE_FLEXCUBE_CONTENT_ERROR:
      return { ...state, deleteError: action.payload };
    case DELETE_POS_CONTENT:
      return {
        ...state,
        deleteStatus: `${action.payload} is deleted!`,
      };
    case DELETE_POS_CONTENT_ERROR:
      return { ...state, deleteError: action.payload };
    case GET_FLEXCUBE_SUMMARY_ITEM:
      return {
        ...state,
        flexcubeLogSummaryItem: action.payload,
        flexcubeLogSummaryItemError: false,
      };
    case GET_FLEXCUBE_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        flexcubeLogSummaryItemError: action.payload,
        flexcubeLogSummaryItem: false,
      };
    case GET_POS_ACQUIRERS_SUMMARY:
      return {
        ...state,
        posAcquirerSummary: action.payload,
        posAcquirerSummaryError: false,
      };
    case GET_POS_ACQUIRERS_SUMMARY_ERROR:
      return {
        ...state,
        posAcquirerSummaryError: action.payload,
        posAcquirerSummary: false,
      };
    case GET_POSACQUIRERS_SUMMARY_ITEM:
      return {
        ...state,
        posAcquirerSummaryItem: action.payload,
        posAcquirerSummaryItemError: false,
      };
    case GET_POSACQUIRERS_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        posAcquirerSummaryItemError: action.payload,
        posAcquirerSummaryItem: false,
      };
    case GET_POSTILION_SUMMARY_SEARCH:
      return {
        ...state,
        postilionLogSummaryItem: action.payload,
        postilionLogSummaryItemError: false,
      };
    case GET_POSTILION_SUMMARY_SEARCH_ERROR:
      return {
        ...state,
        postilionLogSummaryItemError: action.payload,
        postilionLogSummaryItem: false,
      };
    case GET_FLEXCUBE_SUMMARY_SEARCH:
      return {
        ...state,
        flexcubeLogSummaryItem: action.payload,
        flexcubeLogSummaryItemError: false,
      };
    case GET_FLEXCUBE_SUMMARY_SEARCH_ERROR:
      return {
        ...state,
        flexcubeLogSummaryItemError: action.payload,
        flexcubeLogSummaryItem: false,
      };
    case GET_FILE_SET_DEFINITIONS:
      return {
        ...state,
        fileSetDefinitions: action.payload,
        flexcubeLogSummaryItemError: false,
      };
    case GET_FILE_SET_DEFINITIONS_ERROR:
      return {
        ...state,
        fileSetDefintionsError: action.payload,
        flexcubeLogSummaryItem: false,
      };
    case CREATE_FILE_SET:
      state.fileSetsPaginated.push(action.payload);
      return { ...state, fileSetCreated: action.payload };
    case GET_FILE_SET_STATUS:
      return { ...state, fileSetStanding: action.payload };
    case GET_FILE_SET_STATUS_ERROR:
      return { ...state, fileSetStandingError: action.payload };
    case ADD_FILE_DEFINITIONS_TO_FILESET:
      return { ...state, fileDefinitionAddedToFileset: action.payload };
    case ADD_FILE_DEFINITIONS_TO_FILESET_ERROR:
      return { ...state, fileDefinitionAddedToFilesetError: action.payload };
    case CREATE_FILE_SET_ERROR:
      return { ...state, fileSetCreatedError: action.payload };
    case UPDATE_FILE_SET:
      let filtered_data = state.fileSetsPaginated.filter(
        (item) => item.id !== action.payload.id
      );
      filtered_data.push(action.payload);
      state.fileSetsPaginated = filtered_data;
      return { ...state, fileSetUpdated: action.payload };
    case UPDATE_FILE_SET_ERROR:
      return { ...state, fileSetUpdatedError: action.payload };
    case DELETE_FILE_SET:
      const fileSet = state.fileSets.data.filter(
        (item) => item.id !== action.payload.id
      );
      state.fileSets.data = fileSet;
      return { ...state, fileSetDeleted: action.payload };
    case DELETE_FILE_SET_ERROR:
      return { ...state, fileSetDeletedError: action.payload.response.data };
    case GET_FILESET_BATCHES_SUMMARY:
      return { ...state, fileSetBatchSummary: action.payload };
    case GET_FILESET_BATCHES_SUMMARY_ERROR:
      return { ...state, fileSetBatchSummaryError: action.payload };
    case GET_FILE_SETS_PAGINATED:
      return { ...state, fileSetsPaginated: action.payload };
    case GET_FILE_SETS_PAGINATED_ERROR:
      return { ...state, fileSetsPaginateError: action.payload };
    case GET_FILE_SET_BATCHES_PAGINATED:
      return { ...state, fileSetBatchesPaginated: action.payload };
    case GET_FILE_SET_BATCHES_PAGINATED_ERROR:
      return { ...state, fileSetBatchesPaginatedError: action.payload };
    case GET_FILE_SET_DEFINITION_BATCHES_PAGINATED:
      return { ...state, fileSetDefinitionBatchesPaginated: action.payload };
    case GET_FILE_SET_DEFINITION_BATCHES_PAGINATED_ERROR:
      return {
        ...state,
        fileSetDefinitionBatchesPaginatedError: action.payload,
      };
    case GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED:
      return {
        ...state,
        fileSetDefinitionBatchesTranactionsPaginated: action.payload,
      };
    case GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR:
      return {
        ...state,
        fileSetDefinitionBatchesTranactionsPaginatedError: action.payload,
      };
    case GET_FILESET_BATCH_DETAIL:
      return { ...state, fileSetBatchDetail: action.payload };
    case GET_FILESET_BATCH_DETAIL_ERROR:
      return { ...state, fileSetBatchDetailError: action.payload };
    case GET_GIM_POSTING_SUMMARIES:
      return { ...state, gimPostingSummaries: action.payload };
    case GET_GIM_POSTING_SUMMARIES_ERROR:
      return { ...state, gimPostingSummariesError: action.payload };
    case GET_GIM_SUMMARY_DETAIL:
      return { ...state, gimSummaryDetail: action.payload };
    case GET_GIM_SUMMARY_DETAIL_ERROR:
      return { ...state, gimSummaryDetailError: action.payload };
    case GET_GIM_POSTING_DETAIL:
      return { ...state, gimPostingDetail: action.payload };
    case GET_GIM_POSTING_DETAIL_ERROR:
      return { ...state, gimPostingDetailError: action.payload };
    case GET_GIM_DETAIL_FILES:
      return { ...state, gimDetailFiles: action.payload };
    case GET_GIM_DETAIL_FILES_ERROR:
      return { ...state, gimDetailFilesError: action.payload };
    case GET_GIM_FILE_CONTENT:
      return { ...state, gimFileContent: action.payload };
    case GET_GIM_FILE_CONTENT_ERROR:
      return { ...state, gimFileContentError: action.payload };
    case GET_GIM_FILE_SUMMARY:
      return { ...state, gimFileSummary: action.payload };
    case GET_GIM_FILE_SUMMARY_ERROR:
      return { ...state, gimFileSummaryError: action.payload };
    case GET_T112S:
      return { ...state, t112s: action.payload };
    case GET_T112S_ERROR:
      return { ...state, t112sError: action.payload };
    case GET_T112_SUMMARY:
      return {
        ...state,
        t112Summary: action.payload,
        t112SummaryLoading: false,
      };
    case GET_T112_SUMMARY_ERROR:
      return {
        ...state,
        t112SummaryError: action.payload,
        t112SummaryLoading: false,
      };
    case GET_T112_SUMMARY_LOADING:
      return { ...state, t112SummaryLoading: true };
    case GET_T112_SUMMARY_STATS:
      return { ...state, t112SummaryStats: action.payload };
    case GET_T112_SUMMARY_STATS_ERROR:
      return { ...state, t112SummaryStatsError: action.payload };
    case GET_T464_SUMMARY:
      return {
        ...state,
        t464Summary: action.payload,
        t464SummaryError: false,
      };
    case GET_T464_SUMMARY_ERROR:
      return {
        ...state,
        t464SumaryError: action.payload,
        t464Summary: false,
      };
    case GET_T464_SUMMARY_STATS:
      return { ...state, t464SummaryStats: action.payload };
    case GET_t464_SUMMARY_STATS_ERROR:
      return { ...state, t464SummaryStatsError: action.payload };
    case GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM:
      return { ...state, MastercardposAcquirerSummaryItem: action.payload };
    case GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM_ERROR:
      return {
        ...state,
        MastercardposAcquirerSummaryItemError: action.payload,
      };
    case MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS:
      return { ...state, download_status: action.payload.data };
    case GET_INTERAFFILIATE_ISSUER_SUMMARY:
      return { ...state, interAffiliateIssuerSummary: action.payload };
    case GET_INTERAFFILIATE_ISSUER_SUMMARY_ERROR:
      return { ...state, interAffiliateIssuerSummaryError: action };
    case GET_INTERAFFILIATE_ACQUIRER_SUMMARY:
      return { ...state, interAffiliateAcquirerSummary: action.payload };
    case GET_INTERAFFILIATE_ACQUIRER_SUMMARY_ERROR:
      return { ...state, interAffiliateAcquirerSummaryError: action.payload };
    case GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS:
      return { ...state, interAffiliateAcquirerSummaryStats: action.payload };
    case GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS_ERROR:
      return {
        ...state,
        interAffiliateAcquirerSummaryStatsError: action.payload,
      };
    case GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS:
      return { ...state, interAffiliateIssuerSummaryStats: action.payload };
    case GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS_ERROR:
      return {
        ...state,
        interAffiliateIssuerSummaryStatsError: action.payload,
      };
    case GET_INTERAFFILIATE_POSTING_SUMMARY:
      return { ...state, interAffiliatePostingSummary: action.payload };
    case GET_INTERAFFILIATE_POSTING_SUMMARY_ERROR:
      return { ...state, interAffiliatePostingSummaryError: action.payload };
    case GET_T464S:
      return { ...state, t464s: action.payload };
    case GET_T461S:
      return { ...state, t461s: action.payload };
    case GET_T461S_ERROR:
      return { ...state, t461sError: action.payload };
    case GET_T464_TRANSACTIONS:
      return {
        ...state,
        t464Transactions: action.payload,
        t464TransactionsLoading: false,
      };
    case GET_T464_TRANSACTIONS_ERROR:
      return {
        ...state,
        t464TransactionsError: action.payload,
        t464TransactionsLoading: false,
      };
    case GET_T464_SUMMARY_LOADING:
      return { ...state, t464SummaryLoading: true };
    case GET_T140S_TRANSACTIONS:
      return {
        ...state,
        t140Transactions: action.payload,
        t140TransactionsLoading: false,
      };
    case GET_T140S_TRANSACTIONS_ERROR:
      return {
        ...state,
        t140TransactionsError: action.payload,
        t140TransactionsLoading: false,
      };
    case GET_T464_TRANSACTIONS_LOADING:
      return { ...state, t464TransactionsLoading: true };
    case GET_T140S_TRANSACTIONS_LOADING:
      return { ...state, t140TransactionsLoading: true };
    case GET_T112_TRANSACTIONS:
      return {
        ...state,
        t112Transactions: action.payload,
        t112TransactionsLoading: false,
      };
    case GET_T112_TRANSACTIONS_ERROR:
      return {
        ...state,
        t112TransactionsError: action.payload,
        t112TransactionsLoading: false,
      };
    case GET_T112_TRANSACTIONS_LOADING:
      return { ...state, t112TransactionsLoading: true };
    case GET_T461_TRANSACTIONS:
      return {
        ...state,
        t461Transactions: action.payload.content,
        t461TransactionsMeta: action.payload.meta,
        t461TransactionsLoading: false,
      };
    case GET_T461_TRANSACTIONS_ERROR:
      return {
        ...state,
        t461TransactionsError: action.payload,
        t461TransactionsLoading: false,
        t461Transactions: false,
        t461TransactionsMeta: false,
      };
    case GET_T461_TRANSACTIONS_LOADING:
      return { ...state, t461TransactionsLoading: true };
    case GET_T140S:
      return { ...state, t140s: action.payload };
    case GET_T140S_ERROR:
      return { ...state, t140sError: action.payload };
    case GET_T464S_ERROR:
      return { ...state, t464sError: action.payload };
    case GET_FILE_SET:
      return { ...state, fileSet: action.payload.data };
    case GET_ADVISEMENT:
      return { ...state, advisements: action.payload, advismentsError: false };
    case GET_ADVISEMENT_ERROR:
      return { ...state, advisementsError: action.payload, advisments: false };
    case GET_ADVISEMENT_SUMMARY:
      return {
        ...state,
        advisementSummary: action.payload,
        advisementSummaryError: false,
      };
    case GET_ADVISEMENT_SUMMARY_ERROR:
      return {
        ...state,
        advisementSummaryError: action.payload,
        advisementSummary: false,
      };
    case GET_ADVISEMENT_DETAIL:
      return { ...state, advisementDetail: action.payload };
    case GET_ADVISEMENT_DETAIL_ERROR:
      return { ...state, advisementDetailError: action.payload };
    case GET_INTERAFFILIATE_SOURCE_SUMMARY:
      return {
        ...state,
        interAffiliate_source_summary: action.payload,
        interAffiliate_source_summary_error: false,
      };
    case GET_INTERAFFILIATE_SOURCE_SUMMARY_ERROR:
      return {
        ...state,
        interAffiliate_source_summary_error: action.payload,
        interAffiliate_source_summary: false,
      };
    case GET_INTERAFFILIATE_SOURCE_ITEM:
      return {
        interAffiliateSourceItem: action.payload,
        interAffiliateSourceItemError: false,
      };
    case GET_INTERAFFILIATE_SOURCE_ITEM_ERROR:
      return {
        interAffiliateSourceItemError: action.payload,
        interAffiliateSourceItem: false,
      };
    case GET_INTERAFFILIATE_GROSS_SETTLEMENT:
      return {
        interAffiliateGrossSettlement: action.payload,
        interAffiliateGrossSettlementError: false,
      };
    case GET_INTERAFFILIATE_GROSS_SETTLEMENT_ERROR:
      return {
        interAffiliateGrossSettlement: false,
        interAffiliateGrossSettlementError: action.payload,
      };
    case GET_INTERAFFILIATE_REGISTER_SETTLEMENT:
      return {
        interAffiliateRegisterSettlement: action.payload,
        interAffiliateRegisterSettlementError: false,
      };
    case GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR:
      return {
        interAffiliateRegisterSettlement: false,
        interAffiliateRegisterSettlementError: action.payload,
      };
    case GET_INTERAFFILIATE_SUMM_SETTLEMENT:
      return {
        interAffiliateSumSettlement: action.payload.data,
        interAffiliateSumSettlementError: false,
      };
    case GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR:
      return {
        interAffiliateSumSettlementError: action.payload,
        interAffiliateSumSettlement: false,
      };
    case GET_SETTLEMENT_BREAKDOWN:
      return {
        ...state,
        settlement_breakdown: action.payload,
        settlement_breakdown_error: false,
      };
    case GET_SETTLEMENT_BREAKDOWN_ERROR:
      return {
        ...state,
        settlement_breakdown_error: action.payload,
        settlement_breakdown: false,
      };
    case GET_SETTLEMENT_REPORT:
      return {
        ...state,
        settlement_report: action.payload,
        settlement_report_error: false,
      };
    case GET_SETTLEMENT_REPORT_ERROR:
      return {
        ...state,
        settlement_report_error: action.payload,
        settlement_report: false,
      };
    case GET_AFFILIATE_ISSUER_SOURCE_ITEMS:
      return {
        ...state,
        affiliateIssuerSourceItems: action.payload,
        affiliateIssuerSourceItemsError: false,
      };
    case GET_AFFILIATE_ISSUER_SOURCE_ITEMS_ERROR:
      return {
        ...state,
        affiliateIssuerSourceItemsError: action.payload,
        affiliateIssuerSourceItems: false,
      };
    case GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS:
      return {
        ...state,
        affiliateAcquirerSourceItems: action.payload,
        affiliateAcquirerSourceItemsError: false,
      };
    case GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS_ERROR:
      return {
        ...state,
        affiliateAcquirerSourceItemsError: action.payload,
        affiliateAcquirerSourceItems: false,
      };
    case GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY:
      return {
        ...state,
        affPostingSummary: action.payload,
        affPostingSummaryError: false,
      };
    case GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY_ERROR:
      return {
        ...state,
        affPostingSummaryError: action.payload,
        affPostingSummary: false,
      };
    case GET_SUM_SETTLEMENT_FEES:
      return {
        ...state,
        sumSettlementFees: action.payload,
        sumSettlementFeesError: false,
      };
    case GET_SUM_SETTLEMENT_FEES_ERROR:
      return {
        ...state,
        sumSettlementFeesError: action.payload,
        sumSettlementFees: false,
      };
    case GET_ISSUER_AUDIT:
      return { ...state, issuerAudit: action.payload, issuerAuditError: false };
    case GET_ISSUER_AUDIT_ERROR:
      return { ...state, issuerAuditError: action.payload, issuerAudit: false };
    case GET_ACQUIRER_AUDIT:
      return {
        ...state,
        acquirerAudit: action.payload,
        acquirerAuditError: false,
      };
    case GET_ACQUIRER_AUDIT_ERROR:
      return {
        ...state,
        acquirerAuditError: action.payload,
        acquirerAudit: false,
      };
    case GET_POSTING_REPORT:
      return {
        ...state,
        posting_report: action.payload,
        posting_report_error: false,
      };
    case GET_POSTING_REPORT_ERROR:
      return {
        ...state,
        posting_report_error: action.payload,
        posting_report: false,
      };
    case GET_INTERAFFILIATE_POSTING:
      return {
        ...state,
        interAffPostingDetails: action.payload,
        interAffPostingDetailsError: false,
      };
    case GET_INTERAFFILIATE_POSTING_ERROR:
      return {
        ...state,
        interAffPostingDetailsError: action.payload,
        interAffPostingDetails: false,
      };
    case MASTERCARD_POSTING:
      return {
        ...state,
        posting_report: action.payload,
        posting_report_error: false,
      };
    case MASTERCARD_POSTING_ERROR:
      return {
        ...state,
        posting_report_error: action.payload,
        posting_report: false,
      };
    case MASTERCARD_POSTING_DETAILS:
      return {
        ...state,
        posting_report_details: action.payload,
        posting_report_details_error: false,
      };
    case MASTERCARD_POSTING_DETAILS_ERROR:
      return {
        ...state,
        posting_report_details_error: action.payload,
        posting_report_details: false,
      };
    case GET_GENERIC_POSTING_RECORDS:
      return {
        ...state,
        genericPostingRecords: action.payload,
        genericPostingRecordsError: false,
      };
    case GET_GENERIC_POSTING_RECORDS_ERROR:
      return {
        ...state,
        genericPostingRecordsError: action.payload,
        genericPostingRecords: false,
      };
    case GET_VISA_ISSUER:
      return { ...state, visaIssuer: action.payload };
    case GET_VISA_ISSUER_ERROR:
      return {
        ...state,
        visaIssuerError: action.payload,
      };
    case GET_VISA_OCT:
      return { ...state, visaOct: action.payload };
    case GET_VISA_OCT_ERROR:
      return {
        ...state,
        visaOctError: action.payload,
      };
    case GET_VISA_ACQUIRER_TRANSACTIONS:
      return {
        ...state,
        visaAcquirerTransactions: action.payload,
        visaAcquirerTransactionsError: false,
      };
    case GET_VISA_ACQUIRER_TRANSACTIONS_ERROR:
      return {
        ...state,
        visaAcquirerTransactionsError: action.payload,
        visaAcquirerTransactions: false,
      };
    case GET_VISA_OCT_TRANSACTIONS:
      return {
        ...state,
        visaOctTransactions: action.payload,
        visaOctTransactionsError: false,
      };
    case GET_VISA_OCT_TRANSACTIONS_ERROR:
      return {
        ...state,
        visaOctTransactionsError: action.payload,
        visaOctTransactions: false,
      };
    case VISA_ACQUIRER_TRANSACTIONS_UPDATE:
      return {
        ...state,
        visaAcquirerTransactions: {
          ...state.visaIssuerTransactions,
          data: {
            ...state.visaIssuerTransactions.data,
            content: state.visaIssuerTransactions.data.content.map((item) => {
              if (item.id === action.payload.data.id) {
                return action.payload.data;
              } else {
                return item;
              }
            }),
          },
        },
      };
    case GET_VISA_ACQUIRER:
      return {
        ...state,
        visaAcquirer: action.payload,
        visaAcquirerError: false,
      };
    case GET_VISA_ACQUIRER_ERROR:
      return {
        ...state,
        visaAcquirerError: action.payload,
        visaAcquirer: false,
      };
    case GET_VISA_ISSUER_TRANSACTIONS:
      return {
        ...state,
        visaIssuerTransactions: action.payload,
        visaIssuerTransactionsError: false,
      };
    case GET_VISA_ISSUER_TRANSACTIONS_ERROR:
      return {
        ...state,
        visaIssuerTransactionsError: action.payload,
        visaIssuerTransactions: false,
      };
    case VISA_ISSUER_TRANSACTIONS_UPDATE:
      return {
        ...state,
        visaIssuerTransactions: {
          ...state.visaIssuerTransactions,
          data: {
            ...state.visaIssuerTransactions.data,
            content: state.visaIssuerTransactions.data.content.map((item) => {
              if (item.id === action.payload.data.id) {
                return action.payload.data;
              } else {
                return item;
              }
            }),
          },
        },
      };
    case GET_VISA_POSTING_DETAILS:
      return {
        ...state,
        visaPostingDetails: action.payload,
        visaPostingDetailsError: false,
      };
    case GET_VISA_POSTING_DETAILS_ERROR:
      return {
        ...state,
        visaPostingDetailsError: action.payload,
        visaPostingDetails: false,
      };
    case GET_INTERAFILIATE_ISSUERS:
      return { ...state, interAffiliateiIssuers: action.payload };
    case GET_INTERAFILIATE_ISSUERS_ERROR:
      return { ...state, interAffiliateIssuersError: action.payload };
    case GET_INTERAFILIATE_ACQUIRER:
      return { ...state, interAffiliateAcquirer: action.payload };
    case GET_INTERAFILIATE_ACQUIRER_ERROR:
      return { ...state, interAffiliateAcquirerError: action.payload };
    case GET_ISSUER_TRANSACTIONS:
      return { ...state, interAffiliateIssuerTransactions: action.payload };
    case GET_ISSUER_TRANSACTIONS_ERROR:
      return {
        ...state,
        interAffiliateIssuerTransactionsError: action.payload,
      };
    case GET_ACQUIRER_TRANSACTIONS:
      return { ...state, interAffiliateAcquirerTransactions: action.payload };
    case GET_ACQUIRER_TRANSACTIONS_ERROR:
      return {
        ...state,
        interAffiliateAcquirerTransactionsError: action.payload,
      };
    case GET_INTERAFFILIATE_SUMMARY:
      return { ...state, interAffiliateSummary: action.payload };
    case GET_INTERAFFILIATE_SUMMARY_ERROR:
      return { ...state, interAffiliateSummaryError: action.payload };
    case GET_VISA_SUMMARY:
      return { ...state, visaSummary: action.payload };
    case GET_VISA_SUMMARY_ERROR:
      return { ...state, visaSummaryError: action.payload };
    case GET_INTERSWITCH_COMPUTATIONS:
      return { ...state, interSwitchComputations: action.payload };
    case GET_INTERSWITCH_COMPUTATIONS_ERROR:
      return { ...state, interSwitchComputationsError: action.payload };
    case GET_DAILY_INTERSWITCH_SUMMARY:
      return { ...state, interSwitchDailyComputationSummary: action.payload };
    case GET_DAILY_INTERSWITCH_SUMMARY_ERROR:
      return {
        ...state,
        interSwitchDailyComputationSummaryError: action.payload,
      };
    case GET_VISA_POSTING_SUMMARY:
      return {
        ...state,
        visaPostingSummary: action.payload,
        visaPostingSummaryLoading: false,
      };
    case GET_VISA_POSTING_SUMMARY_ERROR:
      return {
        ...state,
        visaPostingSummaryError: action.payload,
        visaPostingSummaryLoading: false,
      };
    case GET_VISA_POSTING_SUMMARY_LOADING:
      return { ...state, visaPostingSummaryLoading: true };
    case GET_VISA_ISSUER_TRANSACTIONS_LOADING:
      return { ...state, visaIssuerTransactionsLoading: action.payload.data };
    case GET_VISA_ACQUIRER_SUMMARY:
      return {
        ...state,
        visaAcquirerSummary: action.payload,
        visaAcquirerSummaryError: false,
      };
    case GET_VISA_ACQUIRER_SUMMARY_ERROR:
      return {
        ...state,
        visaAcquirerSummaryError: action.payload,
        visaAcquirerSummary: false,
      };
    case GET_VISA_ISSUER_SUMMARY:
      return {
        ...state,
        visaIssuerSummary: action.payload,
        visaIssuerSummaryError: false,
      };
    case GET_VISA_ISSUER_SUMMARY_ERROR:
      return {
        ...state,
        visaIssuerSummaryError: action.payload,
        visaIssuerSummary: false,
      };
    case GET_VISA_ISSUER_SUMMARY_STATS:
      return {
        ...state,
        visaIssuerSummaryStats: action.payload,
        visaIssuerSummaryStatsError: false,
      };
    case GET_VISA_ISSUER_SUMMARY_STATS_ERROR:
      return {
        ...state,
        visaIssuerSummaryStatsError: action.payload,
        visaIssuerSummary: false,
      };
    case GET_VISA_ACQUIRER_SUMMARY_STATS:
      return { ...state, visaAcquirerSummaryStat: action.payload };
    case GET_VISA_ACQUIRER_SUMMARY_STATS_ERROR:
      return { ...state, visaAcquirerSummaryStatError: action.payload };
    case GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS:
      return { ...state, interAffiliateSummaryTransactions: action.payload };
    case GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS_ERROR:
      return {
        ...state,
        interAffiliateSummaryTransactionsError: action.payload,
      };
    case GET_INTERAFFILIATE_POSTOFFICE_SUMMARY:
      return {
        ...state,
        interAffiliatePostOffice: action.payload,
        interAffiliatePostOfficeError: false,
      };
    case GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_ERROR:
      return {
        ...state,
        interAffiliatePostOfficeError: action.payload,
        interAffiliatePostOffice: false,
      };
    case GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL:
      return {
        ...state,
        interaffiliatePostOfficeSummary: action.payload,
        interaffliatePostOfficeSummaryError: false,
      };
    case GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL_ERROR:
      return {
        ...state,
        interaffliatePostOfficeSummaryError: action.payload,
        interaffiliatePostOfficeSummary: false,
      };
    // CHINAPAY START
    case CHINAPAY_LOADING:
      return { ...state, chinapayLoading: true };
    case GET_CHINAPAY_ACQUIRER_FILE_SUMMARY:
      return {
        ...state,
        chinapayLoading: false,
        chinapayAcquirerFileSummary: action.payload,
        chinapayAcquirerFileSummaryError: "",
      };
    case GET_CHINAPAY_ACQUIRER_FILE_SUMMARY_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayAcquirerFileSummary: null,
        chinapayAcquirerFileSummaryError: action.payload,
      };
    case GET_CHINAPAY_ACQUIRER_FILE_DETAILS:
      return {
        ...state,
        chinapayLoading: false,
        chinapayAcquirerFileDetails: action.payload,
        chinapayAcquirerFileDetailsError: null,
      };
    case GET_CHINAPAY_ACQUIRER_FILE_DETAILS_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayAcquirerFileDetailsError: action.payload,
      };
    case GET_CHINAPAY_SUMMARY:
      return {
        ...state,
        chinapayLoading: false,
        chinapayFileSummary: action.payload,
        chinapayFileSummaryError: "",
      };
    case GET_CHINAPAY_SUMMARY_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayFileSummary: null,
        chinapayFileSummaryError: action.payload,
      };
    case GET_CHINAPAY_POSTING_SUMMARY:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingSummary: action.payload,
        chinapayPostingSummaryError: "",
      };
    case GET_CHINAPAY_POSTING_SUMMARY_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingSummary: null,
        chinapayPostingSummaryError: action.payload,
      };
    case GET_CHINAPAY_POSTING_DETAILS:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingDetails: action.payload,
        chinapayPostingDetailsError: "",
      };
    case GET_CHINAPAY_POSTING_DETAILS_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingDetails: null,
        chinapayPostingDetailsError: action.payload,
      };
    case GET_CHINAPAY_POSTING_ENTRIES:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingEntries: action.payload,
        chinapayPostingEntriesError: "",
      };
    case GET_CHINAPAY_POSTING_ENTRIES_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapayPostingEntries: null,
        chinapayPostingEntriesError: action.payload,
      };
    case GET_CHINAPAY_SUMMARY_DETAILS:
      return {
        ...state,
        chinapayLoading: false,
        chinapaySummaryDetails: action.payload,
        chinapaySummaryDetailsError: "",
      };
    case GET_CHINAPAY_SUMMARY_DETAILS_ERROR:
      return {
        ...state,
        chinapayLoading: false,
        chinapaySummaryDetails: null,
        chinapaySummaryDetailsError: action.payload,
      };
    //  CHINAPAY END
    case GET_FILE_SET_ERROR:
      return { ...state, fileSetError: action.payload };
    case GET_FILESET_BATCH_DEFINITION_DETAIL:
      return { ...state, fileSetBatchDefinitionDetail: action.payload };
    case GET_FILESET_BATCH_DEFINITION_DETAIL_ERROR:
      return { ...state, fileSetBatchDefinitionDetailError: action.payload };
    case GET_ALL_VSS:
      return { ...state, allVss: action.payload };
    case GET_ALL_VSS_ERROR:
      return { ...state, allVssError: action.payload };
    case GET_VSS_110:
      return { ...state, allVss110: action.payload };
    case GET_VSS_110_ERROR:
      return { ...state, allVss110Error: action.payload };
    case GET_VSS_115:
      return { ...state, allVss115: action.payload };
    case GET_VSS_115_ERROR:
      return { ...state, allVss115Error: action.payload };
    case GET_VSS_116:
      return { ...state, allVss116: action.payload };
    case GET_VSS_116_ERROR:
      return { ...state, allVss116Error: action.payload };
    case GET_VSS_120:
      return { ...state, allVss120: action.payload };
    case GET_VSS_120_ERROR:
      return { ...state, allVss120Error: action.payload };
    case GET_VSS_300:
      return { ...state, allVss300: action.payload };
    case GET_VSS_300_ERROR:
      return { ...state, allVss300Error: action.payload };
    case GET_VSS_900:
      return { ...state, allVss900: action.payload };
    case GET_VSS_900_ERROR:
      return { ...state, allVss900Error: action.payload };
    case GET_VSS_110_SUMMARY:
      return { ...state, vss110Summary: action.payload };
    case GET_VSS_110_SUMMARY_ERROR:
      return { ...state, vss110SummaryError: action.payload };
    case GET_VSS_115_SUMMARY:
      return { ...state, vss115Summary: action.payload };
    case GET_VSS_115_SUMMARY_ERROR:
      return { ...state, vss115SummaryError: action.payload };
    case GET_VSS_116_SUMMARY:
      return { ...state, vss116Summary: action.payload };
    case GET_VSS_116_SUMMARY_ERROR:
      return { ...state, vss116SummaryError: action.payload };
    case GET_VSS_120_SUMMARY:
      return { ...state, vss120Summary: action.payload };
    case GET_VSS_120_SUMMARY_ERROR:
      return { ...state, vss120SummaryError: action.payload };
    case GET_VSS_300_SUMMARY:
      return { ...state, vss300Summary: action.payload };
    case GET_VSS_300_SUMMARY_ERROR:
      return { ...state, vss300SummaryError: action.payload };
    case GET_VSS_900_SUMMARY:
      return { ...state, vss900Summary: action.payload };
    case GET_VSS_900_SUMMARY_ERROR:
      return { ...state, vss900SummaryError: action.payload };
    case DELETE_FILE_SET_FILE_DEFINITION:
      const remainingFileSetDefinitions = state.fileSetDefinitions.filter(
        (item) => item.id !== action.payload
      );
      state.fileSetDefinitions = remainingFileSetDefinitions;
      return {
        ...state,
        fileSetFileDefinitionDeleted: "Deleted Successfully!",
      };
    case DELETE_FILE_SET_FILE_DEFINITION_ERROR:
      return { ...state, fileSetDefinitionDeletedError: action.payload };
    case GET_DAILY_POSTING_SUMMARY:
      return { ...state, dailyPostingSummary: action.payload };
    case GET_DAILY_POSTING_SUMMARY_ERROR:
      return { ...state, dailyPostingSummaryError: action.payload };
    case GET_VISA_POSTING_OVERVIEW:
      return {
        ...state,
        visaPostingOverview: action.payload,
        visaPostingOverviewLoading: false,
      };
    case GET_VISA_POSTING_OVERVIEW_LOADING:
      return { ...state, visaPostingOverviewLoading: true };
    case GET_VISA_POSTING_OVERVIEW_ERROR:
      return {
        ...state,
        visaPostingOverviewError: action.payload,
        visaPostingOverviewLoading: false,
      };
    case GET_INTERAFFILIATE_POSTING_OVERVIEW:
      return { ...state, interAffiliatePostingOverview: action.payload };
    case GET_INTERAFFILIATE_POSTING_OVERVIEW_ERROR:
      return { ...state, interAffiliatePostingOverviewError: action.payload };
    case GET_ALL_XPRESSCASH_SUMMARY_START:
      return {
        ...state,
        xpresscashLoading: true,
        xpresscashError: "",
        xpresscashSummary: null,
      };
    case GET_ALL_XPRESSCASH_SUMMARY_SUCCESS:
      return {
        ...state,
        xpresscashLoading: false,
        xpresscashError: "",
        xpresscashSummary: action.payload,
      };
    case GET_ALL_XPRESSCASH_SUMMARY_FAILURE:
      return {
        ...state,
        xpresscashLoading: false,
        xpresscashError: action.payload,
        xpresscashSummary: null,
      };
    case GET_ALL_XPRESSCASH_DETAILS_START:
      return {
        ...state,
        xpresscashDetailsLoading: true,
        xpresscashDetailsError: "",
        xpresscashDetails: null,
      };
    case GET_ALL_XPRESSCASH_DETAILS_SUCCESS:
      return {
        ...state,
        xpresscashDetailsLoading: false,
        xpresscashDetailsError: "",
        xpresscashDetails: action.payload,
      };
    case GET_ALL_XPRESSCASH_DETAILS_FAILURE:
      return {
        ...state,
        xpresscashDetailsLoading: false,
        xpresscashDetailsError: action.payload,
        xpresscashDetails: null,
      };
    case GET_ALL_XPRESSCASH_QUERY_DETAILS_START:
      return {
        ...state,
        xpresscashQueryDetailsLoading: true,
        xpresscashQueryDetailsError: "",
        xpresscashQueryDetails: null,
      };
    case GET_ALL_XPRESSCASH_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        xpresscashQueryDetailsLoading: false,
        xpresscashQueryDetailsError: "",
        xpresscashQueryDetails: action.payload,
      };
    case GET_ALL_XPRESSCASH_QUERY_DETAILS_FAILURE:
      return {
        ...state,
        xpresscashQueryDetailsLoading: false,
        xpresscashQueryDetailsError: action.payload,
        xpresscashQueryDetails: null,
      };
    case GET_ALL_XPRESSCASH_ACQUIRER_START:
      return {
        ...state,
        xpressCashAcquirerLoaing: true,
        xpressCashAcquirerError: "",
        xpressCashAcquirer: null,
      };
    case GET_ALL_XPRESSCASH_ACQUIRER_SUCCESS:
      return {
        ...state,
        xpressCashAcquirerLoaing: false,
        xpressCashAcquirerError: "",
        xpressCashAcquirer: action.payload,
      };
    case GET_ALL_XPRESSCASH_ACQUIRER_FAILURE:
      return {
        ...state,
        xpressCashAcquirerLoaing: false,
        xpressCashAcquirerError: action.payload,
        xpressCashAcquirer: null,
      };
    case GET_ALL_XPRESSCASH_ISSUER_START:
      return {
        ...state,
        xpressCashIssuerLoaing: true,
        xpressCashIssuerError: "",
        xpressCashIssuer: null,
      };
    case GET_ALL_XPRESSCASH_ISSUER_SUCCESS:
      return {
        ...state,
        xpressCashIssuerLoaing: false,
        xpressCashIssuerError: "",
        xpressCashIssuer: action.payload,
      };
    case GET_ALL_XPRESSCASH_ISSUER_FAILURE:
      return {
        ...state,
        xpressCashIssuerLoaing: false,
        xpressCashIssuerError: action.payload,
        xpressCashIssuer: null,
      };
    case GET_XPRESSCASH_SETTLEMENT_REPORT_START:
      return {
        ...state,
        xpressCashSettlementReportLoaing: true,
        xpressCashSettlementReportError: "",
        xpressCashSettlementReport: null,
      };
    case GET_XPRESSCASH_SETTLEMENT_REPORT_SUCCESS:
      return {
        ...state,
        xpressCashSettlementReportLoaing: false,
        xpressCashSettlementReportError: "",
        xpressCashSettlementReport: action.payload,
      };
    case GET_XPRESSCASH_SETTLEMENT_REPORT_FAILURE:
      return {
        ...state,
        xpressCashSettlementReportLoaing: false,
        xpressCashSettlementReportError: action.payload,
        xpressCashSettlementReport: null,
      };
    case GET_XPRESSCASH_POSTING_REPORT_START:
      return {
        ...state,
        xpressCashPostingReportLoaing: true,
        xpressCashPostingReportError: "",
        xpressCashPostingReport: null,
      };
    case GET_XPRESSCASH_POSTING_REPORT_SUCCESS:
      return {
        ...state,
        xpressCashPostingReportLoaing: false,
        xpressCashPostingReportError: "",
        xpressCashPostingReport: action.payload,
      };
    case GET_XPRESSCASH_POSTING_REPORT_FAILURE:
      return {
        ...state,
        xpressCashPostingReportLoaing: false,
        xpressCashPostingReportError: action.payload,
        xpressCashPostingReport: null,
      };
    case GET_XPRESSCASH_FEES_REPORT_START:
      return {
        ...state,
        xpressCashFeesReportLoaing: true,
        xpressCashFeesReportError: "",
        xpressCashFeesReport: null,
      };
    case GET_XPRESSCASH_FEES_REPORT_SUCCESS:
      return {
        ...state,
        xpressCashFeesReportLoaing: false,
        xpressCashFeesReportError: "",
        xpressCashFeesReport: action.payload,
      };
    case GET_XPRESSCASH_FEES_REPORT_FAILURE:
      return {
        ...state,
        xpressCashFeesReportLoaing: false,
        xpressCashFeesReportError: action.payload,
        xpressCashFeesReport: null,
      };
    case GET_XPRESSCASH_FEES_SUMM_START:
      return {
        ...state,
        xpressCashSummReportLoaing: true,
        xpressCashSummReportError: "",
        xpressCashSummReport: null,
      };
    case GET_XPRESSCASH_FEES_SUMM_SUCCESS:
      return {
        ...state,
        xpressCashSummReportLoaing: false,
        xpressCashSummReportError: "",
        xpressCashSummReport: action.payload,
      };
    case GET_XPRESSCASH_FEES_SUMM_FAILURE:
      return {
        ...state,
        xpressCashSummReportLoaing: false,
        xpressCashSummReportError: action.payload,
        xpressCashSummReport: null,
      };
    case GET_XPRESSCASH_AQUIRER_REPORT_START:
      return {
        ...state,
        xpressCashAcquirerReportLoaing: true,
        xpressCashAcquirerReportError: "",
        xpressCashAcquirerReport: null,
      };
    case GET_XPRESSCASH_AQUIRER_REPORT_SUCCESS:
      return {
        ...state,
        xpressCashAcquirerReportLoaing: false,
        xpressCashAcquirerReportError: "",
        xpressCashAcquirerReport: action.payload,
      };
    case GET_XPRESSCASH_AQUIRER_REPORT_FAILURE:
      return {
        ...state,
        xpressCashAcquirerReportLoaing: false,
        xpressCashAcquirerReportError: action.payload,
        xpressCashAcquirerReport: null,
      };
    case GET_XPRESSCASH_ISSUER_REPORT_START:
      return {
        ...state,
        xpressCashIssuerReportLoaing: true,
        xpressCashIssuerReportError: "",
        xpressCashIssuerReport: null,
      };
    case GET_XPRESSCASH_ISSUER_REPORT_SUCCESS:
      return {
        ...state,
        xpressCashIssuerReportLoaing: false,
        xpressCashIssuerReportError: "",
        xpressCashIssuerReport: action.payload,
      };
    case GET_XPRESSCASH_ISSUER_REPORT_FAILURE:
      return {
        ...state,
        xpressCashIssuerReportLoaing: false,
        xpressCashIssuerReportError: action.payload,
        xpressCashIssuerReport: null,
      };
    case GET_ALL_XPRESSCASH_SINGLE_SUMMARY_START:
      return {
        ...state,
        xpressCashSingleDetailLoaing: true,
        xpressCashSingleDetailError: "",
        xpressCashSingleDetail: null,
      };
    case GET_ALL_XPRESSCASH_SINGLE_SUMMARY_SUCCESS:
      return {
        ...state,
        xpressCashSingleDetailLoaing: false,
        xpressCashSingleDetailError: "",
        xpressCashSingleDetail: action.payload,
      };
    case GET_ALL_XPRESSCASH_SINGLE_SUMMARY_FAILURE:
      return {
        ...state,
        xpressCashSingleDetailLoaing: false,
        xpressCashSingleDetailError: action.payload,
        xpressCashSingleDetail: null,
      };
    case GET_ALL_MVISA_SUMMARY_START:
      return {
        ...state,
        mvisaLoading: true,
        mvisaError: "",
        mvisaSummary: null,
      };
    case GET_ALL_MVISA_SUMMARY_SUCCESS:
      return {
        ...state,
        mvisaLoading: false,
        mvisaError: "",
        mvisaSummary: action.payload,
      };
    case GET_ALL_MVISA_SUMMARY_FAILURE:
      return {
        ...state,
        mvisaLoading: false,
        mvisaError: action.payload,
        mvisaSummary: null,
      };
    case GET_ALL_MVISA_DETAILS_START:
      return {
        ...state,
        mvisaDetailsLoading: true,
        mvisaDetailsError: "",
        mvisaDetails: null,
      };
    case GET_ALL_MVISA_DETAILS_SUCCESS:
      return {
        ...state,
        mvisaDetailsLoading: false,
        mvisaDetailsError: "",
        mvisaDetails: action.payload,
      };
    case GET_ALL_MVISA_DETAILS_FAILURE:
      return {
        ...state,
        mvisaDetailsLoading: false,
        mvisaDetailsError: action.payload,
        mvisaDetails: null,
      };
    case GET_ALL_MVISA_QUERY_DETAILS_START:
      return {
        ...state,
        mvisaQueryDetailsLoading: true,
        mvisaQueryDetailsError: "",
        mvisaQueryDetails: null,
      };
    case GET_ALL_MVISA_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        mvisaQueryDetailsLoading: false,
        mvisaQueryDetailsError: "",
        mvisaQueryDetails: action.payload,
      };
    case GET_ALL_MVISA_QUERY_DETAILS_FAILURE:
      return {
        ...state,
        mvisaQueryDetailsLoading: false,
        mvisaQueryDetailsError: action.payload,
        mvisaQueryDetails: null,
      };
    case GET_ALL_MVISA_ACQUIRER_START:
      return {
        ...state,
        mvisaAcquirerLoading: true,
        mvisaAcquirerError: "",
        mvisaAcquirer: null,
      };
    case GET_ALL_MVISA_ACQUIRER_SUCCESS:
      return {
        ...state,
        mvisaAcquirerLoading: false,
        mvisaAcquirerError: "",
        mvisaAcquirer: action.payload,
      };
    case GET_ALL_MVISA_ACQUIRER_FAILURE:
      return {
        ...state,
        mvisaAcquirerLoading: false,
        mvisaAcquirerError: action.payload,
        mvisaAcquirer: null,
      };
    case GET_ALL_MVISA_ISSUER_START:
      return {
        ...state,
        mvisaIssuerLoading: true,
        mvisaIssuerError: "",
        mvisaIssuer: null,
      };
    case GET_ALL_MVISA_ISSUER_SUCCESS:
      return {
        ...state,
        mvisaIssuerLoading: false,
        mvisaIssuerError: "",
        mvisaIssuer: action.payload,
      };
    case GET_ALL_MVISA_ISSUER_FAILURE:
      return {
        ...state,
        mvisaIssuerLoading: false,
        mvisaIssuerError: action.payload,
        mvisaIssuer: null,
      };
    case GET_ALL_MVISA_SINGLE_SUMMARY_START:
      return {
        ...state,
        mvisaSingleSummaryLoading: true,
        mvisaSingleSummaryError: "",
        mvisaSingleSummary: null,
      };
    case GET_ALL_MVISA_SINGLE_SUMMARY_SUCCESS:
      return {
        ...state,
        mvisaSingleSummaryLoading: false,
        mvisaSingleSummaryError: "",
        mvisaSingleSummary: action.payload,
      };
    case GET_ALL_MVISA_SINGLE_SUMMARY_FAILURE:
      return {
        ...state,
        mvisaSingleSummaryLoading: false,
        mvisaSingleSummaryError: action.payload,
        mvisaSingleSummary: null,
      };
    case GET_MVISA_SUM_REPORT_START:
      return {
        ...state,
        mvisaSumReportLoading: true,
        mvisaSumReportError: "",
        mvisaSumReport: null,
      };
    case GET_MVISA_SUM_REPORT_SUCCESS:
      return {
        ...state,
        mvisaSumReportLoading: false,
        mvisaSumReportError: "",
        mvisaSumReport: action.payload,
      };
    case GET_MVISA_SUM_REPORT_FAILURE:
      return {
        ...state,
        mvisaSumReportLoading: false,
        mvisaSumReportError: action.payload,
        mvisaSumReport: null,
      };
    case GET_XPRESSCASH_MVISA_REPORT_START:
      return {
        ...state,
        mvisaSettlementReportLoading: true,
        mvisaSettlementReportError: "",
        mvisaSettlementReport: null,
      };
    case GET_XPRESSCASH_MVISA_REPORT_SUCCESS:
      return {
        ...state,
        mvisaSettlementReportLoading: false,
        mvisaSettlementReportError: "",
        mvisaSettlementReport: action.payload,
      };
    case GET_XPRESSCASH_MVISA_REPORT_FAILURE:
      return {
        ...state,
        mvisaSettlementReportLoading: false,
        mvisaSettlementReportError: action.payload,
        mvisaSettlementReport: null,
      };
    case GET_MVISA_POSTING_REPORT_START:
      return {
        ...state,
        mvisaPostingReportLoading: true,
        mvisaPostingReportError: "",
        mvisaPostingReport: null,
      };
    case GET_MVISA_POSTING_REPORT_SUCCESS:
      return {
        ...state,
        mvisaPostingReportLoading: false,
        mvisaPostingReportError: "",
        mvisaPostingReport: action.payload,
      };
    case GET_MVISA_POSTING_REPORT_FAILURE:
      return {
        ...state,
        mvisaPostingReportLoading: false,
        mvisaPostingReportError: action.payload,
        mvisaPostingReport: null,
      };
    case GET_MVISA_FEES_REPORT_START:
      return {
        ...state,
        mvisaFeesReportLoading: true,
        mvisaFeesReportError: "",
        mvisaFeesReport: null,
      };
    case GET_MVISA_FEES_REPORT_SUCCESS:
      return {
        ...state,
        mvisaFeesReportLoading: false,
        mvisaFeesReportError: "",
        mvisaFeesReport: action.payload,
      };
    case GET_MVISA_FEES_REPORT_FAILURE:
      return {
        ...state,
        mvisaFeesReportLoading: false,
        mvisaFeesReportError: action.payload,
        mvisaFeesReport: null,
      };
    case GET_MVISA_ACQUIRER_REPORT_START:
      return {
        ...state,
        mvisaAcquirerReportLoading: true,
        mvisaAcquirerReportError: "",
        mvisaAcquirerReport: null,
      };
    case GET_MVISA_ACQUIRER_REPORT_SUCCESS:
      return {
        ...state,
        mvisaAcquirerReportLoading: false,
        mvisaAcquirerReportError: "",
        mvisaAcquirerReport: action.payload,
      };
    case GET_MVISA_ACQUIRER_REPORT_FAILURE:
      return {
        ...state,
        mvisaAcquirerReportLoading: false,
        mvisaAcquirerReportError: action.payload,
        mvisaAcquirerReport: null,
      };
    case GET_MVISA_ISSUER_REPORT_START:
      return {
        ...state,
        mvisaIssuerReportLoading: true,
        mvisaIssuerReportError: "",
        mvisaIssuerReport: null,
      };
    case GET_MVISA_ISSUER_REPORT_SUCCESS:
      return {
        ...state,
        mvisaIssuerReportLoading: false,
        mvisaIssuerReportError: "",
        mvisaIssuerReport: action.payload,
      };
    case GET_MVISA_ISSUER_REPORT_FAILURE:
      return {
        ...state,
        mvisaIssuerReportLoading: false,
        mvisaIssuerReportError: action.payload,
        mvisaIssuerReport: null,
      };
    case GET_QUERY_DETAILS:
      return { ...state, mastercard_query_details: action.payload };
    case GET_QUERY_DETAILS_ERROR:
      return { ...state, mastercard_query_details_error: action.payload };
    // MASTER PASS START
    case MASTERPASS_LOADING:
      return { ...state, MasterpassLoading: true };
    case GET_MASTERPASS_SUMMARIES:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummariesError: "",
        MasterpassSummaries: action.payload,
      };
    case GET_MASTERPASS_SUMMARIES_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummariesError: action.payload,
        MasterpassSummaries: null,
      };
    case GET_MASTERPASS_DETAILS:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassDetailsError: "",
        MasterpassDetails: action.payload,
      };
    case GET_MASTERPASS_DETAILS_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassDetailsError: action.payload,
        MasterpassDetails: null,
      };
    case GET_MASTERPASS_ACQUIRERS:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassAcquirersError: "",
        MasterpassAcquirers: action.payload,
      };
    case GET_MASTERPASS_ACQUIRERS_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassAcquirersError: action.payload,
        MasterpassAcquirers: null,
      };
    case GET_MASTERPASS_ISSUERS:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassIssuersError: "",
        MasterpassIssuers: action.payload,
      };
    case GET_MASTERPASS_ISSUERS_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassIssuersError: action.payload,
        MasterpassIssuers: null,
      };
    case GET_MASTERPASS_SETTLEMENT_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSettlementReportError: "",
        MasterpassSettlementReport: action.payload,
      };
    case GET_MASTERPASS_SETTLEMENT_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSettlementReportError: action.payload,
        MasterpassSettlementReport: null,
      };
    case GET_MASTERPASS_POSTING_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassPostingReportError: "",
        MasterpassPostingReport: action.payload,
      };
    case GET_MASTERPASS_POSTING_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassPostingReportError: action.payload,
        MasterpassPostingReport: null,
      };
    case GET_MASTERPASS_SETTLEMENT_FEES_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSettlementFeesReportError: "",
        MasterpassSettlementFeesReport: action.payload,
      };
    case GET_MASTERPASS_SETTLEMENT_FEES_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSettlementFeesReportError: action.payload,
        MasterpassSettlementFeesReport: null,
      };
    case GET_MASTERPASS_SUMM_SETTLEMENT_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummSettlementReportError: "",
        MasterpassSummSettlementReport: action.payload,
      };
    case GET_MASTERPASS_SUMM_SETTLEMENT_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummSettlementReportError: action.payload,
        MasterpassSummSettlementReport: null,
      };
    case GET_MASTERPASS_ACQUIRER_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassAcquirerReportError: "",
        MasterpassAcquirerReport: action.payload,
      };
    case GET_MASTERPASS_ACQUIRER_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassAcquirerReportError: action.payload,
        MasterpassAcquirerReport: null,
      };
    case GET_MASTERPASS_ISSUER_REPORT:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassIssuerReportError: "",
        MasterpassIssuerReport: action.payload,
      };
    case GET_MASTERPASS_ISSUER_REPORT_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassIssuerReportError: action.payload,
        MasterpassIssuerReport: null,
      };
    case GET_MASTERPASS_SUMMARY:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummaryError: "",
        MasterpassSummary: action.payload,
      };
    case GET_MASTERPASS_SUMMARY_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MMasterpassSummaryError: action.payload,
        MasterpassSummary: null,
      };
    case GET_MASTERPASS_QUERY_DETAILS:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummaryQueryDetailsError: "",
        MasterpassSummaryQueryDetails: action.payload,
      };
    case GET_MASTERPASS_QUERY_DETAILS_ERROR:
      return {
        ...state,
        MasterpassLoading: false,
        MasterpassSummaryQueryDetailsError: action.payload,
        MasterpassSummaryQueryDetails: null,
      };
    // MASTER PASS END
    // Ecommerce
    case FETCH_ALL_ECOMMERCE_SCHEME:
      return {
        ...state,
        ecommerceRegions: action.payload,
        ecommerceRegionsError: false,
      };
    case FETCH_ALL_ECOMMERCE_SCHEME_ERROR:
      return {
        ...state,
        ecommerceRegionsError: action.payload,
        ecommerceRegions: false,
      };
    case ECOMMERCE_DETAILS:
      return {
        ...state,
        ecommerceDetails: action.payload,
        ecommerceDetailsError: false,
      };
    case ECOMMERCE_DETAILS_ERROR:
      return {
        ...state,
        ecommerceDetailsError: action.payload,
        ecommerceDetails: false,
      };
    case ECOMMERCE_ISSUER_DETAILS:
      return {
        ...state,
        ecommerceIssuersDetails: action.payload,
        ecommerceIssuerDetailsError: false,
      };
    case ECOMMERCE_ISSUER_DETAILS_ERROR:
      return {
        ...state,
        ecommerceIssuerDetailsError: action.payload,
        ecommerceIssuerDetails: false,
      };
    case ECOMMERCE_ACQUIRER_DETAILS:
      return {
        ...state,
        ecommerceAcquirerDetails: action.payload,
        ecommerceAcquirerDetailsError: false,
      };
    case ECOMMERCE_ACQUIRER_DETAILS_ERROR:
      return {
        ...state,
        ecommerceAcquirerDetailsError: action.payload,
        ecommercAcquirerDetails: false,
      };
    case GET_ECOMMERCE_SETTLEMENT_REPORT_START:
      return {
        ...state,
        ecommerceSettlmentReportLoading: true,
        ecommerceSettlmentReportError: "",
        ecommerceSettlmentReport: null,
      };
    case GET_ECOMMERCE_SETTLEMENT_REPORT_SUCCESS:
      return {
        ...state,
        ecommerceSettlmentReportLoading: false,
        ecommerceSettlmentReportError: "",
        ecommerceSettlmentReport: action.payload,
      };
    case GET_ECOMMERCE_SETTLEMENT_REPORT_FAILURE:
      return {
        ...state,
        ecommerceSettlmentReportLoading: false,
        ecommerceSettlmentReportError: action.payload,
        ecommerceSettlmentReport: null,
      };
    case GET_ECOMMERCE_POSTING_REPORT_START:
      return {
        ...state,
        ecommercePostingReportLoading: true,
        ecommercePostingReportError: "",
        ecommercePostingReport: null,
      };
    case GET_ECOMMERCE_POSTING_REPORT_SUCCESS:
      return {
        ...state,
        ecommercePostingReportLoading: false,
        ecommercePostingReportError: "",
        ecommercePostingReport: action.payload,
      };
    case GET_ECOMMERCE_POSTING_REPORT_FAILURE:
      return {
        ...state,
        ecommercePostingReportLoading: false,
        ecommercePostingReportError: action.payload,
        ecommercePostingReport: null,
      };
    case GET_ECOMMERCE_FEES_REPORT_START:
      return {
        ...state,
        ecommerceFeesReportLoading: true,
        ecommerceFeesReportError: "",
        ecommerceFeesReport: null,
      };
    case GET_ECOMMERCE_FEES_REPORT_SUCCESS:
      return {
        ...state,
        ecommerceFeesReportLoading: false,
        ecommerceFeesReportError: "",
        ecommerceFeesReport: action.payload,
      };
    case GET_ECOMMERCE_FEES_REPORT_FAILURE:
      return {
        ...state,
        ecommerceFeesReportLoading: false,
        ecommerceFeesReportError: action.payload,
        ecommerceFeesReport: null,
      };
    case GET_ECOMMERCE_SUMM_REPORT_START:
      return {
        ...state,
        ecommerceSumReportLoading: true,
        ecommerceSumReportError: "",
        ecommerceSumReport: null,
      };
    case GET_ECOMMERCE_SUMM_REPORT_SUCCESS:
      return {
        ...state,
        ecommerceSumReportLoading: false,
        ecommerceSumReportError: "",
        ecommerceSumReport: action.payload,
      };
    case GET_ECOMMERCE_SUMM_REPORT_FAILURE:
      return {
        ...state,
        ecommerceSumReportLoading: false,
        ecommerceSumReportError: action.payload,
        ecommerceSumReport: null,
      };
    case GET_ECOMMERCE_AQUIRER_REPORT_START:
      return {
        ...state,
        ecommerceAcquirerReportLoading: true,
        ecommerceAcquirerReportError: "",
        ecommerceAcquirerReport: null,
      };
    case GET_ECOMMERCE_AQUIRER_REPORT_SUCCESS:
      return {
        ...state,
        ecommerceAcquirerReportLoading: false,
        ecommerceAcquirerReportError: "",
        ecommerceAcquirerReport: action.payload,
      };
    case GET_ECOMMERCE_AQUIRER_REPORT_FAILURE:
      return {
        ...state,
        ecommerceAcquirerReportLoading: false,
        ecommerceAcquirerReportError: action.payload,
        ecommerceAcquirerReport: null,
      };
    case GET_ECOMMERCE_ISSUER_REPORT_START:
      return {
        ...state,
        ecommerceIssuerReportLoading: true,
        ecommerceIssuerReportError: "",
        ecommerceIssuerReport: null,
      };
    case GET_ECOMMERCE_ISSUER_REPORT_SUCCESS:
      return {
        ...state,
        ecommerceIssuerReportLoading: false,
        ecommerceIssuerReportError: "",
        ecommerceIssuerReport: action.payload,
      };
    case GET_ECOMMERCE_ISSUER_REPORT_FAILURE:
      return {
        ...state,
        ecommerceIssuerReportLoading: false,
        ecommerceIssuerReportError: action.payload,
        ecommerceIssuerReport: null,
      };
    case GET_ALL_ECOMMERCE_SINGLE_SUMMARY_START:
      return {
        ...state,
        ecommerceSingleSummaryLoading: true,
        ecommerceSingleSummaryError: "",
        ecommerceSingleSummary: null,
      };
    case GET_ALL_ECOMMERCE_SINGLE_SUMMARY_SUCCESS:
      return {
        ...state,
        ecommerceSingleSummaryLoading: false,
        ecommerceSingleSummaryError: "",
        ecommerceSingleSummary: action.payload,
      };
    case GET_ALL_ECOMMERCE_SINGLE_SUMMARY_FAILURE:
      return {
        ...state,
        ecommerceSingleSummaryLoading: false,
        ecommerceSingleSummaryError: action.payload,
        ecommerceSingleSummary: null,
      };
    case GET_ALL_ECOMMERCE_QUERY_DETAILS_START:
      return {
        ...state,
        ecommerceEcommerceDetailsLoading: true,
        ecommerceEcommerceDetailsError: "",
        ecommerceEcommerceDetails: null,
      };
    case GET_ALL_ECOMMERCE_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        ecommerceEcommerceDetailsLoading: false,
        ecommerceEcommerceDetailsError: "",
        ecommerceEcommerceDetails: action.payload,
      };
    case GET_ALL_ECOMMERCE_QUERY_DETAILS_FAILURE:
      return {
        ...state,
        ecommerceEcommerceDetailsLoading: false,
        ecommerceEcommerceDetailsError: action.payload,
        ecommerceEcommerceDetails: null,
      };
    case DELETE_POSTING_LOG_ID_AND_RECORDS:
      return { ...state, deletedPostingId: action.payload };
    case DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR:
      return { ...state, deletedPostingIdError: action.payload };
    case DELETE_CHANNEL_LOG:
      return { ...state, deletedFilename: action.payload };
    case DELETE_CHANNEL_LOG_ERROR:
      return { ...state, deletedFilenameError: action.payload };
    case GET_NOSTRO_REPORTS_LOADING:
      return { ...state, nostroReportsLoading: true };
    case GET_NOSTRO_REPORTS:
      return {
        ...state,
        nostroReports: action.payload,
        nostroReportsLoading: false,
      };
    case GET_NOSTRO_REPORTS_ERROR:
      return {
        ...state,
        nostroReportsError: action.payload,
        nostroReportsLoading: false,
      };
    case TRIGGER_POSTING:
      return { ...state, resetSuccess: true };
    case TRIGGER_POSTING_ERROR:
      return { ...state, resetError: action.payload };
    case RESET_TRIGGER_RESPONSE:
      return { ...state, resetSuccess: null };
    case GET_MASTERCARD_RESOLVED_FILES:
      return { ...state, mastercardResolvedFiles: action.payload };
    case GET_MASTERCARD_RESOLVED_FILES_FAILURE:
      return { ...state, mastercardResolvedFilesError: action.payload };
    case CLEAR_RESOLVED_FILES_MESSAGE:
      return {
        ...state,
        mastercardResolvedFiles: null,
        mastercardResolvedFilesError: null,
      };
    default:
      return state;
  }
}
