import { SAS_BACKEND_URL } from "../Config";
import { SAS_UPLOAD_URL } from "../Config";
export const CREATE_PRODUCT = "create_product";
export const GET_PRODUCTS = "get_products";
export const GET_PRODUCT = "get_product";
export const GET_PRODUCT_ACCOUNTS = "get_product_accounts";
export const CREATE_PROCESSOR = "create_processor";
export const UPDATE_PRODUCT = "update_product";
export const DELETE_PRODUCT = "delete_product";
export const CREATE_PROCESSOR_ERROR = "create_processor_error";
export const UPDATE_PROCESSOR_ERROR = "update_processor_error";
export const DELETE_PROCESSOR = "delete_processor";
export const GET_PROCESSORS = "get_processors";
export const GET_PROCESSORS_BYDATE = "get_processors_bydate";
export const GET_PROCESSOR = "get_processor";
export const GET_PROCESSORS_FILESET = "get_processors_fileset";
export const GET_PROCESSORS_FILESET_ERROR = "get_processors_fileset_error";
export const GET_SUBGROUP_ACCOUNTS = "get_subgroup_accounts";
export const CREATE_SUBGROUP = "create_subgroup";
export const GET_SUBGROUP = "get_subgroup";
export const CREATE_SUBGROUP_ERROR = "create_subgroup_error";
export const GET_PROCESSOR_SUBGROUPS = "get_processor_subgroups";
export const GET_SUBGROUPS = "get_subgroups";
export const UPDATE_SUBGROUP = "update_subgroup";
export const UPDATE_PROCESSOR = "update_processor";
export const CREATE_PRODUCT_ERROR = "create_product_error";
export const TOP_CONTRIBUTORS = "top_contributors";
export const BOTTOM_CONTRIBUTORS = "bottom_contributors";
export const GET_BATCHES = "get_batches";
export const GET_BATCHES_TRANSACTIONS = "get_batches_transactions";
export const CREATE_PRODUCT_DEFINITIONS = "create_product_definitions";
export const DELETE_PRODUCT_DEFINITION = "delete_product_definition";
export const CREATE_PRODUCT_DEFINITIONS_ERROR =
  "create_product_definitions_error";
export const GET_PRODUCT_DEFINITIONS = "get_product_definitions";
export const GET_OPERATORS = "get_operators";
export const GET_LOGS = "get_logs";
export const GET_LOGS_ERROR = "get_logs_error";
export const UPDATE_PRODUCT_DEFINITION = "update_product_definition";
export const UPDATE_PRODUCT_DEFINITION_ERROR =
  "update_product_definition_error";
export const GET_PRODUCT_DEFINITIONS_DETAILS =
  "get_product_definitions_details";
export const GET_TEMPLATES = "get_templates";
export const CREATE_TEMPLATE = "create_template";
export const UPDATE_TEMPLATE = "update_template";
export const DELETE_TEMPLATE = "delete_template";
export const GET_CHANNEL_LOGS = "get_channel_logs";
export const GET_BATCHES_SUMMARY = "get_batches_summary";
export const POST_ALL_TRANSACTIONS = "post_all_transactions";
export const POST_TRANSACTION = "post_transaction";
export const POST_TRANSACTION_ERROR = "post_transaction_error";
export const GET_ACCOUNTS = "get_accounts";
export const CREATE_ACCOUNT = "create_account";
export const CREATE_ACCOUNT_ERROR = "create_account_error";
export const UPDATE_ACCOUNT = "update_account";
export const DELETE_ACCOUNT = "delete_account";
export const GET_ACCOUNT = "get_account";
export const GET_DAILY_PROCESSORS_SUMMARY = "get_daily_processor";
export const GET_DAILY_PROCESSORS_SUBGROUPS_SUMMARY =
  "get_daily_subgroups_summary";
export const GET_DAILY_PROCESSORS_SUBGROUPS_PRODUCTS_SUMMARY =
  "get_daily_processor_subgroups_product_summary";
export const GET_DAILY_PROCESSORS_SUBGROUPS_PRODUCTS_ACCOUNTS_SUMMARY =
  "get_daily_processor_subgroups_product_accounts_summary";
export const GET_CURRENCY = "get_currency";
export const MANUAL_ADD_BATCH = "manual_add_batch";
export const MANUAL_ADD_BATCH_ERROR = "manual_add_batch_error";
export const GET_FILE_DEFINITIONS = "get_file_definitions";
export const CREATE_FILE_DEFINITION = "create_file_definition";
export const CREATE_FILE_DEFINITION_ERROR = "create_file_definition_error";
export const GET_FILE_DEFINITION = "get_file_definition";
export const UPDATE_FILE_DEFINITION = "update_file_definition";
export const UPDATE_FILE_DEFINITION_ERROR = "update_file_definition_error";
export const DELETE_FILE_DEFINITION = "delete_file_definition";
export const GET_DAILY_PROCESSOR_ACCOUNT_POST =
  "get_daily_processor_post_account";
export const GET_PRODUCTS_BYDATE = "get_products_bydate";
export const GET_SUBGROUPS_BYDATE = "get_subgroups_bydate";
export const ACCOUNTS_BYDATE = "accounts_bydate";
export const GET_MANUAL_POSTINGS = "fetch_manual_postings";
export const GET_MANUAL_BATCHES_ERROR = "fetch_manual_batches_error";
export const GET_EXCEPTION_DEFINITIONS = "fetch_exception_definitions";
export const CREATE_EXCEPTION_DEFINITION = "create_exception_definition";
export const CREATE_EXCEPTION_DEFINITION_ERROR =
  "create_exception_definition_error";
export const GET_EXCEPTION_DEFINITION = "fetch_exception_definition";
export const UPDATE_EXCEPTION_DEFINITION = "update_exception_definition";
export const GET_PROCESSOR_EXCEPTION_BYDATE = "get_processor_exception_by_date";
export const GET_PROCESSOR_CHANNEL_EXCEPTION_BYDATE =
  "get_processor_channel_exception_by_date";
export const GET_CHANNEL_ROWS = "fetch_channel_rows";
export const POST_CUSTOMER_EXCEPTION = "post_customer_exception";
export const ALL_EXCEPTION_DEFINITIONS = "all_exception_definitions";
export const GET_ALL_ACCOUNT_REPORT = "get_all_account_report";
export const GET_ALL_CHANNEL_REPORT = "get_all_channel_report";
export const GET_DAILY_PROCESSOR_REPORT = "get_daily_processor_report";
export const GET_ALL_ACCOUNT_REPORT_ERROR = "get_all_account_report_error";
export const GET_PENDING_AUTHORIZATIONS = "get_pending_authorizations";
export const APPROVE_AUTHORIZATION = "approve_authorization";
export const APPROVE_AUTHORIZATION_ERROR = "approve_authorization_error";
export const DECODE_TOKEN = "decode_token";
export const GET_BOTTOM_CONTRIBUTOR_ERROR = "get_bottom_contributor_error";
export const POST_MULTIPLE_CUSTOMER_EXCEPTION =
  "post_multiple_customer_exception";
export const LOCALE_SET = "locale_set";
export const UPDATE_LOCALE = "update_locale";
export const GET_FILE_SETS = "get_file_sets";
export const GET_FILE_SETS_ERROR = "get_file_sets_error";
export const GET_FILE_SETS_PAGINATED = "get_file_sets_paginated";
export const GET_FILE_SETS_PAGINATED_ERROR = "get_file_sets_paginated_error";
export const CREATE_FILE_SET = "create_file_set";
export const CREATE_FILE_SET_ERROR = "create_file_set_error";
export const UPDATE_FILE_SET = "update_file_set";
export const UPDATE_FILE_SET_ERROR = "update_file_set_error";
export const DELETE_FILE_SET = "delete_file_set";
export const DELETE_FILE_SET_ERROR = "delete_file_set_error";
export const GET_FILE_SET = "get_file_set";
export const GET_FILE_SET_ERROR = "get_file_set_error";
export const GET_FILE_SET_DEFINITIONS = "get_file_set_definitions";
export const GET_FILE_SET_DEFINITIONS_ERROR = "get_file_set_definitions_error";
export const ADD_FILE_DEFINITIONS_TO_FILESET = "add_file_defintion_to_fileset";
export const ADD_FILE_DEFINITIONS_TO_FILESET_ERROR =
  "add_file_definition_to_fileset_error";
export const DELETE_FILE_SET_FILE_DEFINITION =
  "delete_file_set_file_definition";
export const DELETE_FILE_SET_FILE_DEFINITION_ERROR =
  "delete_file_set_file_definition_error";
export const GET_FILE_SET_BATCHES_PAGINATED = "get_file_set_batches_paginated";
export const GET_FILE_SET_BATCHES_PAGINATED_ERROR =
  "get_file_set_batches_paginated_error";
export const GET_FILE_SET_DEFINITION_BATCHES_PAGINATED =
  "get_file_set_definition_batches_paginated";
export const GET_FILE_SET_DEFINITION_BATCHES_PAGINATED_ERROR =
  "get_file_set_definition_batches_paginated_error";
export const GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED =
  "get_file_set_definition_batches_transactions_paginated";
export const GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR =
  "get_file_set_definitiion_batches_transactions_paginated";
export const CREATE_BANK = "create_bank";
export const CREATE_BANK_ERROR = "create_bank_error";
export const GET_BANKS = "get_banks";
export const GET_BANKS_ERROR = "get_banks_error";
export const GET_BANKS_UNPAGINATED = "get_banks_unpaginated";
export const GET_BANKS_UNPAGINATED_ERROR = "get_banks_unpaginated_error";
export const GET_BANK = "get_a_bank";
export const GET_BANK_ERROR = "get_a_bank_error";
export const DELETE_BANK = "delete_bank";
export const DELETE_BANK_ERROR = "delete_banK_error";
export const UPDATE_BANK = "update_bank";
export const UPDATE_BANK_ERROR = "update_bank_error";
export const CREATE_CARD = "create_card";
export const CREATE_CARD_ERROR = "create_card_error";
export const GET_CARDS = "get_cards";
export const GET_CARDS_ERROR = "get_cards_error";
export const GET_CARDS_UNPAGINATED = "get_cards_unpaginated";
export const GET_CARDS_UNPAGINATED_ERROR = "get_cards_unpaginated_error";
export const GET_CARD = "get_card";
export const GET_CARD_ERROR = "get_card_error";
export const UPDATE_CARD = "update_card";
export const UPDATE_CARD_ERROR = "update_card_error";
export const DELETE_CARD = "delete_card";
export const GET_BINS = "get_bins";
export const GET_BINS_ERROR = "get_bins_error";
export const GET_BINS_UNPAGINATED = "get_bins_unpaginated";
export const GET_BINS_UNPAGINATED_ERROR = "get_bins_unpaginated_error";
export const GET_BIN = "get_bin";
export const GET_BIN_ERROR = "get_bin_error";
export const CREATE_BIN = "create_bin";
export const CREATE_BIN_ERROR = "create_bin_error";
export const UPDATE_BIN = "update_bin";
export const UPDATE_BIN_ERROR = "update_bin_error";
export const DELETE_BIN = "delete_bin";
export const DELETE_BIN_ERROR = "delete_bin_error";
export const GET_AFFILIATES = "get_affiliates";
export const GET_AFFILIATE_ACCOUNT_TYPE = "get_affiliate_account_type";
export const GET_AFFILIATE_ACCOUNT_TYPE_ERROR =
  "get_affiliate_account_type_error";
export const GET_AFFILIATE_SCHEME_TYPE = "get_affiliate_scheme_type";
export const GET_AFFILIATE_SCHEME_TYPE_ERROR =
  "get_affiliate_scheme_type_error";
export const GET_AFFILIATE_PRODUCT_TYPE = "get_affiliate_product_type";
export const GET_AFFILIATE_PRODUCT_TYPE_ERROR =
  "get_affiliate_product_type_error";
export const ADD_AFFILIATE_PROCESSOR_ACCOUNT =
  "add_affiliate_processor_account";
export const ADD_AFFILIATE_PROCESSOR_ACCOUNT_ERROR =
  "add_affiliate_processor_account_error";
export const CLEAR_ADD_AFFILIATE_PROCESSOR_ACCOUNT_RESPONSE =
  "clear_add_affiliate_processor_account_response";
export const ADD_AFFILIATE_PRODUCT_ACCOUNT = "add_affiliate_product_account";
export const ADD_AFFILIATE_PRODUCT_ACCOUNT_ERROR =
  "add_affiliate_product_account_error";
export const CLEAR_ADD_AFFILIATE_PRODUCT_ACCOUNT_RESPONSE =
  "clear_add_affiliate_product_account_response";
export const GET_AFFILIATES_UNPAGINATED = "get_affiliates_unpaginated";
export const GET_AFFILIATES_UNPAGINATED_ERROR =
  "get_affiliates_unpaginated_error";
export const GET_AFFILIATES_ERROR = "get_affiliates_error";
export const GET_AFFILIATE = "get_affiliate";
export const GET_AFFILIATE_ERROR = "get_affiliate_error";
export const UPDATE_AFFILIATE = "update_affiliate";
export const UPDATE_AFFILIATE_ERROR = "update_affiliate_error";
export const CREATE_AFFILIATE = "create_affiliate";
export const CREATE_AFFILIATE_ERROR = "create_affiliate_error";
export const DELETE_AFFILIATE = "delete_affiliate";
export const DELETE_AFFILIATE_ERROR = "delete_affiliate_error";
export const GET_SRES = "get_sres";
export const GET_SRES_ERROR = "get_sres_error";
export const GET_SRES_UNPAGINATED = "get_sres_unpaginated";
export const GET_SRES_UNPAGINATED_ERROR = "get_sres_unpaginated_error";
export const GET_SRE = "get_sre";
export const GET_SRE_ERROR = "get_sre_error";
export const CREATE_SRE = "create_sre";
export const CREATE_SRE_ERROR = "create_sre_error";
export const UPDATE_SRE = "update_sre";
export const UPDATE_SRE_ERROR = "update_sre_error";
export const DELETE_SRE = "delete_sre";
export const DELETE_SRE_ERROR = "delete_sre_error";
export const GET_AFFILIATE_BINS = "get_affiliate_bins";
export const GET_AFFILIATE_BINS_ERROR = "get_affiliate_bins_error";
export const ADD_BINS_TO_AFFILIATE = "add_bins_to_affiliate";
export const ADD_BINS_TO_AFILIATE_ERROR = "add_bins_to_affiliate_error";
export const GET_AFFILIATES_SRES = "get_affiliate_sres";
export const GET_AFFILIATES_SRES_ERROR = "get_affiliate_sres_error";
export const ADD_SRES_TO_AFFILIATE = "add_sre_to_affiliate";
export const ADD_SRES_TO_AFFILIATE_ERROR = "add_sre_to_affiliate_error";
export const GET_BANK_CARDS = "get_bank_cards";
export const GET_BANK_CARDS_ERROR = "get_bank_cards_error";
export const ADD_CARDS_TO_BANK = "add_card_to_bank";
export const ADD_CARDS_TO_BANK_ERROR = "add_card_to_bank_error";
export const GET_FILESET_BATCH_DETAIL = "get_fileset_batch_detail";
export const GET_FILESET_BATCH_DETAIL_ERROR = "get_fileset_batch_detail_error";
export const GET_FILESET_BATCH_DEFINITION_DETAIL =
  "get_fileset_batch_definition_detail";
export const GET_FILESET_BATCH_DEFINITION_DETAIL_ERROR =
  "get_fileset_batch_definition_detail_error";
export const GET_ACCOUNTS_ERROR = "get_accounts_error";
export const GET_PRODUCTS_ERROR = "get_products_error";
export const GET_USERS = "get_users";
export const GET_USERS_ERROR = "get_users_error";
export const ADD_AFFILIATES_TO_USERS = "add_affiliates_to_users";
export const ADD_AFFILIATE_TO_USER = "add_affiliate_to_user";
export const ADD_AFFILIATE_TO_USER_ERROR = "add_affiliate_to_user_error";
export const REMOVE_AFFILIATE_FROM_USER = "remove_affiliate_from_user";
export const REMOVE_AFFILIATE_FROM_USER_ERROR =
  "remove_affiliate_from_user_error";
export const ADD_AFFILIATES_TO_USERS_ERROR = "add_affiliates_to_users_error";
export const GET_FILESET_BATCHES_SUMMARY = "get_fileset_batches_summary";
export const GET_FILESET_BATCHES_SUMMARY_ERROR =
  "get_filest_batches_summary_error";
export const GET_LOGGED_IN_USER = "get_logged_in_user";
export const GET_LOGGED_IN_USER_ERROR = "get_logged_in_user_error";
export const GET_FILE_SET_STATUS = "get_processor_status";
export const GET_FILE_SET_STATUS_ERROR = "get_processor_status_error";
export const GET_AFFILIATE_PRODUCT_ACCOUNTS = "get_affiliate_product_accounts";
export const GET_AFFILIATE_PRODUCT_ACCOUNTS_ERROR =
  "get_affiliate_product_accounts_error";
export const GET_AFFILIATE_PROCESSOR_ACCOUNTS =
  "get_affiliate_procesor_accounts";
export const GET_AFFILIATE_PROCESSOR_ACCOUNTS_ERROR =
  "get_affiliate_processor_accounts_error";
export const UPDATE_AFFILIATE_PRODUCT_ACCOUNT =
  "update_affiliate_product_account";
export const UPDATE_AFFILIATE_PRODUCT_ACCOUNT_ERROR =
  "update_affiliate_account_error";
export const UPDATE_AFFILIATE_PROCESSOR_ACCOUNT =
  "update_affiliate_processor_account";
export const UPDATE_AFFILIATE_PROCESSOR_ACCOUNT_ERROR =
  "update_affiliate_processor_account_error";
export const UPDATE_AFFILIATE_SRE = "update_affiliate_sre";
export const UPDATE_AFFILIATE_SRE_ERROR = "update_affiliate_sre_error";
export const GET_SCHEME_STANDINGS = "get_scheme_standings";
export const GET_SCHEME_STANDINGS_ERROR = "get_scheme_standings_error";
export const GET_GIM_POSTING_SUMMARIES = "get_gim_posting_summaries";
export const GET_GIM_POSTING_SUMMARIES_ERROR =
  "get_gim_posting_summaries_error";
export const GET_GIM_SUMMARY_DETAIL = "get_gim_summary_detail";
export const GET_GIM_SUMMARY_DETAIL_ERROR = "get_gim_summary_detail_error";
export const GET_GIM_POSTING_DETAIL = "get_gim_posting_detail";
export const GET_GIM_POSTING_DETAIL_ERROR = "get_gim_posting_detail_error";
export const GET_GIM_DETAIL_FILES = "get_gim_detail_files";
export const GET_GIM_DETAIL_FILES_ERROR = "get_gim_detail_files_error";
export const GET_GIM_FILE_CONTENT = "get_gim_file_content";
export const GET_GIM_FILE_CONTENT_ERROR = "get_gim_file_content_error";
export const GET_GIM_FILE_SUMMARY = "get_gim_file_summary";
export const GET_GIM_FILE_SUMMARY_ERROR = "get_gim_file_summary_error";
export const GET_T112S = "get_t112s";
export const GET_T112S_ERROR = "get_t112s_error";
export const GET_T464S = "get_all_t464s";
export const GET_T464S_ERROR = "get_t464s_error";
export const GET_ADVISEMENT = "get_advisement";
export const GET_ADVISEMENT_ERROR = "get_advisement_error";
export const GET_ADVISEMENT_DETAIL = "get_advisement_detail";
export const GET_ADVISEMENT_DETAIL_ERROR = "get_advisement_detail_error";
export const GET_MASTERCARD_T112_TRANSACTIONS =
  "get_mastercard_t112_transactions";
export const GET_MASTERCARD_T112_TRANSACTIONS_ERROR =
  "get_mastercard_t112_transactions_error";
export const GET_T140S = "get_140s";
export const GET_T140S_ERROR = "get_t140s_error";
export const GET_T140S_TRANSACTIONS = "get_t140_transactions";
export const GET_T140S_TRANSACTIONS_LOADING = "get_t140_transactions_loading";
export const GET_T140S_TRANSACTIONS_ERROR = "get_t140_transactions_error";
export const GET_T464_TRANSACTIONS = "get_t464_transactions";
export const GET_T464_TRANSACTIONS_LOADING = "get_t464_transactions_loading";
export const GET_T464_TRANSACTIONS_ERROR = "get_t464_transactions_error";
export const GET_T112_TRANSACTIONS = "get_t112_transactions";
export const GET_T112_TRANSACTIONS_LOADING = "get_t112_transactions_loading";
export const GET_T112_TRANSACTIONS_ERROR = "get_t112_transactions_error";
export const GET_T112_SUMMARY = "get_t112_summary";
export const GET_T112_SUMMARY_LOADING = "get_t112_summary_loading";
export const GET_T112_SUMMARY_ERROR = "get_t112_summary_error";
export const GET_T112_SUMMARY_STATS = "get_t112_summary_stats";
export const GET_T112_SUMMARY_STATS_ERROR = "get_t112_summary_stats_error";
export const GET_T464_SUMMARY = "get_t464_summary";
export const GET_T464_SUMMARY_LOADING = "get_t464_summary_loading";
export const GET_T464_SUMMARY_ERROR = "get_t464_summary_error";
export const GET_T464_SUMMARY_STATS = "get_t464_summary_stats";
export const GET_t464_SUMMARY_STATS_ERROR = "get_t464_summary_stats_error";
export const GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM =
  "get_mastercard_posaquirers_summary_item";
export const GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM_ERROR =
  "get_mastercard_posaquirers_summary_item_error";
export const GET_USER_AFFILIATES = "get_user_affiliates";
// Created this action type to enable seperation of convern between normal affiliates and logged in user affiliates
export const GET_LOGGED_IN_USER_AFFILIATES = "get_logged_in_user_affiliates";
export const GET_LOGGED_IN_USER_AFFILIATES_WITH_ALL =
  "get_logged_in_user_affiliates_with_all";
export const GET_USER_AFFILIATES_ERROR = "get_user_affiliates_error";
export const GET_T461S = "get_t461s";
export const GET_T461_TRANSACTIONS_LOADING = "get_t461_transactions_loading";
export const GET_T461S_ERROR = "get_t461s_error";
export const GET_T461_TRANSACTIONS = "get_t461_transactions";
export const GET_T461_TRANSACTIONS_ERROR = "get_t461_transactions_error";
export const MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS =
  "mastercard_posacquirers_csv_downloading_status";
export const GET_INTERAFILIATE_ISSUERS = "get_interafiliate_issuers";
export const GET_INTERAFILIATE_ISSUERS_ERROR =
  "get_interaffiliateissuers_error";
export const GET_INTERAFILIATE_ACQUIRER = "get_interafiliate_acquirer";
export const GET_INTERAFILIATE_ACQUIRER_ERROR =
  "get_interafiliate_acquirer_error";
export const GET_ACQUIRER_TRANSACTIONS = "get_acquirer_transactions";
export const GET_ACQUIRER_TRANSACTIONS_ERROR =
  "get_acquirer_transactions_error";
export const GET_ISSUER_TRANSACTIONS = "get_issuer_transactions";
export const GET_ISSUER_TRANSACTIONS_ERROR = "get_issuer_transactions_error";
export const GET_VISA_ACQUIRER = "get_visa_acquirer";
export const GET_VISA_ACQUIRER_ERROR = "get_visa_acquirer_error";
export const GET_VISA_ISSUER = "get_visa_issuer";
export const GET_VISA_ISSUER_ERROR = "get_visa_issuer_error";
export const GET_VISA_OCT = "get_visa_oct";
export const GET_VISA_OCT_ERROR = "get_visa_oct_error";
export const GET_VISA_ACQUIRER_SUMMARY = "get_visa_acquirer_summary";
export const GET_VISA_ACQUIRER_SUMMARY_ERROR =
  "get_visa_acquirer_summary_error";
export const GET_VISA_ACQUIRER_SUMMARY_STATS =
  "get_visa_acquirer_summary_stats";
export const GET_VISA_ACQUIRER_SUMMARY_STATS_ERROR =
  "get_visa_acquirer_summary_stats_error";
export const GET_VISA_ISSUER_SUMMARY = "get_visa_issuer_summary";
export const GET_VISA_ISSUER_SUMMARY_ERROR = "get_visa_issuer_summary_error";
export const GET_VISA_ISSUER_SUMMARY_STATS = "get_visa_issuer_summary_stats";
export const GET_VISA_ISSUER_SUMMARY_STATS_ERROR =
  "get_visa_issuer_summary_stats_error";
export const GET_VISA_ISSUER_TRANSACTIONS = "get_visa_issuer_transactions";
export const GET_VISA_ISSUER_TRANSACTIONS_ERROR =
  "get_visa_issuer_transactions_error";
export const GET_VISA_ISSUER_TRANSACTIONS_REQUEST =
  "get_visa_issuer_transactions_request";
export const GET_VISA_ACQUIRER_TRANSACTIONS = "get_visa_acquirer_transactions";
export const GET_VISA_ACQUIRER_TRANSACTIONS_ERROR =
  "get_visa_acquirer_transactions_error";
export const GET_T464_POSTING_REPORT = "get_t464_posting_report";
export const GET_T464_POSTING_REPORT_ERROR = "get_t464_posting_report_error";
export const GET_T112_POSTING_REPORT = "get_t112_posting_report";
export const GET_T112_POSTING_REPORT_ERROR = "get_t112_posting_report_error";
export const GET_INTERAFFILIATE_ACQUIRER_SUMMARY =
  "get_interAffiliate_acquirer_summary";
export const GET_INTERAFFILIATE_ACQUIRER_SUMMARY_ERROR =
  "get_interAffiliate_acquirer_summary_error";
export const GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS =
  "get_interAffiliate_acquirer_summary_stats";
export const GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS_ERROR =
  "get_interAffiliate_acquirer_summary_stats_error";
export const GET_INTERAFFILIATE_ISSUER_SUMMARY_ERROR =
  "get_interAffiliate_issuer_summary_error";
export const GET_INTERAFFILIATE_ISSUER_SUMMARY =
  "get_interAffiliate_issuer_summary";
export const GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS =
  "get_interAffiliate_issuer_summary_stats";
export const GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS_ERROR =
  "get_interAffiliate_issuer_summary_stats_error";
export const GET_INTERAFFILIATE_POSTING_SUMMARY =
  "get_interAffiliate_posting_summary";
export const GET_INTERAFFILIATE_POSTING_SUMMARY_ERROR =
  "get_interAffiliate_posting_summary_error";
export const GET_INTERAFFILIATE_SUMMARY = "get_interAffiliate_summary";
export const GET_INTERAFFILIATE_SUMMARY_ERROR =
  "get_interAffiliate_summary_error";
export const GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS =
  "get_interAffiliate_transactions";
export const GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS_ERROR =
  "get_interAffiliate_transactions_error";
export const GET_INTERAFFILIATE_ISSUER_REPORT =
  "get_interAffiliate_issuer_report";
export const GET_INTERAFFILIATE_ISSUER_REPORT_ERROR =
  "get_interAffiliate_issuer_report_error";
export const GET_INTERAFFILIATE_ACQUIRER_REPORT =
  "get_interAffiliate_acquirer_report";
export const GET_INTERAFFILIATE_ACQUIRER_REPORT_ERROR =
  "get_interAffiliate_acquirer_report_error";
export const GET_INTERAFFILIATE_POSTING_REPORT =
  "get_interAffiliate_posting_report";
export const GET_INTERAFFILIATE_POSTING_REPORT_ERROR =
  "get_interAffiliate_posting_report";
export const GET_VISA_ISSUER_REPORT = "get_visa_issuer_report";
export const GET_VISA_ISSUER_REPORT_ERROR = "get_visa_issuer_report_error";
export const GET_VISA_ACQUIRER_REPORT = "get_visa_acquirer_report";
export const GET_VISA_ACQUIRER_REPORT_ERROR = "get_visa_acquirer_report_error";
export const GET_VISA_REPORT = "get_visa_report";
export const GET_VISA_REPORT_ERROR = "get_visa_report_error";
export const GET_VISA_SUMMARY = "get_visa_summary";
export const GET_VISA_SUMMARY_ERROR = "get_visa_summary_error";
export const GET_VISA_POSTING_SUMMARY = "get_visa_posting_summary";
export const GET_VISA_POSTING_SUMMARY_ERROR = "get_visa_posting_summary_error";
export const GET_VISA_POSTING_SUMMARY_LOADING =
  "get_visa_posting_summary_loading";
export const GET_INTERSWITCH_COMPUTATIONS = "get_interswitch_daily_computation";
export const GET_INTERSWITCH_COMPUTATIONS_ERROR =
  "get_interswitch_daily_computation_error";
export const GET_INTERSWITCH_DAILY_COMPUTATION_SUMMARY =
  "get_interswitch_daily_computation";
export const GET_INTERSWITCH_DAILY_COMPUTATION_SUMMARY_ERROR =
  "get_interswitch_daily_computation_error";
export const GET_DAILY_INTERSWITCH_SUMMARY = "get_daily_interswitch_summary";
export const GET_DAILY_INTERSWITCH_SUMMARY_ERROR =
  "get_daily_interswitch_summary_error";
export const GET_ALL_VSS = "get_all_vss";
export const GET_ALL_VSS_ERROR = "get_all_vss_error";
export const GET_VSS_110 = "get_vss_110";
export const GET_VSS_110_ERROR = "get_vss_110_error";
export const GET_VSS_110_SUMMARY = "get_vss_110_summary";
export const GET_VSS_110_SUMMARY_ERROR = "get_vss_110_summary_error";
export const GET_VSS_115 = "get_vss_115";
export const GET_VSS_115_ERROR = "get_vss_115_error";
export const GET_VSS_115_DETAILS = "get_vss_details";
export const GET_VSS_115_SUMMARY = "get_vss_115_summary";
export const GET_VSS_115_SUMMARY_ERROR = " get_vss_115_summary_error";
export const GET_VSS_116 = "get_vss_116";
export const GET_VSS_116_ERROR = "get_vss_116_error";
export const GET_VSS_116_SUMMARY = "get_vss_116_summary";
export const GET_VSS_116_SUMMARY_ERROR = "get_vss_116_summary_error";
export const GET_VSS_120 = "get_vss_120";
export const GET_VSS_120_ERROR = "get_vss_120_error";
export const GET_VSS_120_SUMMARY = "get_vss_120_summary";
export const GET_VSS_120_SUMMARY_ERROR = "get_vss_120_summary_error";
export const GET_VSS_300 = "get_vss_300";
export const GET_VSS_300_ERROR = "get_vss_300_error";
export const GET_VSS_300_SUMMARY = "get_vss_300_summary";
export const GET_VSS_300_SUMMARY_ERROR = "get_vss_300_summary_error";
export const GET_VSS_900 = "get_vss_900";
export const GET_VSS_900_ERROR = "get_vss_900_error";
export const GET_VSS_900_SUMMARY = "get_vss_900_summary";
export const GET_VSS_900_SUMMARY_ERROR = "get_vss_900_summary_error";
export const GET_DAILY_POSTING_SUMMARY = "get_daily_posting_summary";
export const GET_DAILY_POSTING_SUMMARY_ERROR =
  "get_daily_posting_summary_error";
export const GET_ALL_POS_DETAILS = "get_all_pos_details";
export const GET_ALL_POS_DETAILS_ERROR = "get_all_pos_details_error";
export const GET_VISA_POSTING_OVERVIEW = "get_visa_posting_overview";
export const GET_VISA_POSTING_OVERVIEW_ERROR =
  "get_visa_posting_overview_error";
export const GET_VISA_POSTING_OVERVIEW_LOADING =
  "get_visa_posting_overview_loading";
export const GET_INTERAFFILIATE_POSTING_OVERVIEW =
  "get_interAffiliate_posting_overview";
export const GET_INTERAFFILIATE_POSTING_OVERVIEW_ERROR =
  "get_interAffiliate_posting_overview_error";
export const GET_POS_DETAILS_BY_MERCHANT_ID = "get_pos_details_by_merchant_id";
export const GET_POS_DETAILS_BY_MERCHANT_ID_ERROR =
  "get_pos_details_by_merchant_id_error";
export const SEND_SELECTED_AFFILIATE_CODE = "send_selected_affiliate_code";
export const DOWNLOAD_STATUS = "download_status";
export const GET_VISA_ISSUER_TRANSACTIONS_LOADING =
  "get_visa_issuer_transactions_loading";
export const DOWNLOADING_STATUS = "downloading_status";
export const GETTING_CURRENT_USER_AFFILIATES =
  "getting_current_user_affiliates";
export const FX_HISTORY = "fx_history";
export const FX_HISTORY_ERROR = "fx_history_error";
export const FX_HISTORY_UPDATE = "fx_history_update";
export const FX_HISTORY_UPDATE_SETTLEMENT = "fx_history_update_settlement";
export const FX_HISTORY_UPDATE_ERROR = "fx_history_update_error";
export const DELETE_FX_HISTORY = "delete_fx_history";
export const DELETE_FX_HISTORY_ERROR = "delete_fx_history_error";
export const MANUAL_RESOLUTION = "manual_resolution";
export const VISA_ISSUER_TRANSACTIONS_UPDATE =
  "visa_issuer_transactions_update";
export const VISA_ACQUIRER_TRANSACTIONS_UPDATE =
  "visa_acquirer_transactions_update";
export const GET_VISA_ACQUIRER_TRANSACTIONS_LOADING =
  "get_visa_acquirer_transactions_loading";
export const GET_VISA_OCT_TRANSACTIONS = "get_visa_oct_transactions_loading";
export const GET_VISA_OCT_TRANSACTIONS_ERROR =
  "get_visa_oct_transactions_loading";
export const GET_VISA_OCT_TRANSACTIONS_LOADING =
  "get_visa_oct_transactions_loading";
export const VISA_ACQUIRER_TRANSACTIONS_UPDATE_ERROR =
  "visa_acquirer_transactions_update_error";
export const GET_POSTILION_SUMMARY = "get_postilion_summary";
export const GET_POSTILION_SUMMARY_ERROR = "get_postilion_summary_error";
export const GET_FLEXCUBE_SUMMARY = "get_flexcube_summary";
export const GET_FLEXCUBE_SUMMARY_ERROR = "get_flexcube_summary_error";
export const DELETE_POSTILION_CONTENT = "delete_postilion_content";
export const DELETE_POSTILION_CONTENT_ERROR = "delete_postilion_content_error";
export const DIS_MISS_ERROR = "dis_miss_error";
export const DELETE_FLEXCUBE_CONTENT = "delete_flex_cube_content";
export const DELETE_FLEXCUBE_CONTENT_ERROR = "delete_flexcube_content_error";
export const DELETE_POS_CONTENT = "delete_pos_content";
export const DELETE_POS_CONTENT_ERROR = "delete_pos_content_error";
export const GET_POS_ACQUIRERS_SUMMARY = "get_pos_acquirers_summary";
export const GET_POS_ACQUIRERS_SUMMARY_ERROR =
  "get_pos_acquirers_summary_error";
export const GET_POSTILION_SUMMARY_ITEM = "get_postilion_summary_item";
export const GET_POSTILION_SUMMARY_ITEM_ERROR =
  "get_postilion_summary_item_error";
export const GET_FLEXCUBE_SUMMARY_ITEM = "get_flexcube_summary_item";
export const GET_FLEXCUBE_SUMMARY_ITEM_ERROR =
  "get_flexcube_summary_item_error";
export const GET_POSACQUIRERS_SUMMARY_ITEM = "get_posacquirers_summary_item";
export const GET_POSACQUIRERS_SUMMARY_ITEM_ERROR =
  "get_posacquirers_summary_item_error";
export const GET_POSTILION_SUMMARY_SEARCH = "get_postilion_summary_search";
export const GET_FLEXCUBE_SUMMARY_SEARCH = "get_flexcube_summary_search";
export const GET_POS_ACQUIRERS_SUMMARY_SEARCH =
  "get_posacquirers_summary_search";
export const GET_POSTILION_SUMMARY_SEARCH_ERROR =
  "get_postilion_summary_search_error";
export const GET_FLEXCUBE_SUMMARY_SEARCH_ERROR =
  "get_flexcube_summary_search_error";
export const FILE_UPLOAD = "file_upload";
export const FILE_UPLOAD_ERROR = "file_upload_error";
export const REFRESH = "refresh";
export const DELETE_CHANNEL_LOG_ITEM = "delete_change_log_item";
export const DELETE_CHANNEL_LOG_ITEM_ERROR = "delete_channel_log_item_error";
export const UPLOADING = "uploading";
export const EXT_USER_MGT_URL = "ext_user_mgt_url";
export const VISA_ISSUER_POSTING_REPORT_SUMMARY =
  "visa_issuer_posting_report_summary";
export const VISA_ISSUER_POSTING_REPORT_SUMMARY_ERROR =
  "visa_issuer_posting_report_summary_error";
export const VISA_ACQUIRER_POSTING_REPORT_SUMMARY =
  "visa_acquirer_posting_report_summary";
export const VISA_ACQUIRER_POSTING_REPORT_SUMMARY_ERROR =
  "visa_acquirer_posting_report_summary_error";
export const VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES =
  "visa_issuer_posting_summary_validated_entries";
export const VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR =
  "visa_issuer_posting_summary_validated_entries_error";
export const VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES =
  "visa_acquirer_posting_summary_validated_entries";
export const VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR =
  "visa_acquirer_posting_summary_validated_entries_error";
export const ALL_POSTING_REPORT_SUMMARY = "all_posting_summary_report";
export const ALL_POSTING_REPORT_SUMMARY_ERROR =
  "all_posting_summary_report_error";
export const DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM =
  "delete_visa_acquirer_posting_summary_item";
export const DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM_ERROR =
  "delete_visa_acquirer_posting_summary_item_error";
export const DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM =
  "delete_visa_issuer_posting_summary_item";
export const DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM_ERROR =
  "delete_visa_issuer_posting_summary_item_error";
export const DELETE_VISA_POSTING_SUMMARY_ITEM =
  "delete_visa_posting_summary_item";
export const DELETE_VISA_POSTING_SUMMARY_ITEM_ERROR =
  "delete_visa_posting_summary_item_error";
export const CONFIRM_DELETE = "confirm_delete";
export const CANCEL_DELETE = "cancel_delete";
export const OPEN_MODAL = "open_modal";
export const CLOSE_MODAL = "close_modal";
export const GET_ADVISEMENT_SUMMARY = "get_advisement_summary";
export const GET_ADVISEMENT_SUMMARY_ERROR = "get_advisement_summary_error";
export const GET_INTERAFFILIATE_POSTOFFICE_SUMMARY =
  "get_interaffiliate_postoffice_summary";
export const GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_ERROR =
  "get_interaffiliate_postoffice_summary_error";
export const GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL =
  "get_interaffiliate_postoffice_summary_detail";
export const GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL_ERROR =
  "get_interaffiliate_postoffice_summary_detail_error";
export const GET_INTERAFFILIATE_SOURCE_SUMMARY =
  "get_interaffiliate_source_summary";
export const GET_INTERAFFILIATE_SOURCE_SUMMARY_ERROR =
  "get_interaffiliate_source_summary_error";
export const GET_INTERAFFILIATE_SOURCE_ITEM = "get_interaffiliate_source_item";
export const GET_INTERAFFILIATE_SOURCE_ITEM_ERROR =
  "get_interaffiliate_source_item_error";
export const GET_INTERAFFILIATE_GROSS_SETTLEMENT =
  "get_interaffilaite_gross_settlement";
export const GET_INTERAFFILIATE_GROSS_SETTLEMENT_ERROR =
  "get_interaffilaite_gross_settlement_error";
export const GET_INTERAFFILIATE_REGISTER_SETTLEMENT =
  "get_interaffilaite_register_settlement";
export const GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR =
  "get_interaffilaite_register_settlement_error";
export const FETCH_AFFILIATES_NAMES_AND_CODES =
  "fetch_affiliates_names_and_codes";
export const FETCH_AFFILIATES_NAMES_AND_CODES_ERROR =
  "fetch_allfiliates_names_and_codes";
export const GET_INTERAFFILIATE_SUMM_SETTLEMENT =
  "get_interaffiliate_summ_settlement";
export const GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR =
  "get_interaffiliate_summ_settlement_error";
export const REGION_CODES = "get_region_codes";
export const REGION_CODES_ERROR = "get_region_codes_error";
export const REGION_CODES_DELETE = "delete_region_codes";
export const REGION_CODES_DELETE_ERROR = "delete_region_codes_error";
export const ADD_REGION_CODES = "add_region_codes";
export const ADD_REGION_CODES_ERROR = "add_regions_codes_error";
export const GET_SETTLEMENT_BREAKDOWN = "get_settlement_breakdown";
export const GET_SETTLEMENT_BREAKDOWN_ERROR = "get_settlement_breakdown_error";
export const GET_SETTLEMENT_REPORT = "get_settlement_report";
export const GET_SETTLEMENT_REPORT_ERROR = "get_settlement_report_error";
export const GET_POSTING_REPORT = "get_posting_report";
export const GET_POSTING_REPORT_ERROR = "get_posting_report_error";
export const GET_AFFILIATE_POSTING_REPORT = "get_affiliate_posting_report";
export const GET_AFFILIATE_POSTING_REPORT_ERROR =
  "get_affiliate_posting_report_error";
export const GET_ALL_CURRENCY_CODES = "get_all_currency_codes";
export const GET_ALL_CURRENCY_CODES_ERROR = "get_all_currency_codes_error";
export const ADD_RATES = "add_rates";
export const ADD_RATES_ERROR = "add_rates_error";
export const OPEN_ALERT = "open_alert";
export const CLOSE_ALERT = "close_alert";
export const GET_AFFILIATE_ISSUER_SOURCE_ITEMS =
  "get_affiliate_issuer_source_items";
export const GET_AFFILIATE_ISSUER_SOURCE_ITEMS_ERROR =
  "get_affiliate_issuer_source_items_error";
export const GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS =
  "get_affiliate_acquirer_source_items";
export const GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS_ERROR =
  "get_affiliate_acquirer_source_items_error";
export const GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY =
  "get_interaffiliate_affiliate_posting_summary";
export const GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY_ERROR =
  "get_interaffiliate_affiliate_posting_summary_error";
export const GET_SUM_SETTLEMENT_FEES = "get_sum_settlment_fees";
export const GET_SUM_SETTLEMENT_FEES_ERROR = "get_sum_settlement_fees_error";
export const GET_AUTHORIZATION_TOKEN = "get_authorization_token";
export const GET_AUTHORIZATION_TOKEN_ERROR = "get_authorization_token_error";
export const GET_ISSUER_AUDIT = "get_issuer_audit";
export const GET_ISSUER_AUDIT_ERROR = "get_issuer_audit_error";
export const GET_ACQUIRER_AUDIT = "get_acquirer_audit";
export const GET_ACQUIRER_AUDIT_ERROR = "get_acquirer_audit_error";
export const GET_VISA_POSTING_DETAILS = "get_visa_posting_details";
export const GET_VISA_POSTING_DETAILS_ERROR = "get_visa_posting_details_error";
export const GET_INTERAFFILIATE_POSTING = "get_interaffiliate_posting";
export const GET_INTERAFFILIATE_POSTING_ERROR =
  "get_interaffiliate_posting_error";
export const MASTERCARD_POSTING = "mastercard_posting";
export const MASTERCARD_POSTING_ERROR = "mastercard_posting_error";
export const MASTERCARD_POSTING_DETAILS = "mastercard_posting_details";
export const MASTERCARD_POSTING_DETAILS_ERROR =
  "mastercard_posting_details_error";
export const GET_GENERIC_POSTING_RECORDS = "get_generic_posting_records";
export const GET_GENERIC_POSTING_RECORDS_ERROR =
  "get_generic_posting_records_error";
export const DOWNLOADING_ERROR = "downloading_error";
export const DELETE_POSTING_DETAILS = "delete_posting_details";
export const DELETE_POSTING_DETAILS_ERROR = "delete_posting_details_error";
export const FETCH_POSTING_ENTRY_META = "fetch_posting_entry_meta";
export const FETCH_POSTING_ENTRY_META_ERROR = "fetch_posting_entry_meta_error";
export const FETCH_AUDIT_LOGS = "fetch_audit_logs";
export const FETCH_AUDIT_LOGS_ERROR = "fetch_audit_logs_error";
export const DOWNLOAD_AUDIT_LOGS = "download_audit_logs";
export const DOWNLOAD_AUDIT_LOGS_ERROR = "download_audit_logs_error";
export const INTER_AFFILIATE_QUERY_DETAILS = "inter_affiliate_query_details";
export const INTER_AFFILIATE_QUERY_DETAILS_ERROR =
  "inter_affiliate_query_details_error";
export const GET_AUTO_POSTING_STATUS = "get_auto_posting_status";
export const GET_AUTO_POSTING_STATUS_ERROR = "get_auto_posting_status_error";
export const UPDATE_AUTO_POSTING_STATUS = "update_auto_posting_status";
export const UPDATE_AUTO_POSTING_STATUS_ERROR =
  "update_auto_posting_status_error";

export const INTER_AFFILIATE_FILENAME = "DJRFCYINE";
export const VISA_FILENAME = "DJRFCYVSE";
export const MASTERCARD_FILENAME = "DJRFCYMSE";
export const GIM_FILENAME = "DJRFCYGME";
export const MASTERPASS_FILENAME = "DJRFCYMPE";
export const XPRESSCASH_FILENAME = "DJRFCYXCE";
export const ECOMMERCE_FILENAME = "DJRFCYECE";
export const CUP_FILENAME = "DJRFCYCPE";
export const MVISA_FILENAME = "DJRFCYMVE";
export const NSA_FILENAME = "DJRFCYNSAE";
export const AUTHORIZATION_CHECK = "authorization_check";
export const INVALID_TOKEN = "invalid_token";
export const USER_MGT_URL_FRONTEND_URL = "user_mgt_url_frontend_url";
export const USER_MGT_URL_FRONTEND_URL_ERROR =
  "user_mgt_url_frontend_url_error";
export const BASE_URL = SAS_BACKEND_URL;
export const UPLOAD_URL = SAS_UPLOAD_URL;
export const FETCH_DB_COLLECTIONS = "fetch_db_collections";
export const FETCH_DB_COLLECTIONS_ERROR = "fetch_db_coolections_error";
export const CLEAR_COLLECTION_RECORD = "clear_collection_record";
export const CLEAR_COLLECTION_RECORD_ERROR = "clear_collection_record_error";
export const CLEAR_ALL_COLLECTIONS_ERROR = "clear_all_collections_error";
export const CLEAR_ALL_COLLECTIONS = "clear_all_collections";
export const CLEAR_COLLECTIONS_RESPONSE = "clear_collections_response";
export const FETCH_APACHE_CAMEL_DIRECTORIES = "fetch_apache_camel_directories";
export const FETCH_APACHE_CAMEL_DIRECTORIES_ERROR =
  "fetch_apache_camel_directories_error";
export const DELETE_APACHE_CAMEL_DIRECTORY = "delete_apache_camel_directory";
export const DELETE_APACHE_CAMEL_DIRECTORY_ERROR =
  "delete_apache_camel_directory_error";
export const CLEAR_CAMEL_DIRECTORY_RESPONSE = "clear_camel_directory_response";
export const ADD_NARRATION = "add_narration";
export const ADD_NARRATION_ERROR = "add_narration_error";
export const EDIT_NARRATION = "edit_narration";
export const EDIT_NARRATION_ERROR = "edit_narration_error";
export const GET_ALL_NARRATIONS = "get_all_narration";
export const GET_ALL_NARRATIONS_ERROR = "get_all_narration_error";
export const GET_A_NARRATION = "get_a_narration";
export const GET_A_NARRATION_ERROR = "get_a_narration_error";
export const CLEAR_NARRATION_RESPONSE = "clear_narration_response";
export const DELETE_INTERAFFILIATE_CHANNEL_LOG =
  "delete_interaffiliate_channel_log";
export const DELETE_INTERAFFILIATE_CHANNEL_LOG_ERROR =
  "delete_interaffiliate_channel_log_error";

export const GET_ALL_XPRESSCASH_SUMMARY_START =
  "get_all_xpresscash_summary_start";
export const GET_ALL_XPRESSCASH_SUMMARY_SUCCESS =
  "get_all_xpresscash_summary_success";
export const GET_ALL_XPRESSCASH_SUMMARY_FAILURE =
  "get_all_xpresscash_summary_failure";
export const GET_ALL_XPRESSCASH_DETAILS_START =
  "get_all_xpresscash_details_start";
export const GET_ALL_XPRESSCASH_DETAILS_SUCCESS =
  "get_all_xpresscash_details_success";
export const GET_ALL_XPRESSCASH_DETAILS_FAILURE =
  "get_all_xpresscash_details_failure";
export const GET_ALL_XPRESSCASH_ACQUIRER_START =
  "get_all_xpresscash_acquirer_start";
export const GET_ALL_XPRESSCASH_ACQUIRER_SUCCESS =
  "get_all_xpresscash_acquirer_success";
export const GET_ALL_XPRESSCASH_ACQUIRER_FAILURE =
  "get_all_xpresscash_acquirer_failure";
export const GET_ALL_XPRESSCASH_ISSUER_START =
  "get_all_xpresscash_issuer_start";
export const GET_ALL_XPRESSCASH_ISSUER_SUCCESS =
  "get_all_xpresscash_issuer_success";
export const GET_ALL_XPRESSCASH_ISSUER_FAILURE =
  "get_all_xpresscash_issuer_failure";
export const GET_XPRESSCASH_SETTLEMENT_REPORT_START =
  "get_xpresscash_settlement_report_start";
export const GET_XPRESSCASH_SETTLEMENT_REPORT_SUCCESS =
  "get_xpresscash_settlement_report_success";
export const GET_XPRESSCASH_SETTLEMENT_REPORT_FAILURE =
  "get_xpresscash_settlement_report_failure";
export const GET_XPRESSCASH_POSTING_REPORT_START =
  "get_xpresscash_posting_report_start";
export const GET_XPRESSCASH_POSTING_REPORT_SUCCESS =
  "get_xpresscash_posting_report_success";
export const GET_XPRESSCASH_POSTING_REPORT_FAILURE =
  "get_xpresscash_posting_report_failure";
export const GET_XPRESSCASH_FEES_REPORT_START =
  "get_xpresscash_fees_report_start";
export const GET_XPRESSCASH_FEES_REPORT_SUCCESS =
  "get_xpresscash_fees_report_success";
export const GET_XPRESSCASH_FEES_REPORT_FAILURE =
  "get_xpresscash_fees_report_failure";
export const GET_XPRESSCASH_FEES_SUMM_START = "get_xpresscash_fees_summ_start";
export const GET_XPRESSCASH_FEES_SUMM_SUCCESS =
  "get_xpresscash_fees_summ_success";
export const GET_XPRESSCASH_FEES_SUMM_FAILURE =
  "get_xpresscash_fees_summ_failure";
export const GET_ALL_XPRESSCASH_QUERY_DETAILS_START =
  "get_all_xpresscash_query_details_start";
export const GET_ALL_XPRESSCASH_QUERY_DETAILS_SUCCESS =
  "get_all_xpresscash_query_details_success";
export const GET_ALL_XPRESSCASH_QUERY_DETAILS_FAILURE =
  "get_all_xpresscash_query_details_failure";
export const GET_MVISA_ISSUER_REPORT_START = "get_mvisa_issuer_report_start";
export const GET_MVISA_ISSUER_REPORT_SUCCESS =
  "get_mvisa_issuer_report_success";
export const GET_MVISA_ISSUER_REPORT_FAILURE =
  "get_mvisa_issuer_report_failure";
export const GET_XPRESSCASH_AQUIRER_REPORT_START =
  "get_xpresscash_aquirer_report_start";
export const GET_XPRESSCASH_AQUIRER_REPORT_SUCCESS =
  "get_xpresscash_aquirer_report_success";
export const GET_XPRESSCASH_AQUIRER_REPORT_FAILURE =
  "get_xpresscash_aquirer_report_failure";
export const GET_XPRESSCASH_ISSUER_REPORT_START =
  "get_xpresscash_issuer_report_start";
export const GET_XPRESSCASH_ISSUER_REPORT_SUCCESS =
  "get_xpresscash_issuer_report_success";
export const GET_XPRESSCASH_ISSUER_REPORT_FAILURE =
  "get_xpresscash_issuer_report_failure";
export const GET_ALL_XPRESSCASH_SINGLE_SUMMARY_START =
  "get_all_xpresscash_single_summary_start";
export const GET_ALL_XPRESSCASH_SINGLE_SUMMARY_SUCCESS =
  "get_all_xpresscash_single_summary_success";
export const GET_ALL_XPRESSCASH_SINGLE_SUMMARY_FAILURE =
  "get_all_xpresscah_single_summary_failure";
export const GET_MVISA_ACQUIRER_REPORT_START =
  "get_mvisa_acquirer_report_start";
export const GET_MVISA_ACQUIRER_REPORT_SUCCESS =
  "get_mvisa_acquirer_report_success";
export const GET_MVISA_ACQUIRER_REPORT_FAILURE =
  "get_mvisa_acquirer_report_failure";
export const GET_ALL_MVISA_QUERY_DETAILS_START =
  "get_all_mvisa_query_details_start";
export const GET_ALL_MVISA_QUERY_DETAILS_SUCCESS =
  "get_all_mvisa_query_details_success";
export const GET_ALL_MVISA_QUERY_DETAILS_FAILURE =
  "get_all_mvisa_query_details_failure";
export const GET_ALL_MVISA_ACQUIRER_START = "get_all_mvisa_acquirer_start";
export const GET_ALL_MVISA_ACQUIRER_SUCCESS = "get_all_mvisa_acquirer_success";
export const GET_ALL_MVISA_ACQUIRER_FAILURE = "get_all_mvisa_acquirer_failure";
export const GET_ALL_MVISA_DETAILS_START = "get_all_mvisa_details_start";
export const GET_ALL_MVISA_DETAILS_SUCCESS = "get_all_mvisa_details_success";
export const GET_ALL_MVISA_DETAILS_FAILURE = "get_all_mvisa_details_failure";
export const GET_ALL_MVISA_ISSUER_START = "get_all_mvisa_issuer_start";
export const GET_ALL_MVISA_ISSUER_SUCCESS = "get_all_mvisa_issuer_success";
export const GET_ALL_MVISA_ISSUER_FAILURE = "get_all_mvisa_issuer_failure";
export const GET_ALL_MVISA_SINGLE_SUMMARY_START =
  "get_all_mvisa_single_summary_start";
export const GET_ALL_MVISA_SINGLE_SUMMARY_SUCCESS =
  "get_all_mvisa_single_summary_success";
export const GET_ALL_MVISA_SINGLE_SUMMARY_FAILURE =
  "get_all_mvisa_single_summary_failure";
export const GET_ALL_MVISA_SUMMARY_START = "get_all_mvisa_summary_start";
export const GET_ALL_MVISA_SUMMARY_SUCCESS = "get_all_mvisa_summary_success";
export const GET_ALL_MVISA_SUMMARY_FAILURE = "get_all_mvisa_summary_failure";
export const GET_MVISA_FEES_REPORT_START = "get_mvisa_fees_report_start";
export const GET_MVISA_FEES_REPORT_SUCCESS = "get_mvisa_fees_report_success";
export const GET_MVISA_FEES_REPORT_FAILURE = "get_mvisa_fees_report_failure";
export const GET_MVISA_POSTING_REPORT_START = "get_mvisa_posting_report_start";
export const GET_MVISA_POSTING_REPORT_SUCCESS =
  "get_mvisa_posting_report_success";
export const GET_MVISA_POSTING_REPORT_FAILURE =
  "get_mvisa_posting_report_failure";
export const GET_MVISA_SUM_REPORT_START = "get_mvisa_sum_report_start";
export const GET_MVISA_SUM_REPORT_SUCCESS = "get_mvisa_sum_report_success";
export const GET_MVISA_SUM_REPORT_FAILURE = "get_mvisa_sum_report_failure";
export const GET_XPRESSCASH_MVISA_REPORT_START =
  "get_xpresscash_mvisa_report_start";
export const GET_XPRESSCASH_MVISA_REPORT_SUCCESS =
  "get_xpresscash_mvisa_report_succeSS";
export const GET_XPRESSCASH_MVISA_REPORT_FAILURE =
  "get_xpresscash_mvisa_report_failure";
export const INTER_AFFILIATE_DOWNLOAD_FILENAME = "DJRFCYNS";
export const DOWNLOAD_FILE_TYPES = { csv: "csv", text: "txt" };
export const GET_QUERY_DETAILS = "get_query_details";
export const GET_QUERY_DETAILS_ERROR =
  "get_query_details_error";
export const GET_CHINAPAY_ACQUIRER_FILE_SUMMARY =
  "get_chinapay_acquirer_file_summary";
export const GET_CHINAPAY_ACQUIRER_FILE_SUMMARY_ERROR =
  "get_chinapay_acquirer_file_summary_error";
export const GET_CHINAPAY_ACQUIRER_FILE_DETAILS =
  "get_chinapay_acquirer_file_details";
export const GET_CHINAPAY_ACQUIRER_FILE_DETAILS_ERROR =
  "get_chinapay_acquirer_file_details_error";
export const GET_CHINAPAY_SUMMARY = "get_chinapay_summary";
export const GET_CHINAPAY_SUMMARY_ERROR = "get_chinapay_summary_error";
export const GET_CHINAPAY_SUMMARY_DETAILS = "get_chinapay_summary_details";
export const GET_CHINAPAY_SUMMARY_DETAILS_ERROR =
  "get_chinapay_summary_details_error";
export const GET_CHINAPAY_POSTING_SUMMARY = "get_chinapay_posting_summary";
export const GET_CHINAPAY_POSTING_SUMMARY_ERROR =
  "get_chinapay_posting_summary_error";
export const GET_CHINAPAY_POSTING_DETAILS = "get_chinapay_posting_details";
export const GET_CHINAPAY_POSTING_DETAILS_ERROR =
  "get_chinapay_posting_details_error";
export const GET_CHINAPAY_POSTING_ENTRIES = "get_chinapay_posting_entries";
export const GET_CHINAPAY_POSTING_ENTRIES_ERROR =
  "get_chinapay_posting_entries_error";
export const CHINAPAY_LOADING = "get_chinapay_loading";
// MASTER PASS
export const MASTERPASS_LOADING = "masterpass_loading";
export const GET_MASTERPASS_SUMMARIES = "get_masterpass_summaries";
export const GET_MASTERPASS_SUMMARIES_ERROR = "get_masterpass_summaries_error";
export const GET_MASTERPASS_DETAILS = "get_masterpass_details";
export const GET_MASTERPASS_DETAILS_ERROR = "get_masterpass_details_error";
export const GET_MASTERPASS_ACQUIRERS = "get_masterpass_acquirers";
export const GET_MASTERPASS_ACQUIRERS_ERROR = "get_masterpass_acquirers_error";
export const GET_MASTERPASS_ISSUERS = "get_masterpass_issuer";
export const GET_MASTERPASS_ISSUERS_ERROR = "get_masterpass_issuers_error";
export const GET_MASTERPASS_SUMMARY = "get_masterpass_summary";
export const GET_MASTERPASS_SUMMARY_ERROR = "get_masterpass_summary_error";
export const GET_MASTERPASS_SETTLEMENT_REPORT =
  "get_masterpass_settlement_report";
export const GET_MASTERPASS_SETTLEMENT_REPORT_ERROR =
  "get_masterpass_settlement_report_error";
export const GET_MASTERPASS_POSTING_REPORT = "get_masterpass_posting_report";
export const GET_MASTERPASS_POSTING_REPORT_ERROR =
  "get_masterpass_posting_report_error";
export const GET_MASTERPASS_SETTLEMENT_FEES_REPORT =
  "get_masterpass_settlement_fees_report";
export const GET_MASTERPASS_SETTLEMENT_FEES_REPORT_ERROR =
  "get_masterpass_settlement_fees_report_error";
export const GET_MASTERPASS_SUMM_SETTLEMENT_REPORT =
  "get_masterpass_summ_settlement_report";
export const GET_MASTERPASS_SUMM_SETTLEMENT_REPORT_ERROR =
  "get_masterpass_summ_settlement_report_error";
export const GET_MASTERPASS_ACQUIRER_REPORT = "get_masterpass_acquirer_report";
export const GET_MASTERPASS_ACQUIRER_REPORT_ERROR =
  "get_masterpass_acquirer_report_error";
export const GET_MASTERPASS_ISSUER_REPORT = "get_masterpass_issuer_report";
export const GET_MASTERPASS_ISSUER_REPORT_ERROR =
  "get_masterpass_issuer_report_error";
export const GET_MASTERPASS_QUERY_DETAILS = "get_masterpass_query_details";
export const GET_MASTERPASS_QUERY_DETAILS_ERROR =
  "get_masterpass_query_details_error";

// Ecommerce
export const FETCH_ALL_ECOMMERCE_SCHEME = "fetch_all_ecommerce_scheme";
export const FETCH_ALL_ECOMMERCE_SCHEME_ERROR =
  "fetch_all_ecommerce_scheme_error";
export const ECOMMERCE_DETAILS = "ecommerce_details";
export const ECOMMERCE_DETAILS_ERROR = "ecommrece_details_error";
export const ECOMMERCE_ISSUER_DETAILS = "ecommerce_issuer_details";
export const ECOMMERCE_ISSUER_DETAILS_ERROR = "ecommerce_issuer_details_error";
export const ECOMMERCE_ACQUIRER_DETAILS = "ecommerce_acquirer_details";
export const ECOMMERCE_ACQUIRER_DETAILS_ERROR =
  "ecommerce_acquirer_details_error";
export const GET_ECOMMERCE_SETTLEMENT_REPORT_START =
  "get_ecommerce_settlement_report_start";
export const GET_ECOMMERCE_SETTLEMENT_REPORT_SUCCESS =
  "get_ecommerce_settlement_report_success";
export const GET_ECOMMERCE_SETTLEMENT_REPORT_FAILURE =
  "get_ecommerce_settlement_report_failure";
export const GET_ECOMMERCE_POSTING_REPORT_START =
  "get_ecommerce_posting_report_start";
export const GET_ECOMMERCE_POSTING_REPORT_SUCCESS =
  "get_ecommerce_posting_report_success";
export const GET_ECOMMERCE_POSTING_REPORT_FAILURE =
  "get_ecommerce_posting_report_failure";
export const GET_ECOMMERCE_FEES_REPORT_START =
  "get_ecommerce_fees_report_start";
export const GET_ECOMMERCE_FEES_REPORT_SUCCESS =
  "get_ecommerce_fees_report_success";
export const GET_ECOMMERCE_FEES_REPORT_FAILURE =
  "get_ecommerce_fees_report_failure";
export const GET_ECOMMERCE_SUMM_REPORT_START =
  "get_ecommerce_summ_report_start";
export const GET_ECOMMERCE_SUMM_REPORT_SUCCESS =
  "get_ecommerce_summ_report_success";
export const GET_ECOMMERCE_SUMM_REPORT_FAILURE =
  "get_ecommerce_summ_report_failure";
export const GET_ECOMMERCE_AQUIRER_REPORT_START =
  "get_ecommerce_aquirer_report_start";
export const GET_ECOMMERCE_AQUIRER_REPORT_SUCCESS =
  "get_ecommerce_aquirer_report_success";
export const GET_ECOMMERCE_AQUIRER_REPORT_FAILURE =
  "get_ecommerce_aquirer_report_failure";
export const GET_ECOMMERCE_ISSUER_REPORT_START =
  "get_ecommerce_issuer_report_start";
export const GET_ECOMMERCE_ISSUER_REPORT_SUCCESS =
  "get_ecommerce_issuer_report_success";
export const GET_ECOMMERCE_ISSUER_REPORT_FAILURE =
  "get_ecommerce_issuer_report_failure";
export const GET_ALL_ECOMMERCE_SINGLE_SUMMARY_START =
  "get_all_ecommerce_single_summary_start";
export const GET_ALL_ECOMMERCE_SINGLE_SUMMARY_SUCCESS =
  "get_all_ecommerce_single_summary_success";
export const GET_ALL_ECOMMERCE_SINGLE_SUMMARY_FAILURE =
  "get_all_ecommerce_single_summary_failure";
export const GET_ALL_ECOMMERCE_QUERY_DETAILS_START =
  "get_all_ecommerce_query_details_start";
export const GET_ALL_ECOMMERCE_QUERY_DETAILS_SUCCESS =
  "get_all_ecommerce_query_details_success";
export const GET_ALL_ECOMMERCE_QUERY_DETAILS_FAILURE =
  "get_all_ecommerce_query_details_failure";
// Posting details
export const DELETE_POSTING_LOG_ID_AND_RECORDS =
  "delete_posting_log_id_and_records";
export const DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR =
  "delete_posting_log_id_and_records_error";
export const DELETE_CHANNEL_LOG = "delete_channel_log";
export const DELETE_CHANNEL_LOG_ERROR = "delete_channel_log_error";
export const ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE =
  "add_settlement_currency_to_affiliate";
export const ADD_SETTLEMENT_CURRENCY_TO_AFFILIATE_ERROR =
  "add_settlement_currency_to_affiliate_error";
export const CLEAR_ADD_SETTLEMENT_CURRENCY_MESSAGES =
  "clear_add_settlement_currency_messages";
export const CLEAR_VISA_ALERT_MESSAGES = "clear_visa_alert_messages";
export const GET_NOSTRO_REPORTS_LOADING = "get_nostro_reports_loading";
export const GET_NOSTRO_REPORTS = "get_nostro_reports";
export const GET_NOSTRO_REPORTS_ERROR = "get_nostro_reports_error";
export const TRIGGER_POSTING = "trigger_posting";
export const TRIGGER_POSTING_ERROR = "trigger_posting_error";
export const RESET_TRIGGER_RESPONSE = "reset_trigger_response";

// POSTING GENERAL
export const APPROVE_AFFILIATE_SCHEME = "approve_affiliate_scheme";
export const APPROVE_AFFILIATE_SCHEME_SUCCESS =
  "approve_affiliate_scheme_success";
export const APPROVE_AFFILIATE_SCHEME_FAILURE =
  "approve_affiliate_scheme_failure";

export const DENY_AFFILIATE_SCHEME = "deny_affiliate_scheme";
export const DENY_AFFILIATE_SCHEME_SUCCESS = "deny_affiliate_scheme_success";
export const DENY_AFFILIATE_SCHEME_FAILURE = "deny_affiliate_scheme_failure";

export const GET_APPROVED_AFFILIATE_SCHEME = "get_approved_affiliate_scheme";
export const GET_APPROVED_AFFILIATE_SCHEME_SUCCESS =
  "get_approved_affiliate_scheme_success";
export const GET_APPROVED_AFFILIATE_SCHEME_FAILURE =
  "get_approved_affiliate_scheme_failure";

export const GET_REGISTERED_SCHEMES = "get_registered_schemes";
export const GET_REGISTERED_SCHEMES_SUCCESS = "get_registered_schemes_success";
export const GET_REGISTERED_SCHEMES_FAILURE = "get_registered_schemes_failure";


export const GET_REPORT_SCHEMES = "get_report_schemes";
export const GET_REPORT_SCHEMES_SUCCESS = "get_report_schemes_success";
export const GET_REPORT_SCHEMES_FAILURE = "get_report_schemes_failure";

// BILLING RECORDS FOR ALL AFFILIATES
export const GET_BILLING_RECORDS = "get_billling_records";
export const GET_BILLING_RECORDS_SUCCESS = "get_billling_records_success";
export const GET_BILLING_RECORDS_FAILURE = "get_billling_records_failure";

export const DOWNLOAD_BILLING_RECORD = "download_billing_record";
export const DOWNLOAD_BILLING_RECORD_SUCCESS =
  "download_billing_record_success";
export const DOWNLOAD_BILLING_RECORD_FAILURE =
  "download_billing_record_failure";

// ADMIN REPORTS
export const GET_ADMIN_REPORTS = "get_admin_reports";
export const GET_ADMIN_REPORTS_ERROR = "get_admin_reports_error";

// POSTING TRANSACTION REPORTS
export const GET_POST_TRANSACTION_REPORT = "get_post_transaction_report";
export const GET_POST_TRANSACTION_REPORT_ERROR =
  "get_post_transaction_report_error";

export const GET_MASTERCARD_RESOLVED_FILES = "get_mastercard_resolved_files";
export const GET_MASTERCARD_RESOLVED_FILES_FAILURE =
  "get_mastercard_resolved_files_failure";
export const CLEAR_RESOLVED_FILES_MESSAGE = "clear_resolved_files_message";

// SETTLEMENT USERS & ACTIONS
export const GET_SETTLEMENT_USERS = "get_settlement_users";
export const GET_SETTLEMENT_USERS_ERROR = "get_settlement_users_error";
export const GET_SETTLEMENT_USERS_PAGINATED = "get_settlement_users_paginated";
export const DELETE_SETTLEMENT_USER = "delete_settlement_user";

//FILTER CASH MANAGEMENT
export const FILTER_CASH_BILLING_REPORT = "filter_cash-billing_report";
export const FILTER_CASH_BILLING_REPORT_ERROR =
  "filter_cash_billing_report_error";

  export const RESET_FILTER_CASH_BILLING_REPORT =
  "reset_filter_cash_billing_report";

//FILTER CONSUMER MANAGEMENT

export const FILTER_CONSUMER_BILLING_REPORT = "FILTER_CONSUMER_BILLING_REPORT";
export const FILTER_CONSUMER_BILLING_REPORT_ERROR =
  "FILTER_CONSUMER_BILLING_REPORT_ERROR";

  export const RESET_FILTER_CONSUMER_BILLING_REPORT =
  "reset_filter_consumer_billing_report";


// Products Reports
export const GET_PRODUCT_REPORTS = "get_products_reports";
export const GET_PRODUCT_REPORTS_ERROR = "get_products_reports_error";