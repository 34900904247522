import { todaysDateFunc } from "../Utils/utilityFunctions";

// Set Todays Date
const todaysDate = todaysDateFunc();

export const SettlementSidebar = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Schemes",
      url: "#",
      icon: "fa fa-file-pdf-o",
      viewPermission: "settlement.can_view_schemes",
      children: [
        {
          name: "China Union Pay",
          url: `/cup/settlement?startDate=${todaysDate}&endDate=${todaysDate}`,
          icon: "fa fa-credit-card",
          viewPermission: "settlement.can_view_chinapay_summary",
        },
        {
          name: "Ecommerce",
          url: `/ecommerce/settlement?startDate=${todaysDate}&endDate=${todaysDate}&page=1`,
          icon: "fa fa-credit-card",
          viewPermission: "settlement.can_view_ecommerce",
        },
        {
          name: "Express Cash",
          url: `/xpresscash/settlement?startDate=${todaysDate}&endDate=${todaysDate}&page=1`,
          icon: "fa fa-money",
          viewPermission: "settlement.can_view_xpresscash",
        },
        {
          name: "InterAffiliate",
          url: `/interAffiliate/settlement?startDate=${todaysDate}&endDate=${todaysDate}`,
          icon: "fa fa-globe",
          viewPermission: "settlement.can_view_interaffiliates",
        },
        {
          name: "Mastercard",
          url: "/mastercard/settlement",
          icon: "fa fa-cc-mastercard",
          viewPermission: "settlement.can_view_mastercard",
        },
        {
          name: "Masterpass",
          url: `/masterpass/settlement/?startDate=${todaysDate}&endDate=${todaysDate}`,
          icon: "fa fa-cc-mastercard",
          viewPermission: "settlement.can_view_masterpass",
        },

        {
          name: "MVISA",
          url: `/mvisa/settlement?startDate=${todaysDate}&endDate=${todaysDate}&page=1`,
          icon: "fa fa-cc-visa",
          viewPermission: "settlement.can_view_mvisa",
        },
        {
          name: "Visa",
          url: "/visa/settlement",
          icon: "fa fa-cc-visa",
          viewPermission: "settlement.can_view_visa",
        },
        {
          name: "GIM",
          url: "/gim/settlement",
          icon: "fa fa-credit-card",
          viewPermission: "settlement.can_view_gim",
        },
      ],
    },
    {
      name: "Reports",
      url: "#",
      icon: "fa fa-navicon",
      viewPermission: "settlement.can_view_reports",
      children: [
        {
          name: "Ecommerce",
          url: "/ecommerce/reports",
          icon: "fa fa-cc-mastercard",
          viewPermission: "settlement.can_view_ecommerce_reports",
        },
        {
          name: "Express Cash",
          url: "/xpresscash/reports",
          icon: "fa fa-money",
          viewPermission: "settlement.can_view_xpresscash_reports",
        },
        {
          name: "InterAffiliate",
          url: "/interAffiliate/reports",
          icon: "fa fa-globe",
          viewPermission: "settlement.can_view_interaffiliate_reports",
        },
        {
          name: "Mastercard",
          url: "/mastercard/reports",
          icon: "fa fa-cc-mastercard",
          viewPermission: "settlement.can_view_mastercard_reports",
        },
        {
          name: "Masterpass",
          url: "/masterpass/reports",
          icon: "fa fa-cc-mastercard",
          viewPermission: "settlement.can_view_masterpass_reports", //create this permission
        },
        {
          name: "MVISA",
          url: "/mvisa/reports",
          icon: "fa fa-cc-visa",
          viewPermission: "settlement.can_view_mvisa_reports",
        },
        {
          name: "Visa",
          url: "/visa/reports",
          icon: "fa fa-cc-visa",
          viewPermission: "settlement.can_view_visa_reports",
        },
        {
          name: "Billing",
          url: `/billing/reports?settlementDate=${todaysDate}&scheme=visa`,
          icon: "fa fa-money",
          viewPermission: "settlement.can_view_billing_reports",
        },
      ],
    },
    {
      name: "Logs",
      url: "#",
      icon: "fa fa-tasks",
      viewPermission: "settlement.can_view_logs",
      children: [
        {
          name: "Audit Logs",
          url: `/admin/audit?page=1&startDate=${todaysDate}&endDate=${todaysDate}`,
          icon: "fa fa-pencil",
          viewPermission: "settlement.can_view_audit_logs",
        },
        {
          name: "Processing Logs",
          url: `/admin/logs?page=1&startDate=${todaysDate}&endDate=${todaysDate}&failed=false&message=&filename=&logType=&settlementDate=`,
          icon: "fa fa-align-left",
          viewPermission: "settlement.can_view_processing_logs",
        },
        {
          name: "Processed Files",
          url: "/channel/logs?page=1",
          icon: "fa fa-file-zip-o",
          viewPermission: "settlement.can_view_processed_files",
        },
        {
          name: "Downstream Logs",
          url: `/downstream/logs/postilion?&startDate=${todaysDate}&endDate=${todaysDate}&filename=FileName&affiliateCode=AffiliateCode`,
          icon: "fa fa-list-alt",
          viewPermission: "settlement.can_view_downstream_logs",
        },
      ],
    },
    {
      name: "Configuration",
      url: "#",
      icon: "fa fa-cog",
      viewPermission: "settlement.can_view_configurations",
      children: [
        {
          name: "Bins",
          url: "/configuration/bins",
          icon: "fa fa-file-code-o",
          viewPermission: "settlement.can_view_bins",
        },
        {
          name: "Affiliates",
          url: "/configuration/affiliates",
          icon: "fa fa-flag",
          viewPermission: [
            "settlement.can_view_group_affiliates",
            "settlement.can_view_own_affiliate",
          ],
        },
        {
          name: "Region Codes",
          url: "/interaffiliate/reports/region-codes",
          icon: "fa fa-language",
          viewPermission: "settlement.can_view_regions",
        },
        {
          name: "Fx History",
          url: `/affiliate/exchange-rates/?date=${todaysDate}`,
          icon: "fa fa-history",
          viewPermission: "settlement.can_view_forex",
        },
        {
          name: "Sres",
          url: "/configuration/sres",
          icon: "fa fa-key",
          viewPermission: "settlement.can_view_sres",
        },
        {
          name: "Narrations",
          url: "/narrations?page=1",
          icon: "fa fa-key",
          viewPermission: "settlement.can_view_narration",
        },
        {
          name: "Products",
          url: "/products",
          icon: "fa fa-tv",
          viewPermission: "settlement.can_view_products",
        },
        {
          name: "File Templates",
          url: "/file/templates",
          icon: "fa fa-sticky-note",
          viewPermission: "settlement.can_view_file_templates",
        },
        {
          name: "Posting Approval",
          url: "/posting",
          icon: "fa fa-envelope",
          viewPermission: "settlement.can_view_posting",
        },
        {
          name: "Settings",
          url: "/settings",
          icon: "fa fa-cogs",
          viewPermission: "settlement.can_view_configurations",
        },
      ],
    },
    {
      name: "Admin",
      url: "#",
      icon: "fa fa-shield",
      viewPermission: "settlement.can_view_advanced_options",
      children: [
        {
          name: "File Uploads",
          url: "/uploads",
          icon: "fa fa-upload",
          viewPermission: "settlement.can_upload_files",
        },
        {
          name: "Users Management",
          url: "/user-management",
          icon: "fa fa-users",
          viewPermission: "settlement.can_manage_users",
        },
        {
          name: "User Affiliates",
          url: "/users-list",
          icon: "fa fa-flag",
          viewPermission: "settlement.can_manage_users",
        },
        {
          name: "Collections",
          url: "/collections",
          icon: "fa fa-align-left",
          viewPermission: "settlement.can_list_collections",
        },
        {
          name: "Directory Listing",
          url: "/directorylisting",
          icon: "fa fa-align-left",
          viewPermission: "settlement.can_list_directory",
        },
        {
          name: "Reports",
          url: "#",
          icon: "fa fa-align-left",
          viewPermission: "settlement.can_view_admin_reports",
          children: [
            {
              name: "Admin Reports",
              url: "/admin/reports",
              icon: "fa fa-align-left",
              viewPermission: "settlement.can_view_admin_reports",
            },
            {
              name: "Transaction Reports",
              url: "/admin/posting-transaction",
              icon: "fa fa-align-left",
              viewPermission: "settlement.can_view_admin_reports",
            },
            {
              name: "Cash Billing Reports",
              url: "/admin/cash-billing-reports",
              icon: "fa fa-bar-chart",
              viewPersmission: "settlement.can_view_admin_reports",
            },
          ],
        },
      ],
    },
  ],
};
